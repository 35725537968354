import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
	selector: 'pdm-simple-text-input',
	templateUrl: './pdm-simple-text-input.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class SimpleTextInputComponent implements OnInit {
	public title: FormControl = new FormControl<string | null>(null);

	constructor(
		@Inject(MAT_DIALOG_DATA)
		public data: {
			text: string;
			confirmButtonText: string;
			discardButtonText: string;
			inputIsRequired: boolean;
			lengths?: { maxLength: number; minLength: number };
		},
		private readonly dialogRef: MatDialogRef<SimpleTextInputComponent>
	) {}

	ngOnInit(): void {
		if (this.data.inputIsRequired) {
			this.title.addValidators(Validators.required);
			this.title.updateValueAndValidity();
		}

		if (this.data.lengths) {
			this.title.addValidators(Validators.maxLength(this.data.lengths.maxLength));
			this.title.addValidators(Validators.minLength(this.data.lengths.minLength));
			this.title.updateValueAndValidity();
		}
	}

	public cancel() {
		this.dialogRef.close(false);
	}

	public save() {
		if (this.title.invalid) {
			this.title.markAllAsTouched();
			return;
		}

		this.dialogRef.close(this.title.value);
	}
}
