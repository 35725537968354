import { createAction, props } from '@ngrx/store';
import { Pagination } from '@core/models/miscellaneous/server-response.model';
import { CollaboratorV2 } from '../../models/collaborators-v2.model';

export const initCollaborators = createAction('[Collaborators] Init', props<{ params: Record<string, string | boolean> }>());
export const loadCollaborators = createAction('[Collaborators] Load', props<{ params: Record<string, string | boolean> }>());

export const loadCollaboratorsSuccess = createAction(
	'[Collaborators] Load success',
	props<{
		collaborators: CollaboratorV2[];
		pagination: Pagination;
	}>()
);
export const loadCollaboratorsFailed = createAction('[Collaborators] Load failed');

export const clearCollaboratorsPaginationInfo = createAction('[Collaborators] Clear pagination info');
