import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { MenuItem } from './models/menu-item.model';
import { LocalStorageService } from '@core/services/storage/local-storage.service';
import { UserPreferences } from '@core/models/user-preferences.model';
import { EStorageKey } from '@core/services/storage/storage-key.enum';
import { randomColor } from '@core/utils/miscellaneous.utils';
import { MenuItemService } from './services/menu-item.service';
import { APP_VERSION } from '../../../app.module';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { Store } from '@ngrx/store';
import { AppState } from '../../core.module';
import { getCompleteName, getEmail, getInitials } from '@core/profile/store/profile.selector';
import { isLoading } from '../../store/selectors/loading.selectors';
import { MediaMatcher } from '@angular/cdk/layout';
import { logout } from '@core/auth/store/auth.actions';

@Component({
	selector: 'pdm-main-view',
	templateUrl: './main-view.component.html',
	styleUrls: ['./main-view.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	animations: [
		trigger('rotatedState', [
			state('default', style({ transform: 'rotate(0)' })),
			state('expanded', style({ transform: 'rotate(90deg)' })),
			transition('expanded => default', animate('100ms ease-out')),
			transition('default => expanded', animate('100ms ease-in'))
		])
	]
})
export class MainViewComponent implements OnInit, OnDestroy {
	private readonly _mobileQueryListener: () => void;
	public readonly userCompleteName$: Observable<string> = this.store$.select(getCompleteName);
	public readonly userEmail$: Observable<string> = this.store$.select(getEmail);
	public readonly userInitials$: Observable<string> = this.store$.select(getInitials);
	public readonly isLoading$: Observable<boolean> = this.store$.select(isLoading);
	public readonly profileMenuItems: MenuItem[] = [];
	public readonly sidebarItems: MenuItem[] = [];
	public userProfileColor = '';
	public mobileQuery: MediaQueryList;
	public sidenavOpened = true;

	constructor(
		menuItemService: MenuItemService,
		changeDetectorRef: ChangeDetectorRef,
		media: MediaMatcher,
		@Inject(APP_VERSION) public readonly version: string,
		private readonly store$: Store<AppState>,
		private readonly storageService: LocalStorageService
	) {
		this.profileMenuItems = menuItemService.getProfileMenuElements();
		this.sidebarItems = menuItemService.getSidebarElements().filter(item => item.label !== 'dashboard');
		this.mobileQuery = media.matchMedia('(max-width: 600px)');
		this._mobileQueryListener = () => changeDetectorRef.detectChanges();
		this.mobileQuery.addListener(this._mobileQueryListener);
	}

	public ngOnInit(): void {
		const profilePreferences = this.storageService.getItem<UserPreferences>(EStorageKey.USER_PREFERENCES);
		this.userProfileColor = profilePreferences?.profileColor ?? randomColor();
		this.storageService.setItem<Partial<UserPreferences>>(EStorageKey.USER_PREFERENCES, {
			...profilePreferences,
			profileColor: this.userProfileColor
		});
	}

	public ngOnDestroy(): void {
		this.mobileQuery.removeListener(this._mobileQueryListener);
	}

	public trackByFn(index: number) {
		return index;
	}

	public logout() {
		this.store$.dispatch(logout());
	}
}
