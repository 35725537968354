import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { exhaustMap, of, tap } from 'rxjs';
import * as PasswordResetActions from './password-reset.action';
import { catchError, map } from 'rxjs/operators';
import { Router } from '@angular/router';
import { NotificationService } from '@core/services/notification.service';
import { PasswordResetService } from '@core/auth/services/password-reset.service';

@Injectable()
export class PasswordResetEffect {
	passwordResetLink$ = createEffect(() =>
		this.actions$.pipe(
			ofType(PasswordResetActions.passwordResetLink),
			exhaustMap(({ email }) =>
				this.passwordResetService.askResetLink(email).pipe(
					map(() => PasswordResetActions.passwordResetLinkSuccess()),
					catchError(() => of(PasswordResetActions.passwordResetLinkFailed()))
				)
			)
		)
	);

	passwordResetLinkSuccess$ = createEffect(
		() =>
			this.actions$.pipe(
				ofType(PasswordResetActions.passwordResetLinkSuccess),
				tap(() => this.notificationService.success('Un link per il reset della password è stato inviato alla tua email', 10000))
			),
		{ dispatch: false }
	);

	passwordReset$ = createEffect(() =>
		this.actions$.pipe(
			ofType(PasswordResetActions.passwordReset),
			exhaustMap(({ token, newPassword, newPasswordConfirmation }) =>
				this.passwordResetService.resetPassword(token, newPassword, newPasswordConfirmation).pipe(
					map(() => PasswordResetActions.passwordResetSuccess()),
					catchError(() => of(PasswordResetActions.passwordResetFailed()))
				)
			)
		)
	);

	passwordResetSuccess$ = createEffect(
		() =>
			this.actions$.pipe(
				ofType(PasswordResetActions.passwordResetSuccess),
				tap(() => {
					this.router
						.navigate(['login'])
						.then(() => this.notificationService.success('La password è stata aggiornata correttamente'));
				})
			),
		{ dispatch: false }
	);

	constructor(
		private readonly router: Router,
		private readonly actions$: Actions,
		private readonly notificationService: NotificationService,
		private readonly passwordResetService: PasswordResetService
	) {}
}
