import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import * as LegislaturePatchActions from './legislature-patch.actions';
import { of, switchMap, tap } from 'rxjs';
import * as LoadingActions from '../../../../../core/store/actions/loading.actions';
import { catchError, map } from 'rxjs/operators';
import { NotificationService } from '@core/services/notification.service';
import { LegislaturesService } from '../../../services/legislatures.service';
import { InteractionService } from '../../../../../shared/interaction/interaction.service';
import { TranslocoService } from '@ngneat/transloco';
import * as LegislatureCreateActions from '@legislatures/store/create/legislature-create.actions';
import { ELegislaturesErrorCode } from '@legislatures/models/enum/legislatures-error-code.enum';
import { showDefaultError } from '@core/utils/miscellaneous.utils';

@Injectable()
export class LegislaturePatchEffects {
	init$ = createEffect(() =>
		this.actions$.pipe(
			ofType(LegislaturePatchActions.initPatchLegislature),
			switchMap(({ id, legislaturePatch }) => [
				LoadingActions.startLoading(),
				LegislaturePatchActions.patchLegislature({ id, legislaturePatch })
			])
		)
	);

	patch$ = createEffect(() =>
		this.actions$.pipe(
			ofType(LegislaturePatchActions.patchLegislature),
			switchMap(({ id, legislaturePatch }) =>
				this.legislaturesService.patch(id, legislaturePatch).pipe(
					map(legislatureDetail => LegislaturePatchActions.patchLegislatureSuccess({ legislatureDetail })),
					catchError(error => of(LegislaturePatchActions.patchLegislatureFailed({ error })))
				)
			)
		)
	);

	patchSuccess$ = createEffect(
		() =>
			this.actions$.pipe(
				ofType(LegislaturePatchActions.patchLegislatureSuccess),
				tap(() => this.notificationService.success('legislatures.DETAIL.PATCH_SUCCESS'))
			),
		{ dispatch: false }
	);

	patchFailed$ = createEffect(() =>
		this.actions$.pipe(
			ofType(LegislatureCreateActions.createLegislatureFailed),
			switchMap(({ error }) => {
				if (error.internalCode in ELegislaturesErrorCode) {
					if (error.internalCode === ELegislaturesErrorCode.DUPLICATE_NAME && error.details) {
						const validations = this.translateService.translate(`legislatures.errors.validation.${error.internalCode}`, {
							title: error.details.duplicatedName
						});
						this.interactionService.confirmValidationV2('legislatures.errors.validation.title', [validations]);
					} else {
						this.interactionService.confirm(
							`legislatures.errors.${error.internalCode}.title`,
							this.translateService.translate(`legislatures.errors.${error.internalCode}.message`),
							'PDM.GENERICS.BUTTON.OK',
							'error'
						);
					}
				} else {
					showDefaultError(error, this.notificationService, this.interactionService);
				}

				return [LoadingActions.stopLoading()];
			})
		)
	);

	stopLoading$ = createEffect(() =>
		this.actions$.pipe(
			ofType(LegislaturePatchActions.patchLegislatureSuccess, LegislaturePatchActions.patchLegislatureFailed),
			switchMap(() => [LoadingActions.stopLoading()])
		)
	);

	constructor(
		private readonly actions$: Actions,
		private readonly translateService: TranslocoService,
		private readonly interactionService: InteractionService,
		private readonly legislaturesService: LegislaturesService,
		private readonly notificationService: NotificationService
	) {}
}
