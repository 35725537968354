import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Region } from '@core/models/miscellaneous/region.model';
import { ServerResponse } from '@core/models/miscellaneous/server-response.model';
import { map } from 'rxjs/operators';
import { Province } from '@core/models/miscellaneous/province.model';

@Injectable()
export class MiscellaneousService {
	private readonly baseURL = 'v1';

	constructor(private readonly http: HttpClient) {}

	public getRegions(): Observable<Region[]> {
		return this.http.get<ServerResponse<Region[]>>(`${this.baseURL}/regions`).pipe(map(result => result.results));
	}

	public getProvinces(regionId?: string): Observable<Province[]> {
		let params = new HttpParams();
		if (regionId) {
			params = params.set('regionId', regionId);
		}

		return this.http.get<ServerResponse<Province[]>>(`${this.baseURL}/provinces`, { params }).pipe(map(result => result.results));
	}
}
