import { createReducer, on } from '@ngrx/store';
import { Pagination } from '@core/models/miscellaneous/server-response.model';
import { PDMFile } from '@core/models/pdm-file.model';
import { InternalNote } from '@core/models/notes/internal-note.model';
import { ActivityV2 } from '../models/activity-V2.model';
import * as ActivitiesV2Actions from './list/activities-V2-list.actions';
import * as ActivitiesV2DetailActions from './detail/activities-V2-detail.actions';
import * as ActivitiesV2DeleteActions from './delete/activities-V2-delete.actions';
import * as InternalNoteActions from '@core/store/actions/internal-note-actions';
import * as ActivitiesV2CancelActions from './cancel/activities-V2-cancel.actions';
import * as ActivitiesV2PatchActions from './patch/activities-V2-patch.actions';
import * as ActivitiesV2CreateActions from './create/activities-V2-create.actions';
import * as ActivitiesV2ApproveActions from './approve/activities-V2-approve.actions';
import * as ActivitiesV2ArchiveActions from './archive/activities-V2-archive.actions';
import * as ActivitiesV2PublishActions from './publish/activities-V2-publish.actions';
import * as ActivitiesV2ReviewActions from './review/activities-V2-review.actions';
import * as ActivitiesV2RejectActions from './reject/activities-V2-reject.actions';
import * as FileActions from '@core/store/actions/file.actions';
import { ActivityV2Detail } from '../models/activity-V2-detail.model';

export const activitiesV2FeatureKey = 'activitiesV2';

export interface ActivitiesV2State {
	activities: ActivityV2[];
	activity: ActivityV2Detail | undefined;
	pagination: Pagination | undefined;
	descriptionFiles: PDMFile[];
	detailsFiles: PDMFile[];
	notes: InternalNote[];
}

export const initialState: ActivitiesV2State = {
	activities: [],
	pagination: undefined,
	descriptionFiles: [],
	detailsFiles: [],
	notes: [],
	activity: undefined
};

export const activitiesV2Reducer = createReducer(
	initialState,
	on(ActivitiesV2Actions.loadActivitiesV2ListSuccess, (state, action) => ({
		...state,
		activities: action.activities,
		pagination: action.pagination
	})),
	on(FileActions.uploadActivityDescriptionFileSuccess, (state, action) => ({
		...state,
		descriptionFiles: [action.file, ...state.descriptionFiles]
	})),
	on(FileActions.uploadActivityTextFileSuccess, (state, action) => ({
		...state,
		detailsFiles: [action.file, ...state.detailsFiles]
	})),
	on(FileActions.cleanActivityFiles, state => ({
		...state,
		detailsFiles: [],
		descriptionFiles: []
	})),
	on(FileActions.deleteActivityTextFileSuccess, (state, action) => ({
		...state,
		detailsFiles: [...state.detailsFiles.filter(file => file.id !== action.id)]
	})),
	on(FileActions.deleteActivityDescriptionFileSuccess, (state, action) => ({
		...state,
		descriptionFiles: [...state.descriptionFiles.filter(file => file.id !== action.id)]
	})),
	on(ActivitiesV2DetailActions.loadActivityV2DetailSuccess, (state, action) => ({
		...state,
		activity: action.activity,
		descriptionFiles: action.activity.attachments?.filter(file => file.target === 'DESCRIPTION') || [],
		detailsFiles: action.activity.attachments?.filter(file => file.target === 'TEXT') || []
	})),
	on(ActivitiesV2DetailActions.clearActivityV2Detail, state => ({
		...state,
		activity: undefined,
		descriptionFiles: [],
		detailsFiles: [],
		notes: []
	})),
	on(InternalNoteActions.loadSuccess, (state, action) => ({
		...state,
		notes: action.notes
	})),
	on(ActivitiesV2PatchActions.patchActivityV2Success, (state, action) => ({
		...state,
		activity: action.activity,
		descriptionFiles: action.activity.attachments?.filter(file => file.target === 'DESCRIPTION') || [],
		detailsFiles: action.activity.attachments?.filter(file => file.target === 'TEXT') || []
	})),
	on(ActivitiesV2DeleteActions.deleteActivityV2Success, (state, action) => ({
		...state,
		activities: [...state.activities.filter(activity => activity.id !== action.id)],
		descriptionFiles: [],
		detailsFiles: []
	})),
	on(ActivitiesV2CancelActions.cancelActivityV2Success, (state, action) => ({
		...state,
		activity: action.activity,
		descriptionFiles: action.activity.attachments?.filter(file => file.target === 'DESCRIPTION') || [],
		detailsFiles: action.activity.attachments?.filter(file => file.target === 'TEXT') || []
	})),
	on(ActivitiesV2CreateActions.createActivityV2Success, (state, action) => ({
		...state,
		activity: action.activity,
		descriptionFiles: action.activity.attachments?.filter(file => file.target === 'DESCRIPTION') || [],
		detailsFiles: action.activity.attachments?.filter(file => file.target === 'TEXT') || []
	})),
	on(ActivitiesV2ApproveActions.approveActivityV2Success, (state, action) => ({
		...state,
		activity: action.activity,
		descriptionFiles: action.activity.attachments?.filter(file => file.target === 'DESCRIPTION') || [],
		detailsFiles: action.activity.attachments?.filter(file => file.target === 'TEXT') || []
	})),
	on(ActivitiesV2ArchiveActions.archiveActivityV2Success, (state, action) => ({
		...state,
		activity: action.activity,
		descriptionFiles: action.activity.attachments?.filter(file => file.target === 'DESCRIPTION') || [],
		detailsFiles: action.activity.attachments?.filter(file => file.target === 'TEXT') || []
	})),
	on(ActivitiesV2PublishActions.publishActivityV2Success, (state, action) => ({
		...state,
		activity: action.activity,
		descriptionFiles: action.activity.attachments?.filter(file => file.target === 'DESCRIPTION') || [],
		detailsFiles: action.activity.attachments?.filter(file => file.target === 'TEXT') || []
	})),
	on(ActivitiesV2ReviewActions.askReviewActivityV2Success, (state, action) => ({
		...state,
		activity: action.activity,
		descriptionFiles: action.activity.attachments?.filter(file => file.target === 'DESCRIPTION') || [],
		detailsFiles: action.activity.attachments?.filter(file => file.target === 'TEXT') || []
	})),
	on(ActivitiesV2RejectActions.rejectActivityV2Success, (state, action) => ({
		...state,
		activity: action.activity,
		descriptionFiles: action.activity.attachments?.filter(file => file.target === 'DESCRIPTION') || [],
		detailsFiles: action.activity.attachments?.filter(file => file.target === 'TEXT') || []
	}))
);
