import { NgModule } from '@angular/core';
import { MatToolbarModule } from '@angular/material/toolbar';
import { AppRoutingModule } from '../app-routing.module';
import { MatIconModule } from '@angular/material/icon';
import { CommonModule } from '@angular/common';
import { ProfileButtonComponent } from '@core/components/main-view/components/profile-button/profile-button.component';
import { MainViewComponent } from '@core/components/main-view/main-view.component';
import { MainContainerComponent } from '@core/components/main-view/components/main-container/main-container.component';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MenuItemService } from '@core/components/main-view/services/menu-item.service';
import { ActionButtonsModule } from '@uikit/components/action-buttons/action-buttons.module';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { environment } from '@environment/environment';
import { EffectsModule } from '@ngrx/effects';
import { AuthEffect } from './auth/store/auth.effect';
import { ProfileEffect } from '@core/profile/store/profile.effect';
import { profileReducer, ProfileState } from '@core/profile/store/profile.reducer';
import { authReducer, AuthState } from './auth/store/auth.reducer';
import { ProfileService } from '@core/profile/services/profile.service';
import { AuthService } from './auth/services/auth.service';
import { loadingReducer, LoadingState } from './store/reducers/loading.reducer';
import { HttpErrorEffects } from './store/effects/http-error.effects';
import { InteractionModule } from '../shared/interaction/interaction.module';
import { LocalStorageService } from '@core/services/storage/local-storage.service';
import { RegionsEffects } from './store/effects/regions.effects';
import { MiscellaneousService } from '@core/services/miscellaneous.service';
import { ProvincesEffects } from './store/effects/provinces.effects';
import { clearState } from './index';
import { UserPermissionService } from '@core/services/user-permission.service';
import { HasPermissionDirective } from '@uikit/directives/has-permission.directive';
import { FileUploadService } from '@core/services/file-upload.service';
import { FileEffects } from './store/effects/file.effects';
import { PDMPaginationIntlService } from '@core/services/pdm-pagination-intl.service';
import { NotificationService } from '@core/services/notification.service';
import { InternalNoteEffects } from './store/effects/internal-note.effects';
import { InternalNoteService } from '@core/services/internal-note.service';
import { RolesV2Effect } from './store/effects/rolesV2.effects';
import { RolesServiceV2 } from '@core/services/rolesV2.service';
import { categoriesReducer, CategoriesState } from './store/reducers/categories.reducer';

import { permissionReducer, PermissionState } from './store/reducers/permissions.reducers';
import { PermissionsEffect } from './store/effects/permissions.effects';
import { PermissionsService } from './services/permissions.service';
import { MatButtonModule } from '@angular/material/button';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatMenuModule } from '@angular/material/menu';
import { MatListModule } from '@angular/material/list';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { rolesV2Reducer, RolesV2State } from './store/reducers/rolesV2.reducer';
import { regionsReducer, RegionsState } from './store/reducers/regions.reducer';
import { provincesReducer, ProvincesState } from './store/reducers/provinces.reducer';
import { ProvincesV2Effects } from './store/effects/provincesV2.effects';
import { AssignRoleEffects } from './store/effects/assign-role.effects';
import { TranslocoPipe } from '@ngneat/transloco';
import { PasswordResetEffect } from '@core/auth/store/password-reset.effect';
import { PasswordResetService } from '@core/auth/services/password-reset.service';

export interface AppState {
	auth: AuthState;
	profile: ProfileState;
	loading: LoadingState;
	categories: CategoriesState;
	permissions: PermissionState;
	rolesV2: RolesV2State;
	regions: RegionsState;
	provinces: ProvincesState;
}

@NgModule({
	declarations: [ProfileButtonComponent, MainContainerComponent, MainViewComponent],
	imports: [
		CommonModule,
		MatButtonModule,
		MatToolbarModule,
		MatProgressBarModule,
		MatMenuModule,
		AppRoutingModule,
		MatIconModule,
		MatSidenavModule,
		MatListModule,
		MatButtonToggleModule,
		MatTooltipModule,
		ActionButtonsModule,
		MatFormFieldModule,
		InteractionModule,
		StoreModule.forRoot(
			{
				auth: authReducer,
				profile: profileReducer,
				loading: loadingReducer,
				categories: categoriesReducer,
				permissions: permissionReducer,
				rolesV2: rolesV2Reducer,
				regions: regionsReducer,
				provinces: provincesReducer
			},
			{ metaReducers: [clearState] }
		),
		StoreDevtoolsModule.instrument({ maxAge: 25, logOnly: !environment.production }),
		EffectsModule.forRoot([
			AuthEffect,
			ProfileEffect,
			HttpErrorEffects,
			RegionsEffects,
			ProvincesEffects,
			PasswordResetEffect,
			FileEffects,
			InternalNoteEffects,
			RolesV2Effect,
			PermissionsEffect,
			ProvincesV2Effects,
			AssignRoleEffects
		]),
		HasPermissionDirective,

		TranslocoPipe
	],
	providers: [
		MenuItemService,
		AuthService,
		ProfileService,
		LocalStorageService,
		MiscellaneousService,
		UserPermissionService,
		PasswordResetService,
		FileUploadService,
		NotificationService,
		InternalNoteService,
		RolesServiceV2,
		PermissionsService,
		{
			provide: MatPaginatorIntl,
			useClass: PDMPaginationIntlService
		}
	]
})
export class CoreModule {}
