import { ChangeDetectorRef, Directive, Input, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';
import { EUserPermissions } from '@core/models/enums/user-permissions.enum';
import { UserPermissionService } from '@core/services/user-permission.service';
import { distinctUntilChanged } from 'rxjs';

@Directive({
	selector: '[pdmHasPermission]',
	standalone: true
})
export class HasPermissionDirective implements OnInit {
	private _permissions: EUserPermissions[] = [];

	@Input()
	public set pdmHasPermission(value: EUserPermissions[]) {
		this._permissions = value;
	}

	public constructor(
		private readonly cd: ChangeDetectorRef,
		private readonly templateRef: TemplateRef<unknown>,
		private readonly viewContainerRef: ViewContainerRef,
		private readonly userPermissionService: UserPermissionService
	) {}

	public ngOnInit() {
		this.userPermissionService
			.hasSomePermissions(...this._permissions)
			.pipe(distinctUntilChanged())
			.subscribe(result => {
				if (result) {
					this.viewContainerRef.createEmbeddedView(this.templateRef);
				} else {
					this.viewContainerRef.clear();
				}
				this.cd.detectChanges();
			});
	}
}
