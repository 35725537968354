import { createAction, props } from '@ngrx/store';
import { PermissionV2 } from '@core/models/roles/permissionV2.model';

// LOAD
export const initPermissions = createAction('[Permissions] Init');
export const loadPermissions = createAction('[Permissions] Load');
export const loadPermissionsSuccess = createAction(
	'[Permissions] Load success',
	props<{
		permissions: PermissionV2[];
	}>()
);
export const loadPermissionsFailed = createAction('[Permissions] Load failed');
