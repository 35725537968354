import { createFeatureSelector, createSelector } from '@ngrx/store';
import { CollaboratorsListState } from './collaborators-list.reducers';

export const collaboratorsListFeatureKey = 'collaboratorsList';

const selectCollaboratorsListFeature = createFeatureSelector<CollaboratorsListState>(collaboratorsListFeatureKey);
export const getCollaborators = createSelector(selectCollaboratorsListFeature, (state: CollaboratorsListState) => state.collaborators);
export const getCollaboratorsPaginationInfo = createSelector(
	selectCollaboratorsListFeature,
	(state: CollaboratorsListState) => state.pagination
);
