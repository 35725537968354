import { createAction, props } from '@ngrx/store';
import { LegislatureCreate } from '../../../models/legislature-create.model';
import { LegislatureDetail } from '../../../models/legislature-detail.model';
import { ServerError } from '@core/models/server-error.model';

export const initPatchLegislature = createAction(
	'[Legislatures] Patch Init',
	props<{ id: string; legislaturePatch: Partial<LegislatureCreate> }>()
);
export const patchLegislature = createAction('[Legislatures] Patch', props<{ id: string; legislaturePatch: Partial<LegislatureCreate> }>());
export const patchLegislatureSuccess = createAction('[Legislatures] Patch success', props<{ legislatureDetail: LegislatureDetail }>());
export const patchLegislatureFailed = createAction('[Legislatures] Patch failed', props<{ error: ServerError }>());
