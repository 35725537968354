import { createAction, props } from '@ngrx/store';
import { RolesAndPermissionsCreate } from '../../models/roles-and-permissions-create.model';
import { RoleV2 } from '@core/models/roles/roleV2.model';
import { ServerError } from '@core/models/server-error.model';

export const initRolesAndPermissionsV2Create = createAction(
	'[Roles and Permissions V2] Init Create',
	props<{ role: RolesAndPermissionsCreate }>()
);

export const createRolesAndPermissionsV2 = createAction('[Roles and Permissions V2] Create', props<{ role: RolesAndPermissionsCreate }>());
export const createRolesAndPermissionsV2Success = createAction('[Roles and Permissions V2] Create Success', props<{ role: RoleV2 }>());
export const createRolesAndPermissionsV2Failed = createAction('[Roles and Permissions V2] Create Failed', props<{ error: ServerError }>());
