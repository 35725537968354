import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { PaginatedServerResponse } from '@core/models/miscellaneous/server-response.model';
import { CollaboratorV2, ServerCollaboratorV2 } from '../models/collaborators-v2.model';
import { map } from 'rxjs/operators';
import { DateTime } from 'luxon';
import { CollaboratorsFilters } from '../models/collaborators-filters.model';
import { CheckboxFilter, TextFilter } from '@core/models/filters/filter.model';
import { Validators } from '@angular/forms';
import { CollaboratorDetail, ServerCollaboratorDetail } from '../models/collaborator-detail.model';

@Injectable()
export class CollaboratorsV2Service {
	private static readonly BASE_URL = 'v1/users';
	constructor(private readonly http: HttpClient) {}

	public getFilters(filters: CollaboratorsFilters) {
		return [
			new TextFilter({
				key: 'firstName',
				value: filters.firstName,
				label: 'collaborators.FILTERS.FIRSTNAME'
			}),
			new TextFilter({
				key: 'lastName',
				value: filters.lastName,
				label: 'collaborators.FILTERS.LASTNAME'
			}),
			new TextFilter({
				key: 'userName',
				value: filters.userName,
				label: 'collaborators.FILTERS.USERNAME'
			}),
			new TextFilter({
				key: 'email',
				value: filters.email,
				type: 'email',
				label: 'collaborators.FILTERS.EMAIL',
				validators: [Validators.email]
			}),
			new TextFilter({
				key: 'role',
				value: filters.role,
				label: 'collaborators.FILTERS.ROLE'
			}),
			new CheckboxFilter({
				key: 'isBlocked',
				value: filters.isBlocked,
				label: 'collaborators.FILTERS.IS_BLOCKED'
			}),
			new CheckboxFilter({
				key: 'isDeleted',
				value: filters.isDeleted,
				label: 'collaborators.FILTERS.IS_DELETED'
			})
		];
	}

	public listUsers(params: Record<string, string | boolean>): Observable<PaginatedServerResponse<CollaboratorV2[]>> {
		return this.http.get<PaginatedServerResponse<ServerCollaboratorV2[]>>(`${CollaboratorsV2Service.BASE_URL}`, { params }).pipe(
			map(response => ({
				...response,
				results: response.results.map(collaborator => ({
					...collaborator,
					joinedDate: collaborator.joinedDate ? DateTime.fromISO(collaborator.joinedDate) : null,
					lastAccess: collaborator.lastAccess ? DateTime.fromISO(collaborator.lastAccess) : null
				}))
			}))
		);
	}

	public getDetail(id: string): Observable<CollaboratorDetail> {
		return this.http.get<ServerCollaboratorDetail>(`${CollaboratorsV2Service.BASE_URL}/${id}`).pipe(map(this.mapData()));
	}

	public delete(id: string): Observable<void> {
		return this.http.delete(`${CollaboratorsV2Service.BASE_URL}/${id}`).pipe(map(() => void 0));
	}

	public block(id: string): Observable<void> {
		return this.http.post(`${CollaboratorsV2Service.BASE_URL}/${id}/block`, {}).pipe(map(() => void 0));
	}

	public unblock(id: string): Observable<void> {
		return this.http.post(`${CollaboratorsV2Service.BASE_URL}/${id}/unblock`, {}).pipe(map(() => void 0));
	}

	private mapData() {
		return (response: ServerCollaboratorDetail) => ({
			...response,
			birthDate: DateTime.fromISO(response.birthDate),
			joinedDate: DateTime.fromISO(response.joinedDate),
			lastAccess: DateTime.fromISO(response.lastAccess)
		});
	}
}
