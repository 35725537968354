import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import * as ActivitiesV2Actions from './activities-V2-list.actions';
import * as LoadingActions from '@core/store/actions/loading.actions';
import { of, switchMap } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { ActivitiesV2Service } from '../../services/activities-V2.service';
import { NotificationService } from '@core/services/notification.service';

@Injectable()
export class ActivitiesV2ListEffects {
	init$ = createEffect(() =>
		this.actions$.pipe(
			ofType(ActivitiesV2Actions.initActivitiesV2List),
			switchMap(({ params }) => [LoadingActions.startLoading(), ActivitiesV2Actions.loadActivitiesV2List({ params })])
		)
	);

	load$ = createEffect(() =>
		this.actions$.pipe(
			ofType(ActivitiesV2Actions.loadActivitiesV2List),
			switchMap(({ params }) =>
				this.activitiesV2Service.listActivities(params).pipe(
					map(result =>
						ActivitiesV2Actions.loadActivitiesV2ListSuccess({
							activities: result.results,
							pagination: result.paginatedInfo
						})
					),
					catchError(() => of(ActivitiesV2Actions.loadActivitiesV2ListFailed()))
				)
			)
		)
	);

	loadAllFailed$ = createEffect(
		() =>
			this.actions$.pipe(
				ofType(ActivitiesV2Actions.loadActivitiesV2ListFailed),
				tap(() => this.notificationService.error('PDM.GENERICS.ERROR.LOAD_DATA_ERROR'))
			),
		{ dispatch: false }
	);

	stopLoading$ = createEffect(() =>
		this.actions$.pipe(
			ofType(ActivitiesV2Actions.loadActivitiesV2ListSuccess, ActivitiesV2Actions.loadActivitiesV2ListFailed),
			switchMap(() => [LoadingActions.stopLoading()])
		)
	);

	constructor(
		private readonly actions$: Actions,
		private readonly activitiesV2Service: ActivitiesV2Service,
		private readonly notificationService: NotificationService
	) {}
}
