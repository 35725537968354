import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import * as ArticlePatchActions from './articles-V2-patch.actions';
import * as ArticleReviewActions from '../review/articles-V2-review.actions';
import { concatMap, of, switchMap, tap } from 'rxjs';
import * as LoadingActions from '../../../../core/store/actions/loading.actions';
import { catchError, map } from 'rxjs/operators';
import { NotificationService } from '@core/services/notification.service';
import { InteractionService } from '../../../../shared/interaction/interaction.service';
import { ServerError } from '@core/models/server-error.model';

import { TranslocoService } from '@ngneat/transloco';
import { ArticlesV2Service } from '../../services/articles-v2.service';
import { environment } from '@environment/environment';
import { EArticleErrorCodes } from '../../models/enum/article-error-code.enum';

@Injectable()
export class ArticlesV2PatchEffects {
	init$ = createEffect(() =>
		this.actions$.pipe(
			ofType(ArticlePatchActions.initPatchArticle),
			switchMap(({ id, article }) => [
				LoadingActions.startLoading(),
				ArticlePatchActions.patchArticle({
					id,
					article
				})
			])
		)
	);

	initAndFireReview$ = createEffect(() =>
		this.actions$.pipe(
			ofType(ArticlePatchActions.initPatchArticleAndFireAskReview),
			switchMap(({ id, article, message }) => [
				LoadingActions.startLoading(),
				ArticlePatchActions.patchArticleAndFireAskReview({ id, article, message })
			])
		)
	);

	patch$ = createEffect(() =>
		this.actions$.pipe(
			ofType(ArticlePatchActions.patchArticle),
			switchMap(({ id, article }) =>
				this.articlesService.patchArticle(id, article).pipe(
					map(result => ArticlePatchActions.patchArticleSuccess({ article: result })),
					catchError(error => of(ArticlePatchActions.patchArticleFailed({ error })))
				)
			)
		)
	);

	patchAndFireAskReview$ = createEffect(() =>
		this.actions$.pipe(
			ofType(ArticlePatchActions.patchArticleAndFireAskReview),
			switchMap(({ id, article, message }) =>
				this.articlesService.patchArticle(id, article).pipe(
					concatMap(result => [
						ArticlePatchActions.patchArticleSuccess({ article: result }),
						ArticleReviewActions.initAskReviewArticle({ id: result.id, message })
					]),
					catchError(error => of(ArticlePatchActions.patchArticleFailed({ error })))
				)
			)
		)
	);

	patchSuccess$ = createEffect(
		() =>
			this.actions$.pipe(
				ofType(ArticlePatchActions.patchArticleSuccess),
				tap(() => {
					this.notificationService.success('articles.detail.success.patch');
				})
			),
		{ dispatch: false }
	);

	patchFailed$ = createEffect(() =>
		this.actions$.pipe(
			ofType(ArticlePatchActions.patchArticleFailed),
			switchMap(({ error }) => {
				if (error.internalCode in EArticleErrorCodes) {
					if (
						error.internalCode === EArticleErrorCodes.DUPLICATE_TITLE &&
						error.details &&
						error.details.duplicatedTitle &&
						error.details.duplicatedTitle.length > 0
					) {
						const validations = [
							this.translateService.translate(`articles.detail.errors.validation.DUPLICATE_TITLE`, {
								title: error.details.duplicatedTitle
							})
						];
						this.interactionService.confirmValidationV2('articles.detail.errors.validation.title', validations);
					} else {
						this.interactionService.confirm(
							`articles.detail.errors.${error.internalCode}.title`,
							this.translateService.translate(`articles.detail.errors.${error.internalCode}.message`),
							'PDM.GENERICS.BUTTON.OK',
							'error'
						);
					}
				} else {
					this.showDefaultError(error);
				}

				return [LoadingActions.stopLoading()];
			})
		)
	);

	private showDefaultError(error: ServerError) {
		if (environment.production) {
			this.notificationService.error().afterDismissed();
		} else {
			this.interactionService.confirm('Generic Error', JSON.stringify(error), 'PDM.GENERICS.BUTTON.OK', 'error');
		}
	}

	stopLoading$ = createEffect(() =>
		this.actions$.pipe(
			ofType(ArticlePatchActions.patchArticleSuccess, ArticlePatchActions.patchArticleFailed),
			switchMap(() => [LoadingActions.stopLoading()])
		)
	);

	constructor(
		private readonly actions$: Actions,
		private readonly articlesService: ArticlesV2Service,
		private readonly translateService: TranslocoService,
		private readonly interactionService: InteractionService,
		private readonly notificationService: NotificationService
	) {}
}
