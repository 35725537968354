import { createReducer, on } from '@ngrx/store';
import { Pagination } from '@core/models/miscellaneous/server-response.model';
import { Group } from '../../models/group.model';
import * as GroupsListActions from './groups-list.actions';
import * as GroupPatchActions from '../patch/groups-patch.actions';

export interface GroupsListState {
	groups: Group[];
	pagination: Pagination | undefined;
}

const initialState: GroupsListState = {
	groups: [],
	pagination: undefined
};

export const groupsListReducers = createReducer(
	initialState,
	on(GroupsListActions.loadGroupsListSuccess, (state, action) => ({
		...state,
		groups: action.groups,
		pagination: action.pagination
	})),
	on(GroupPatchActions.patchGroupSuccess, (state, action) => ({
		...state,
		groups: state.groups.map(group => {
			if (group.id === action.group.id) {
				return action.group;
			}

			return group;
		})
	}))
);
