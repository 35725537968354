import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ServerResponse } from '@core/models/miscellaneous/server-response.model';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { PermissionV2 } from '@core/models/roles/permissionV2.model';

@Injectable()
export class PermissionsService {
	private static BASE_URL = 'v1/permissions';

	constructor(private readonly http: HttpClient) {}

	public getPermissions(): Observable<PermissionV2[]> {
		return this.http
			.get<ServerResponse<PermissionV2[]>>(`${PermissionsService.BASE_URL}`)
			.pipe(map((response: ServerResponse<PermissionV2[]>) => response.results));
	}
}
