export enum EActivitiesV2ErrorCodes {
	INVALID_PUBBLICAZIONE_GAZZETTA_DATE = 'INVALID_PUBBLICAZIONE_GAZZETTA_DATE',
	ATTACHMENTS_NOT_FOUND = 'ATTACHMENTS_NOT_FOUND',
	NOT_FOUND = 'NOT_FOUND',
	DUPLICATE_TITLE = 'DUPLICATE_TITLE',
	INVALID_CATEGORY_TYPE = 'INVALID_CATEGORY_TYPE',
	INVALID_STATUS = 'INVALID_STATUS',
	MISSING_DATA = 'MISSING_DATA',
	INVALID_PUBLICATION_DATE = 'INVALID_PUBLICATION_DATE'
}
