import { createReducer, on } from '@ngrx/store';
import * as LegislatureDetailActions from './legislature-detail.actions';
import * as LegislatureMinistryDeleteActions from './ministries/delete/legislature-ministry-delete.action';
import * as LegislatureMinistryAddActions from './ministries/add/legislature-ministry-add.action';
import * as LegislatureMinistryUpdateActions from './ministries/update/legislature-ministry-update.action';
import * as LegislaturePatchActions from './patch/legislature-patch.actions';
import * as PoliticiansAutocomplete from './politicians-autocomplete/politicians-autocomplete.actions';
import { LegislatureDetail } from '../../models/legislature-detail.model';
import { Person } from '@core/models/person.model';

export interface LegislatureDetailState {
	legislatureDetail: LegislatureDetail | undefined;
	simplePoliticians: Person[];
}

const initialState: LegislatureDetailState = {
	legislatureDetail: undefined,
	simplePoliticians: []
};

export const legislatureDetailReducers = createReducer(
	initialState,
	on(LegislatureDetailActions.detailLegislatureSuccess, (state, action) => ({
		...state,
		legislatureDetail: action.legislatureDetail
	})),
	on(LegislatureDetailActions.detailLegislatureReset, () => ({
		...initialState
	})),
	on(LegislaturePatchActions.patchLegislatureSuccess, (state, action) => ({
		...state,
		legislatureDetail: { ...state.legislatureDetail, ...action.legislatureDetail }
	})),
	on(PoliticiansAutocomplete.loadPoliticiansAutocompleteSuccess, (state, action) => ({
		...state,
		simplePoliticians: action.politicians
	})),
	on(LegislatureMinistryDeleteActions.deleteMinistriesSuccess, (state, action) => ({
		...state,
		legislatureDetail: action.legislatureDetail
	})),
	on(LegislatureMinistryAddActions.addMinistriesSuccess, (state, action) => ({
		...state,
		legislatureDetail: action.legislatureDetail
	})),
	on(LegislatureMinistryUpdateActions.updateMinistriesSuccess, (state, action) => ({
		...state,
		legislatureDetail: action.legislatureDetail
	}))
);
