import { createReducer, on } from '@ngrx/store';
import * as PermissionsActions from '../actions/permissions.actions';
import { PermissionV2 } from '@core/models/roles/permissionV2.model';

export interface PermissionState {
	permissions: PermissionV2[];
}

export const initialState: PermissionState = {
	permissions: []
};

export const permissionReducer = createReducer(
	initialState,
	on(PermissionsActions.loadPermissionsSuccess, (_, action) => ({
		permissions: action.permissions
	}))
);
