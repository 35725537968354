import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import * as RolesAndPermissionsV2UpdateActions from './roles-and-permissions-v2-update.actions';
import { of, switchMap, tap } from 'rxjs';
import * as LoadingActions from '@core/store/actions/loading.actions';
import { catchError, map } from 'rxjs/operators';
import { NotificationService } from '@core/services/notification.service';
import { RolesServiceV2 } from '@core/services/rolesV2.service';
import { ServerError } from '@core/models/server-error.model';
import { TranslocoService } from '@ngneat/transloco';
import { ERolesAndPermissionsErrors } from '../../models/roles-and-permissions-errors.enum';
import { environment } from '@environment/environment';
import { InteractionService } from '../../../../../shared/interaction/interaction.service';

@Injectable()
export class RolesAndPermissionsV2UpdateEffects {
	init$ = createEffect(() =>
		this.actions$.pipe(
			ofType(RolesAndPermissionsV2UpdateActions.initUpdateRolesAndPermissionsV2),
			switchMap(({ id, role }) => [
				LoadingActions.startLoading(),
				RolesAndPermissionsV2UpdateActions.updateRolesAndPermissionsV2({ id, role })
			])
		)
	);

	update$ = createEffect(() =>
		this.actions$.pipe(
			ofType(RolesAndPermissionsV2UpdateActions.updateRolesAndPermissionsV2),
			switchMap(({ id, role }) =>
				this.rolesServiceV2.update(id, role).pipe(
					map(role => RolesAndPermissionsV2UpdateActions.updateRolesAndPermissionsV2Success({ role })),
					catchError(error => of(RolesAndPermissionsV2UpdateActions.updateRolesAndPermissionsV2Failed({ error })))
				)
			)
		)
	);

	updateFailed$ = createEffect(() =>
		this.actions$.pipe(
			ofType(RolesAndPermissionsV2UpdateActions.updateRolesAndPermissionsV2Failed),
			switchMap(({ error }) => {
				switch (error.internalCode) {
					case ERolesAndPermissionsErrors.DUPLICATED_OWNERSHIP: {
						this.interactionService.confirm(
							`rolesAndPermissions.errors.${error.internalCode}.title`,
							this.translateService.translate(`rolesAndPermissions.errors.${error.internalCode}.message`),
							'PDM.GENERICS.BUTTON.OK',
							'error'
						);
						break;
					}
					default: {
						this.showDefaultError(error);
					}
				}

				return [LoadingActions.stopLoading()];
			})
		)
	);

	updateSuccess$ = createEffect(
		() =>
			this.actions$.pipe(
				ofType(RolesAndPermissionsV2UpdateActions.updateRolesAndPermissionsV2Success),
				tap(() => this.notificationService.success('rolesAndPermissions.detail.updateSuccess'))
			),
		{ dispatch: false }
	);

	stopLoading$ = createEffect(() =>
		this.actions$.pipe(
			ofType(
				RolesAndPermissionsV2UpdateActions.updateRolesAndPermissionsV2Success,
				RolesAndPermissionsV2UpdateActions.updateRolesAndPermissionsV2Failed
			),
			switchMap(() => [LoadingActions.stopLoading()])
		)
	);

	private showDefaultError(error: ServerError) {
		if (environment.production) {
			this.notificationService.error().afterDismissed();
		} else {
			this.interactionService.confirm('Generic Error', JSON.stringify(error), 'PDM.GENERICS.BUTTON.OK', 'error');
		}
	}

	constructor(
		private readonly actions$: Actions,
		private readonly rolesServiceV2: RolesServiceV2,
		private readonly translateService: TranslocoService,
		private readonly interactionService: InteractionService,
		private readonly notificationService: NotificationService
	) {}
}
