import { createReducer, on } from '@ngrx/store';
import { Pagination } from '@core/models/miscellaneous/server-response.model';
import * as PoliticiansActions from './politiciansV2.actions';
import * as PoliticianDetailActions from './detail/politiciansV2-detail.actions';
import { Politician } from '../models/politician.model';

export interface PoliticiansV2State {
	politicians: Politician[];
	politician: Politician | undefined;
	pagination: Pagination;
}

const initialState: PoliticiansV2State = {
	politicians: [],
	politician: undefined,
	pagination: {} as Pagination
};

export const politiciansV2Reducers = createReducer(
	initialState,
	on(PoliticiansActions.loadPoliticiansV2Success, (state, action) => ({
		...state,
		politicians: [...action.politicians],
		pagination: action.pagination
	})),
	on(PoliticiansActions.loadMorePoliticiansV2Success, (state, action) => ({
		...state,
		politicians: [...state.politicians, ...action.politicians],
		pagination: action.pagination
	})),
	on(PoliticiansActions.clearPoliticiansV2PaginationInfo, () => ({
		...initialState
	})),
	on(PoliticianDetailActions.loadPoliticiansV2DetailSuccess, (state, action) => ({
		...state,
		politician: action.politician
	}))
);
