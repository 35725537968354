import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import * as RoleDeleteActions from './roles-and-permissions-v2-delete-role.actions';
import { of, switchMap, tap } from 'rxjs';
import * as LoadingActions from '@core/store/actions/loading.actions';
import { catchError, map } from 'rxjs/operators';
import { Router } from '@angular/router';
import { NotificationService } from '@core/services/notification.service';
import { RolesServiceV2 } from '@core/services/rolesV2.service';
import { ServerError } from '@core/models/server-error.model';
import { TranslocoService } from '@ngneat/transloco';
import { environment } from '@environment/environment';
import { InteractionService } from '../../../../../shared/interaction/interaction.service';

@Injectable()
export class RolesAndPermissionsV2DeleteRoleEffects {
	init$ = createEffect(() =>
		this.actions$.pipe(
			ofType(RoleDeleteActions.initDeleteRole),
			switchMap(({ id }) => [LoadingActions.startLoading(), RoleDeleteActions.deleteRole({ id })])
		)
	);

	delete$ = createEffect(() =>
		this.actions$.pipe(
			ofType(RoleDeleteActions.deleteRole),
			switchMap(({ id }) =>
				this.rolesServiceV2.delete(id).pipe(
					map(() => RoleDeleteActions.deleteRoleSuccess({ id })),
					catchError(error => of(RoleDeleteActions.deleteRoleFailed({ error })))
				)
			)
		)
	);

	deleteSuccess$ = createEffect(
		() =>
			this.actions$.pipe(
				ofType(RoleDeleteActions.deleteRoleSuccess),
				tap(() =>
					this.router
						.navigateByUrl('amministrazione/ruoli-e-permessi')
						.then(() => this.notificationService.success('rolesAndPermissions.delete.deleteRoleSuccess'))
				)
			),
		{ dispatch: false }
	);

	deleteFailed$ = createEffect(() =>
		this.actions$.pipe(
			ofType(RoleDeleteActions.deleteRoleFailed),
			switchMap(({ error }) => {
				switch (error.internalCode) {
					case 'ROLE_ASSIGNED': {
						this.interactionService.confirm(
							`rolesAndPermissions.errors.${error.internalCode}.title`,
							this.translateService.translate(`rolesAndPermissions.errors.${error.internalCode}.message`),
							'PDM.GENERICS.BUTTON.OK',
							'error'
						);
						break;
					}
					default: {
						this.showDefaultError(error);
					}
				}

				return [LoadingActions.stopLoading()];
			})
		)
	);

	stopLoading$ = createEffect(() =>
		this.actions$.pipe(
			ofType(RoleDeleteActions.deleteRoleSuccess, RoleDeleteActions.deleteRoleFailed),
			switchMap(() => [LoadingActions.stopLoading()])
		)
	);

	private showDefaultError(error: ServerError) {
		if (environment.production) {
			this.notificationService.error().afterDismissed();
		} else {
			this.interactionService.confirm('Generic Error', JSON.stringify(error), 'PDM.GENERICS.BUTTON.OK', 'error');
		}
	}

	constructor(
		private readonly router: Router,
		private readonly actions$: Actions,
		private readonly rolesServiceV2: RolesServiceV2,
		private readonly translateService: TranslocoService,
		private readonly interactionService: InteractionService,
		private readonly notificationService: NotificationService
	) {}
}
