import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { ThemePalette } from '@angular/material/core';

@Component({
	selector: 'pdm-button',
	templateUrl: './button.component.html',
	styleUrls: ['./button.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class ButtonComponent {
	@Input()
	public type: 'primary' | 'secondary' | 'text' = 'primary';

	@Input()
	public disabled = false;

	@Input()
	public updating = false;

	@Input()
	public color: ThemePalette = 'primary';
}
