import { Injectable } from '@angular/core';
import { ArticlesV2Service } from '../../services/articles-v2.service';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import * as ArticlesV2ListActions from './articles-v2-list.actions';
import { of, switchMap } from 'rxjs';
import * as LoadingActions from '@core/store/actions/loading.actions';
import { catchError, map, tap } from 'rxjs/operators';
import { NotificationService } from '@core/services/notification.service';

@Injectable()
export class ArticlesV2ListEffects {
	init$ = createEffect(() =>
		this.actions$.pipe(
			ofType(ArticlesV2ListActions.initArticlesV2List),
			switchMap(({ params }) => [LoadingActions.startLoading(), ArticlesV2ListActions.loadArticlesV2List({ params })])
		)
	);

	load$ = createEffect(() =>
		this.actions$.pipe(
			ofType(ArticlesV2ListActions.loadArticlesV2List),
			switchMap(({ params }) =>
				this.articlesV2Service.listArticles(params).pipe(
					map(result =>
						ArticlesV2ListActions.loadArticlesV2ListSuccess({
							articles: result.results,
							pagination: result.paginatedInfo
						})
					),
					catchError(() => of(ArticlesV2ListActions.loadArticlesV2ListFailed()))
				)
			)
		)
	);

	loadAllFailed$ = createEffect(
		() =>
			this.actions$.pipe(
				ofType(ArticlesV2ListActions.loadArticlesV2ListFailed),
				tap(() => this.notificationService.error('PDM.GENERICS.ERROR.LOAD_DATA_ERROR'))
			),
		{ dispatch: false }
	);

	stopLoading$ = createEffect(() =>
		this.actions$.pipe(
			ofType(ArticlesV2ListActions.loadArticlesV2ListSuccess, ArticlesV2ListActions.loadArticlesV2ListFailed),
			switchMap(() => [LoadingActions.stopLoading()])
		)
	);

	constructor(
		private readonly actions$: Actions,
		private readonly articlesV2Service: ArticlesV2Service,
		private readonly notificationService: NotificationService
	) {}
}
