import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import * as LegislatureDetailActions from './legislature-detail.actions';
import { of, switchMap } from 'rxjs';
import * as LoadingActions from '../../../../core/store/actions/loading.actions';
import { catchError, map } from 'rxjs/operators';
import { NotificationService } from '@core/services/notification.service';
import { LegislaturesService } from '../../services/legislatures.service';
import { ServerError } from '@core/models/server-error.model';
import { environment } from '../../../../../environments/environment';
import { InteractionService } from '../../../../shared/interaction/interaction.service';

@Injectable()
export class LegislatureDetailEffects {
	init$ = createEffect(() =>
		this.actions$.pipe(
			ofType(LegislatureDetailActions.initDetailLegislature),
			switchMap(({ id }) => [LoadingActions.startLoading(), LegislatureDetailActions.detailLegislature({ id })])
		)
	);

	detail$ = createEffect(() =>
		this.actions$.pipe(
			ofType(LegislatureDetailActions.detailLegislature),
			switchMap(({ id }) =>
				this.legislaturesService.getById(id).pipe(
					map(legislatureDetail => LegislatureDetailActions.detailLegislatureSuccess({ legislatureDetail })),
					catchError(error => of(LegislatureDetailActions.detailLegislatureFailed({ error })))
				)
			)
		)
	);

	detailFailed$ = createEffect(() =>
		this.actions$.pipe(
			ofType(LegislatureDetailActions.detailLegislatureFailed),
			switchMap(({ error }) => {
				this.showDefaultError(error);

				return [LoadingActions.stopLoading()];
			})
		)
	);

	stopLoading$ = createEffect(() =>
		this.actions$.pipe(
			ofType(LegislatureDetailActions.detailLegislatureSuccess, LegislatureDetailActions.detailLegislatureFailed),
			switchMap(() => [LoadingActions.stopLoading()])
		)
	);

	private showDefaultError(error: ServerError) {
		if (environment.production) {
			this.notificationService.error().afterDismissed();
		} else {
			this.interactionService.confirm('Generic Error', JSON.stringify(error), 'PDM.GENERICS.BUTTON.OK', 'error');
		}
	}

	constructor(
		private readonly actions$: Actions,
		private readonly interactionService: InteractionService,
		private readonly legislaturesService: LegislaturesService,
		private readonly notificationService: NotificationService
	) {}
}
