import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import * as RoleDetailActions from './roles-and-permissions-v2-detail.actions';
import { of, switchMap } from 'rxjs';
import * as LoadingActions from '@core/store/actions/loading.actions';
import { catchError, map } from 'rxjs/operators';
import { RolesServiceV2 } from '@core/services/rolesV2.service';
import * as RoleDeleteActions from '../delete/roles-and-permissions-v2-delete-role.actions';

@Injectable()
export class RolesAndPermissionsV2DetailEffects {
	init$ = createEffect(() =>
		this.actions$.pipe(
			ofType(RoleDetailActions.initDetailRole),
			switchMap(({ roleId }) => [LoadingActions.startLoading(), RoleDetailActions.detailRole({ roleId })])
		)
	);

	detail$ = createEffect(() =>
		this.actions$.pipe(
			ofType(RoleDetailActions.detailRole),
			switchMap(({ roleId }) =>
				this.rolesServiceV2.getRole(roleId).pipe(
					map(role => RoleDetailActions.detailRoleSuccess({ role })),
					catchError(() => of(RoleDetailActions.detailRoleFailed()))
				)
			)
		)
	);

	detailFailed$ = createEffect(() =>
		this.actions$.pipe(
			ofType(RoleDetailActions.detailRoleSuccess, RoleDetailActions.detailRoleFailed),
			map(() => LoadingActions.stopLoading())
		)
	);

	stopLoading$ = createEffect(() =>
		this.actions$.pipe(
			ofType(RoleDeleteActions.deleteRoleSuccess, RoleDeleteActions.deleteRoleFailed),
			switchMap(() => [LoadingActions.stopLoading()])
		)
	);

	constructor(private readonly rolesServiceV2: RolesServiceV2, private readonly actions$: Actions) {}
}
