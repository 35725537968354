import { createAction, props } from '@ngrx/store';
import { EDangerZoneActionType } from '../../../models/enums/danger-zone-action-type.enum';
import { ServerError } from '@core/models/server-error.model';

export const initCollaboratorDangerAction = createAction(
	'[Collaborator] Init Danger Action',
	props<{ actionType: EDangerZoneActionType; userId: string }>()
);
export const doCollaboratorDangerAction = createAction(
	'[Collaborator] Do Danger Action',
	props<{ actionType: EDangerZoneActionType; userId: string }>()
);

export const collaboratorDangerActionSuccess = createAction(
	'[Collaborator] Danger Action Success',
	props<{ actionType: EDangerZoneActionType; userId: string }>()
);
export const collaboratorDangerActionFailed = createAction('[Collaborator] Danger Action Failed', props<{ error: ServerError }>());
