import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import * as ActivityV2CreateActions from './activities-V2-create.actions';
import { of, switchMap, tap } from 'rxjs';
import * as LoadingActions from '../../../../core/store/actions/loading.actions';
import { catchError, map } from 'rxjs/operators';
import { NotificationService } from '@core/services/notification.service';
import { Router } from '@angular/router';
import { InteractionService } from '../../../../shared/interaction/interaction.service';
import { TranslocoService } from '@ngneat/transloco';
import { showDefaultError } from '@core/utils/miscellaneous.utils';
import { EActivitiesV2ErrorCodes } from '../../models/enum/activities-V2-error-code.enum';
import { ActivitiesV2Service } from '../../services/activities-V2.service';

@Injectable()
export class ActivitiesV2CreateEffects {
	init$ = createEffect(() =>
		this.actions$.pipe(
			ofType(ActivityV2CreateActions.initCreateActivityV2),
			switchMap(({ activity }) => [LoadingActions.startLoading(), ActivityV2CreateActions.createActivityV2({ activity })])
		)
	);

	create$ = createEffect(() =>
		this.actions$.pipe(
			ofType(ActivityV2CreateActions.createActivityV2),
			switchMap(({ activity }) =>
				this.activityService.create(activity).pipe(
					map(result => ActivityV2CreateActions.createActivityV2Success({ activity: result })),
					catchError(error => of(ActivityV2CreateActions.createActivityV2Failed({ error })))
				)
			)
		)
	);

	createFailed$ = createEffect(() =>
		this.actions$.pipe(
			ofType(ActivityV2CreateActions.createActivityV2Failed),
			switchMap(({ error }) => {
				if (error.internalCode in EActivitiesV2ErrorCodes) {
					this.interactionService.confirm(
						`activities.detail.errors.${error.internalCode}.title`,
						this.translateService.translate(`activities.detail.errors.${error.internalCode}.message`),
						'PDM.GENERICS.BUTTON.OK',
						'error'
					);
				} else {
					showDefaultError(error, this.notificationService, this.interactionService);
				}

				return [LoadingActions.stopLoading()];
			})
		)
	);

	createSuccess$ = createEffect(
		() =>
			this.actions$.pipe(
				ofType(ActivityV2CreateActions.createActivityV2Success),
				tap(({ activity }) => {
					this.notificationService.success('activities.detail.success.create');
					this.router.navigate([`attivita/${activity.id}/modifica`]);
				})
			),
		{ dispatch: false }
	);

	stopLoading$ = createEffect(() =>
		this.actions$.pipe(
			ofType(ActivityV2CreateActions.createActivityV2Success, ActivityV2CreateActions.createActivityV2Failed),
			switchMap(() => [LoadingActions.stopLoading()])
		)
	);

	constructor(
		private readonly router: Router,
		private readonly actions$: Actions,
		private readonly activityService: ActivitiesV2Service,
		private readonly translateService: TranslocoService,
		private readonly interactionService: InteractionService,
		private readonly notificationService: NotificationService
	) {}
}
