import { Injectable } from '@angular/core';

import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import { TranslocoService } from '@ngneat/transloco';

@Injectable()
export class NotificationService {
	constructor(private readonly snackbar: MatSnackBar, private readonly translationService: TranslocoService) {}

	public success(message = 'Operazione conclusa con successo', duration = 5000) {
		const configurations: MatSnackBarConfig = {
			duration,
			panelClass: 'success'
		};

		const messageToShow = `\uD83C\uDF89 ${this.translationService.translate(message)}`;
		return this.snackbar.open(messageToShow, undefined, configurations);
	}

	public error(message = "Si è verificato un errore. Contattare l'amministratore di sistema.", duration = 5000) {
		const configurations: MatSnackBarConfig = {
			duration,
			panelClass: 'error'
		};

		const messageToShow = `🤷 ${this.translationService.translate(message)}`;
		return this.snackbar.open(messageToShow, undefined, configurations);
	}
}
