import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import * as AssignRoleActions from '../actions/assign-role.actions';
import * as LoadingActions from '../actions/loading.actions';
import { catchError, switchMap } from 'rxjs/operators';
import { RolesServiceV2 } from '@core/services/rolesV2.service';
import { of, tap } from 'rxjs';
import { NotificationService } from '@core/services/notification.service';
import { ServerError } from '@core/models/server-error.model';
import { environment } from '../../../../environments/environment';
import { InteractionService } from '../../../shared/interaction/interaction.service';

@Injectable()
export class AssignRoleEffects {
	init$ = createEffect(() =>
		this.actions$.pipe(
			ofType(AssignRoleActions.initAssignRolesV2),
			switchMap(({ userId, roleId, permissions }) => [
				LoadingActions.startLoading(),
				AssignRoleActions.assignRolesV2({ userId, roleId, permissions })
			])
		)
	);

	assignRole$ = createEffect(() =>
		this.actions$.pipe(
			ofType(AssignRoleActions.assignRolesV2),
			switchMap(({ userId, roleId, permissions }) =>
				this.rolesV2Service.assign(userId, roleId).pipe(
					switchMap(() => [AssignRoleActions.assignRolesSuccessV2({ userId, roleId, permissions })]),
					catchError(error => of(AssignRoleActions.assignRolesFailedV2({ error })))
				)
			)
		)
	);

	assignRoleSuccess$ = createEffect(
		() =>
			this.actions$.pipe(
				ofType(AssignRoleActions.assignRolesSuccessV2),
				tap(() => this.notificationService.success('collaborators.DETAIL.ROLE.SAVE_SUCCESS'))
			),
		{ dispatch: false }
	);

	patchFailed$ = createEffect(() =>
		this.actions$.pipe(
			ofType(AssignRoleActions.assignRolesFailedV2),
			switchMap(({ error }) => {
				this.showDefaultError(error);
				return [LoadingActions.stopLoading()];
			})
		)
	);

	stopLoading$ = createEffect(() =>
		this.actions$.pipe(
			ofType(AssignRoleActions.assignRolesSuccessV2, AssignRoleActions.assignRolesFailedV2),
			switchMap(() => [LoadingActions.stopLoading()])
		)
	);

	private showDefaultError(error: ServerError) {
		if (environment.production) {
			this.notificationService.error().afterDismissed();
		} else {
			this.interactionService.confirm('Generic Error', JSON.stringify(error), 'PDM.GENERICS.BUTTON.OK', 'error');
		}
	}

	constructor(
		private readonly actions$: Actions,
		private readonly rolesV2Service: RolesServiceV2,
		private readonly interactionService: InteractionService,
		private readonly notificationService: NotificationService
	) {}
}
