import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { PDMFile } from '@core/models/pdm-file.model';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { EFeatureType } from '@core/models/enums/feature-type.enum';

@Injectable()
export class FileUploadService {
	private static BASE_URL = 'v1/files';

	constructor(private readonly http: HttpClient) {}

	public upload(file: File, featureType: EFeatureType): Observable<PDMFile> {
		const formData = new FormData();
		formData.append('files', file);
		formData.append('type', `${featureType}_IMAGE`);

		return this.http.post<PDMFile[]>(`${FileUploadService.BASE_URL}/upload`, formData).pipe(map(result => result[0]));
	}

	public delete(id: string): Observable<void> {
		return this.http.delete(`${FileUploadService.BASE_URL}/${id}`).pipe(map(() => void 0));
	}
}
