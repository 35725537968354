import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { exhaustMap, of, switchMap, tap } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import * as FileActions from '../actions/file.actions';
import * as LoadingActions from '../actions/loading.actions';
import { FileUploadService } from '@core/services/file-upload.service';
import { NotificationService } from '@core/services/notification.service';
import { EFeatureType } from '@core/models/enums/feature-type.enum';
import { EFileTarget } from '@core/models/enums/file-target.enum';

@Injectable()
export class FileEffects {
	// UPLOAD
	uploadFileSuccess$ = createEffect(
		() =>
			this.actions$.pipe(
				ofType(
					FileActions.uploadActivityTextFileSuccess,
					FileActions.uploadActivityDescriptionFileSuccess,
					FileActions.uploadArticleFileSuccess
				),
				tap(() => this.notificationService.success('PDM.FILES.SUCCESS_UPLOAD'))
			),
		{ dispatch: false }
	);

	uploadFileFailed$ = createEffect(
		() =>
			this.actions$.pipe(
				ofType(FileActions.uploadFileFailed),
				tap(() => this.notificationService.error('PDM.FILES.ERROR_UPLOAD'))
			),
		{ dispatch: false }
	);

	// DELETE
	initDelete$ = createEffect(() =>
		this.actions$.pipe(
			ofType(FileActions.initDeleteFile),
			switchMap(({ id, featureType, targetFile }) => [
				LoadingActions.startLoading(),
				FileActions.deleteFile({
					id,
					featureType,
					targetFile
				})
			])
		)
	);

	deleteFile$ = createEffect(() =>
		this.actions$.pipe(
			ofType(FileActions.deleteFile),
			exhaustMap(({ id, featureType, targetFile }) => {
				return this.fileUploadService.delete(id).pipe(
					map(() => {
						switch (featureType) {
							case EFeatureType.ACTIVITY: {
								if (targetFile === EFileTarget.TEXT) {
									return FileActions.deleteActivityTextFileSuccess({ id });
								}

								if (targetFile === EFileTarget.DESCRIPTION) {
									return FileActions.deleteActivityDescriptionFileSuccess({ id });
								}

								throw new Error('Invalid target file');
							}
							case EFeatureType.ARTICLE:
								return FileActions.deleteArticleFileSuccess({ id });
						}
					}),
					catchError(() => {
						switch (featureType) {
							case EFeatureType.ACTIVITY:
								return of(FileActions.deleteActivityFileFailed);
							case EFeatureType.ARTICLE:
								return of(FileActions.deleteArticleFileFailed);
						}
					})
				);
			})
		)
	);

	deleteFileSuccess$ = createEffect(
		() =>
			this.actions$.pipe(
				ofType(
					FileActions.deleteActivityTextFileSuccess,
					FileActions.deleteActivityDescriptionFileSuccess,
					FileActions.deleteArticleFileSuccess
				),
				tap(() => this.notificationService.success('PDM.FILES.SUCCESS_DELETE'))
			),
		{ dispatch: false }
	);

	// STOP
	stopLoading$ = createEffect(() =>
		this.actions$.pipe(
			ofType(
				FileActions.uploadArticleFileSuccess,
				FileActions.uploadActivityTextFileSuccess,
				FileActions.uploadActivityDescriptionFileSuccess,
				FileActions.uploadFileFailed,
				FileActions.deleteArticleFileSuccess,
				FileActions.deleteActivityTextFileSuccess,
				FileActions.deleteActivityDescriptionFileSuccess,
				FileActions.deleteArticleFileFailed,
				FileActions.deleteActivityFileFailed
			),
			switchMap(() => [LoadingActions.stopLoading()])
		)
	);

	constructor(
		private readonly actions$: Actions,
		private readonly fileUploadService: FileUploadService,
		private readonly notificationService: NotificationService
	) {}
}
