import { createAction, props } from '@ngrx/store';
import { LegislatureDetail } from '../../../../models/legislature-detail.model';
import { ServerError } from '@core/models/server-error.model';
import { MinistryUpdate } from '../../../../models/ministry-update.model';

export const initUpdateMinistries = createAction(
	'[Legislature Ministry] Init Update Ministries',
	props<{ legislatureId: string; ministries: MinistryUpdate[] }>()
);
export const updateMinistries = createAction(
	'[Legislature Ministry] Update Ministries',
	props<{ legislatureId: string; ministries: MinistryUpdate[] }>()
);
export const updateMinistriesSuccess = createAction(
	'[Legislature Ministry] Update Ministries success',
	props<{ legislatureDetail: LegislatureDetail }>()
);
export const updateMinistriesFailed = createAction('[Legislature Ministry] Update Ministries failed', props<{ error: ServerError }>());
