import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import * as RoleUnlinkPermissionsActions from './roles-and-permissions-v2-unlink-permission.action';
import { of, switchMap, tap } from 'rxjs';
import * as LoadingActions from '@core/store/actions/loading.actions';
import { catchError, map } from 'rxjs/operators';
import { NotificationService } from '@core/services/notification.service';
import { RolesServiceV2 } from '@core/services/rolesV2.service';

@Injectable()
export class RolesAndPermissionsV2UnlinkPermissionEffects {
	init$ = createEffect(() =>
		this.actions$.pipe(
			ofType(RoleUnlinkPermissionsActions.initUnlinkPermissions),
			switchMap(({ roleId, permissions }) => [
				LoadingActions.startLoading(),
				RoleUnlinkPermissionsActions.unlinkPermissions({
					roleId,
					permissions
				})
			])
		)
	);

	unlink$ = createEffect(() =>
		this.actions$.pipe(
			ofType(RoleUnlinkPermissionsActions.unlinkPermissions),
			switchMap(({ roleId, permissions }) =>
				this.rolesServiceV2
					.unlinkPermissions(
						roleId,
						permissions.map(permissions => permissions.id)
					)
					.pipe(
						map(() => RoleUnlinkPermissionsActions.unlinkPermissionsSuccess({ roleId, permissions })),
						catchError(error => of(RoleUnlinkPermissionsActions.unlinkPermissionsFailed({ error })))
					)
			)
		)
	);

	unlinkSuccess$ = createEffect(
		() =>
			this.actions$.pipe(
				ofType(RoleUnlinkPermissionsActions.unlinkPermissionsSuccess),
				tap(() => this.notificationService.success('rolesAndPermissions.unlinkPermissions.unlinkPermissionsSuccess'))
			),
		{ dispatch: false }
	);

	unlinkFailed$ = createEffect(() =>
		this.actions$.pipe(
			ofType(RoleUnlinkPermissionsActions.unlinkPermissionsSuccess, RoleUnlinkPermissionsActions.unlinkPermissionsFailed),
			map(() => LoadingActions.stopLoading())
		)
	);

	constructor(
		private readonly rolesServiceV2: RolesServiceV2,
		private readonly actions$: Actions,
		private readonly notificationService: NotificationService
	) {}
}
