import { createAction, props } from '@ngrx/store';
import { ServerError } from '@core/models/server-error.model';
import { ActivityV2Detail } from '../../models/activity-V2-detail.model';

export const initApproveActivityV2 = createAction(
	'[Activities V2] Approve Init',
	props<{ id: string; publicationDateTime: string; message?: string }>()
);
export const approveActivityV2 = createAction(
	'[Activities V2] Approve',
	props<{ id: string; publicationDateTime: string; message?: string }>()
);
export const approveActivityV2Success = createAction('[Activities V2] Approve success', props<{ activity: ActivityV2Detail }>());
export const approveActivityV2Failed = createAction('[Activities V2] Approve failed', props<{ error: ServerError }>());
