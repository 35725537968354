import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import * as ArticleApproveActions from './articles-V2-approve.actions';
import { of, switchMap } from 'rxjs';
import * as LoadingActions from '../../../../core/store/actions/loading.actions';
import * as InternalNoteActions from '../../../../core/store/actions/internal-note-actions';
import { catchError, map } from 'rxjs/operators';
import { NotificationService } from '@core/services/notification.service';
import { EFeatureType } from '@core/models/enums/feature-type.enum';
import { ArticlesV2Service } from '../../services/articles-v2.service';
import { InteractionService } from '../../../../shared/interaction/interaction.service';
import { TranslocoService } from '@ngneat/transloco';
import { EArticleErrorCodes } from '../../models/enum/article-error-code.enum';
import { showDefaultError } from '@core/utils/miscellaneous.utils';

@Injectable()
export class ArticlesV2ApproveEffects {
	init$ = createEffect(() =>
		this.actions$.pipe(
			ofType(ArticleApproveActions.initApproveArticle),
			switchMap(({ id, publicationDateTime, message }) => [
				LoadingActions.startLoading(),
				ArticleApproveActions.approveArticle({
					id,
					publicationDateTime,
					message
				})
			])
		)
	);

	approve$ = createEffect(() =>
		this.actions$.pipe(
			ofType(ArticleApproveActions.approveArticle),
			switchMap(({ id, publicationDateTime, message }) =>
				this.articlesService.approve(id, publicationDateTime, message).pipe(
					map(result => ArticleApproveActions.approveArticleSuccess({ article: result })),
					catchError(error => of(ArticleApproveActions.approveArticleFailed({ error })))
				)
			)
		)
	);

	approveSuccess$ = createEffect(() =>
		this.actions$.pipe(
			ofType(ArticleApproveActions.approveArticleSuccess),
			switchMap(({ article }) => {
				this.notificationService.success('articles.detail.success.approve');
				return [InternalNoteActions.initLoadNote({ entityId: article.id, feature: EFeatureType.ARTICLE })];
			})
		)
	);

	approveFailed$ = createEffect(() =>
		this.actions$.pipe(
			ofType(ArticleApproveActions.approveArticleFailed),
			switchMap(({ error }) => {
				if (error.internalCode in EArticleErrorCodes) {
					this.interactionService.confirm(
						`articles.detail.errors.${error.internalCode}.title`,
						this.translateService.translate(`articles.detail.errors.${error.internalCode}.message`),
						'PDM.GENERICS.BUTTON.OK',
						'error'
					);
				} else {
					showDefaultError(error, this.notificationService, this.interactionService);
				}

				return [LoadingActions.stopLoading()];
			})
		)
	);

	stopLoading$ = createEffect(() =>
		this.actions$.pipe(
			ofType(ArticleApproveActions.approveArticleSuccess, ArticleApproveActions.approveArticleFailed),
			switchMap(() => [LoadingActions.stopLoading()])
		)
	);

	constructor(
		private readonly actions$: Actions,
		private readonly articlesService: ArticlesV2Service,
		private readonly translateService: TranslocoService,
		private readonly interactionService: InteractionService,
		private readonly notificationService: NotificationService
	) {}
}
