import { AppState } from '../../core.module';
import { createSelector } from '@ngrx/store';
import { ProfileState } from './profile.reducer';
import { AuthenticatedUser } from '../models/authenticated-user.model';

export const selectProfile = (state: AppState) => state.profile;

export const getMe = createSelector(selectProfile, (state: ProfileState) => state.user);

export const getCompleteName = createSelector(selectProfile, (state: ProfileState) => `${state.user?.firstName} ${state.user?.lastName}`);

export const getEmail = createSelector(selectProfile, (state: ProfileState) => `${state.user?.email}`);

export const getUserPermissions = createSelector(selectProfile, (state: ProfileState) => state.user?.permissions);

export const getInitials = createSelector(
	getMe,
	(authenticated: AuthenticatedUser) =>
		`${authenticated.firstName ? authenticated.firstName[0] : ''}${authenticated.lastName ? authenticated.lastName[0] : ''}`
);
