import { createReducer, on } from '@ngrx/store';
import { Pagination } from '@core/models/miscellaneous/server-response.model';
import * as CollaboratorsListActions from './collaborators-list.actions';
import { CollaboratorV2 } from '../../models/collaborators-v2.model';

export interface CollaboratorsListState {
	collaborators: CollaboratorV2[];
	pagination: Pagination | undefined;
}

const initialState: CollaboratorsListState = {
	collaborators: [],
	pagination: undefined
};

export const collaboratorsListReducers = createReducer(
	initialState,
	on(CollaboratorsListActions.loadCollaboratorsSuccess, (state, action) => ({
		...state,
		collaborators: action.collaborators,
		pagination: action.pagination
	}))
);
