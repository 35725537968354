import { Injectable } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { noNewLine } from '@core/utils/forms/form.validators';
import { RolesAndPermissionsCreateForm } from '../models/roles-and-permissions-create.model';
import { RoleV2 } from '@core/models/roles/roleV2.model';

@Injectable()
export class RolesAndPermissionsFormMapperService {
	constructor(private readonly formBuilder: FormBuilder) {}

	public buildEmptyForm(): RolesAndPermissionsCreateForm {
		return this.formBuilder.group({
			name: [
				'',
				{
					validators: [Validators.required, Validators.minLength(3), Validators.maxLength(50)],
					nonNullable: true
				}
			],
			description: [
				'',
				{
					validators: [Validators.required, Validators.minLength(3), Validators.maxLength(200)],
					nonNullable: true
				}
			],
			permissionsIds: [
				[],
				{
					validators: [Validators.required, Validators.min(1)],
					nonNullable: true
				}
			]
		}) as unknown as RolesAndPermissionsCreateForm;
	}

	public buildFromExisting(role: RoleV2): RolesAndPermissionsCreateForm {
		return this.formBuilder.group({
			name: [
				role.name,
				{
					validators: [Validators.required, Validators.minLength(3), Validators.maxLength(200), noNewLine()],
					nonNullable: true
				}
			],
			description: [
				role.description,
				{
					validators: [Validators.required, Validators.minLength(3), Validators.maxLength(200)],
					nonNullable: true
				}
			],
			permissionsIds: [
				role.permissions.map(permission => permission.id),
				{
					validators: [Validators.required, Validators.min(1)],
					nonNullable: true
				}
			]
		}) as unknown as RolesAndPermissionsCreateForm;
	}
}
