import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import * as LegislatureMinistryDeleteActions from './legislature-ministry-delete.action';
import { of, switchMap, tap } from 'rxjs';
import * as LoadingActions from '../../../../../../core/store/actions/loading.actions';
import { catchError, map } from 'rxjs/operators';
import { NotificationService } from '@core/services/notification.service';
import { LegislaturesService } from '../../../../services/legislatures.service';
import { InteractionService } from '../../../../../../shared/interaction/interaction.service';
import { ELegislaturesErrorCode } from '@legislatures/models/enum/legislatures-error-code.enum';
import { TranslocoService } from '@ngneat/transloco';
import { showDefaultError } from '@core/utils/miscellaneous.utils';

@Injectable()
export class LegislatureMinistryDeleteEffects {
	init$ = createEffect(() =>
		this.actions$.pipe(
			ofType(LegislatureMinistryDeleteActions.initDeleteMinistries),
			switchMap(({ legislatureId, ids }) => [
				LoadingActions.startLoading(),
				LegislatureMinistryDeleteActions.deleteMinistries({ legislatureId, ids })
			])
		)
	);

	delete$ = createEffect(() =>
		this.actions$.pipe(
			ofType(LegislatureMinistryDeleteActions.deleteMinistries),
			switchMap(({ legislatureId, ids }) =>
				this.legislaturesService.deleteMinistries(legislatureId, ids).pipe(
					map(legislatureDetail => LegislatureMinistryDeleteActions.deleteMinistriesSuccess({ legislatureDetail })),
					catchError(error => of(LegislatureMinistryDeleteActions.deleteMinistriesFailed({ error })))
				)
			)
		)
	);

	deleteSuccess$ = createEffect(
		() =>
			this.actions$.pipe(
				ofType(LegislatureMinistryDeleteActions.deleteMinistriesSuccess),
				tap(() => this.notificationService.success('legislatures.detail.success.delete'))
			),
		{ dispatch: false }
	);

	deleteFailed$ = createEffect(() =>
		this.actions$.pipe(
			ofType(LegislatureMinistryDeleteActions.deleteMinistriesFailed),
			switchMap(({ error }) => {
				if (error.internalCode in ELegislaturesErrorCode) {
					this.interactionService.confirm(
						`legislatures.errors.${error.internalCode}.title`,
						this.translateService.translate(`legislatures.errors.${error.internalCode}.message`),
						'PDM.GENERICS.BUTTON.OK',
						'error'
					);
				} else {
					showDefaultError(error, this.notificationService, this.interactionService);
				}

				return [LoadingActions.stopLoading()];
			})
		)
	);

	stopLoading$ = createEffect(() =>
		this.actions$.pipe(
			ofType(LegislatureMinistryDeleteActions.deleteMinistriesSuccess, LegislatureMinistryDeleteActions.deleteMinistriesFailed),
			switchMap(() => [LoadingActions.stopLoading()])
		)
	);

	constructor(
		private readonly actions$: Actions,
		private readonly interactionService: InteractionService,
		private readonly legislaturesService: LegislaturesService,
		private readonly translateService: TranslocoService,
		private readonly notificationService: NotificationService
	) {}
}
