import { createReducer, on } from '@ngrx/store';
import { LoadingActions } from '../actions';

export interface LoadingState {
	bucket: number;
}

export const initialState: LoadingState = {
	bucket: 0
};

export const loadingReducer = createReducer(
	initialState,
	on(LoadingActions.startLoading, state => ({
		bucket: state.bucket + 1
	})),
	on(LoadingActions.stopLoading, state => ({
		bucket: state.bucket - 1
	}))
);
