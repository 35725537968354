import { createAction, props } from '@ngrx/store';
import { PermissionV2 } from '@core/models/roles/permissionV2.model';
import { ServerError } from '@core/models/server-error.model';

export const initUnlinkPermissions = createAction(
	'[Roles and Permissions V2] Init Unlink',
	props<{
		roleId: string;
		permissions: PermissionV2[];
	}>()
);
export const unlinkPermissions = createAction(
	'[Roles and Permissions V2] Unlink',
	props<{
		roleId: string;
		permissions: PermissionV2[];
	}>()
);
export const unlinkPermissionsSuccess = createAction(
	'[Roles and Permissions V2] Unlink Success',
	props<{ roleId: string; permissions: PermissionV2[] }>()
);
export const unlinkPermissionsFailed = createAction('[Roles and Permissions V2] Unlink Failed', props<{ error: ServerError }>());
