export enum EArticleStatus {
	DRAFT = 'DRAFT',
	IN_REVIEW = 'IN_REVIEW',
	READY = 'READY',
	PUBLISHED = 'PUBLISHED',
	REWORK = 'REWORK',
	ARCHIVED = 'ARCHIVED',
	DELETED = 'DELETED',
	NEW = 'NEW'
}

export namespace EArticleStatus {
	export function values(): EArticleStatus[] {
		return [
			EArticleStatus.DRAFT,
			EArticleStatus.IN_REVIEW,
			EArticleStatus.READY,
			EArticleStatus.PUBLISHED,
			EArticleStatus.REWORK,
			EArticleStatus.ARCHIVED,
			EArticleStatus.DELETED,
			EArticleStatus.NEW
		];
	}
}
