import { Injectable } from '@angular/core';
import { filter, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { getUserPermissions } from '@core/profile/store/profile.selector';
import { EArticleStatus } from '../models/enum/article-status.enum';
import { EUserPermissions } from '@core/models/enums/user-permissions.enum';
import { AppState } from '@core/core.module';
import { ArticleV2Detail } from '../models/article-v2-detail.model';
import { ArticleAllowedActionsV2 } from '../models/article-V2-allowed-actions.model';

@Injectable()
export class ArticleDetailPermissionsService {
	constructor(private readonly store$: Store<AppState>) {}

	public getPermission({ status, isMine }: ArticleV2Detail): Observable<ArticleAllowedActionsV2> {
		return this.store$.select(getUserPermissions).pipe(
			filter(permissions => permissions?.length > 0),
			map((permissions: string[]) => {
				const allowedActions: ArticleAllowedActionsV2 = {
					canPhysicallyDelete:
						EArticleStatus.DRAFT === status &&
						(permissions.includes(EUserPermissions.ARTICLE_DELETE_ANY.valueOf()) ||
							(permissions.includes(EUserPermissions.ARTICLE_DELETE_OWN.valueOf()) && isMine)),
					canAskReview:
						[EArticleStatus.DRAFT, EArticleStatus.REWORK].includes(status) &&
						(permissions.includes(EUserPermissions.ARTICLE_REVIEW_ANY.valueOf()) ||
							(permissions.includes(EUserPermissions.ARTICLE_REVIEW_OWN.valueOf()) && isMine)),
					canApprove:
						EArticleStatus.IN_REVIEW === status &&
						(permissions.includes(EUserPermissions.ARTICLE_APPROVE_ANY.valueOf()) ||
							(permissions.includes(EUserPermissions.ARTICLE_APPROVE_OWN.valueOf()) && isMine)),
					canRework:
						EArticleStatus.IN_REVIEW === status &&
						(permissions.includes(EUserPermissions.ARTICLE_REWORK_ANY.valueOf()) ||
							(permissions.includes(EUserPermissions.ARTICLE_REWORK_OWN.valueOf()) && isMine)),
					canPublish:
						EArticleStatus.READY === status &&
						(permissions.includes(EUserPermissions.ARTICLE_PUBLISH_ANY.valueOf()) ||
							(permissions.includes(EUserPermissions.ARTICLE_PUBLISH_OWN.valueOf()) && isMine)),
					canArchive:
						EArticleStatus.PUBLISHED === status &&
						(permissions.includes(EUserPermissions.ARTICLE_ARCHIVE_ANY.valueOf()) ||
							(permissions.includes(EUserPermissions.ARTICLE_ARCHIVE_OWN.valueOf()) && isMine)),
					canLogicallyDelete:
						![EArticleStatus.DRAFT, EArticleStatus.DELETED, EArticleStatus.ARCHIVED].includes(status) &&
						(permissions.includes(EUserPermissions.ARTICLE_REMOVE_ANY.valueOf()) ||
							(permissions.includes(EUserPermissions.ARTICLE_REMOVE_OWN.valueOf()) && isMine)),
					canBeEdited:
						![EArticleStatus.DELETED, EArticleStatus.ARCHIVED, EArticleStatus.READY, EArticleStatus.PUBLISHED].includes(
							status
						) &&
						(permissions.includes(EUserPermissions.ARTICLE_UPDATE_ANY.valueOf()) ||
							(permissions.includes(EUserPermissions.ARTICLE_UPDATE_OWN.valueOf()) && isMine))
				};

				return allowedActions;
			})
		);
	}

	public getInitialPermission() {
		const allowedActions: ArticleAllowedActionsV2 = {
			canPhysicallyDelete: false,
			canAskReview: false,
			canApprove: false,
			canRework: false,
			canPublish: false,
			canArchive: false,
			canLogicallyDelete: false,
			canBeEdited: true
		};

		return allowedActions;
	}
}
