import { AfterViewInit, DestroyRef, Directive, ElementRef, inject, Input } from '@angular/core';
import { map, startWith } from 'rxjs/operators';
import { MatFormField } from '@angular/material/form-field';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Directive({
	selector: '[pdmMaxAllowedCharacters]',
	standalone: true
})
export class CharacterCountDirective implements AfterViewInit {
	private readonly destroyRef = inject(DestroyRef);
	private _maxAllowedCharacters = 0;

	@Input()
	public set pdmMaxAllowedCharacters(value: number | string) {
		this._maxAllowedCharacters = +value;
	}

	public constructor(private readonly elementRef: ElementRef, private readonly matFormField: MatFormField) {}

	public ngAfterViewInit(): void {
		const control = this.matFormField._control;
		if (!control) {
			return;
		}

		if (!control.ngControl) throw new Error('unexpected value for ngControl (null)');

		control.ngControl
			.valueChanges!.pipe(
				startWith(control.value as number),
				map(value => (value as string)?.length ?? 0),
				takeUntilDestroyed(this.destroyRef)
			)
			.subscribe(length => (this.elementRef.nativeElement.innerHTML = `${length}/${this._maxAllowedCharacters}`));
	}
}
