import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import * as ArticleRejectActions from './articles-V2-reject.actions';
import { of, switchMap } from 'rxjs';
import * as LoadingActions from '../../../../core/store/actions/loading.actions';
import * as InternalNoteActions from '../../../../core/store/actions/internal-note-actions';
import { catchError, map } from 'rxjs/operators';
import { NotificationService } from '@core/services/notification.service';
import { EFeatureType } from '@core/models/enums/feature-type.enum';
import { ArticlesV2Service } from '../../services/articles-v2.service';
import { InteractionService } from '../../../../shared/interaction/interaction.service';
import { EArticleErrorCodes } from '../../models/enum/article-error-code.enum';
import { showDefaultError } from '@core/utils/miscellaneous.utils';
import { TranslocoService } from '@ngneat/transloco';

@Injectable()
export class ArticlesV2RejectEffects {
	init$ = createEffect(() =>
		this.actions$.pipe(
			ofType(ArticleRejectActions.initRejectArticle),
			switchMap(({ id, message }) => [
				LoadingActions.startLoading(),
				ArticleRejectActions.rejectArticle({
					id,
					message
				})
			])
		)
	);

	reject$ = createEffect(() =>
		this.actions$.pipe(
			ofType(ArticleRejectActions.rejectArticle),
			switchMap(({ id, message }) =>
				this.articlesService.reject(id, message).pipe(
					map(result => ArticleRejectActions.rejectArticleSuccess({ article: result })),
					catchError(error => of(ArticleRejectActions.rejectArticleFailed({ error })))
				)
			)
		)
	);

	rejectSuccess$ = createEffect(() =>
		this.actions$.pipe(
			ofType(ArticleRejectActions.rejectArticleSuccess),
			switchMap(({ article }) => {
				this.notificationService.success('articles.detail.success.reject');
				return [InternalNoteActions.initLoadNote({ entityId: article.id, feature: EFeatureType.ARTICLE })];
			})
		)
	);

	rejectFailed$ = createEffect(() =>
		this.actions$.pipe(
			ofType(ArticleRejectActions.rejectArticleFailed),
			switchMap(({ error }) => {
				if (error.internalCode in EArticleErrorCodes) {
					this.interactionService.confirm(
						`articles.detail.errors.${error.internalCode}.title`,
						this.translateService.translate(`articles.detail.errors.${error.internalCode}.message`),
						'PDM.GENERICS.BUTTON.OK',
						'error'
					);
				} else {
					showDefaultError(error, this.notificationService, this.interactionService);
				}

				return [LoadingActions.stopLoading()];
			})
		)
	);

	stopLoading$ = createEffect(() =>
		this.actions$.pipe(
			ofType(ArticleRejectActions.rejectArticleSuccess, ArticleRejectActions.rejectArticleFailed),
			switchMap(() => [LoadingActions.stopLoading()])
		)
	);

	constructor(
		private readonly actions$: Actions,
		private readonly articlesService: ArticlesV2Service,
		private readonly translateService: TranslocoService,
		private readonly interactionService: InteractionService,
		private readonly notificationService: NotificationService
	) {}
}
