import { createReducer, on } from '@ngrx/store';
import * as ProvincesV2Actions from '../actions/provincesV2.actions';
import { Province } from '@core/models/miscellaneous/province.model';

export interface ProvincesState {
	provinces: { [key: string]: Province[] } | undefined;
}

const initialState: ProvincesState = {
	provinces: undefined
};

export const provincesReducer = createReducer(
	initialState,
	on(ProvincesV2Actions.loadProvincesSuccess, (state, action) => ({
		...state,
		provinces: {
			...state.provinces,
			[action.regionId]: action.provinces
		}
	}))
);
