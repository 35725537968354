import { Injectable } from '@angular/core';
import { MenuItem } from '../models/menu-item.model';
import { EItemPosition } from '../models/item-position.enum';
import { EUserPermissions } from '@core/models/enums/user-permissions.enum';

@Injectable()
export class MenuItemService {
	public items: MenuItem[] = [];

	constructor() {
		this.items = this.createMenuItems();
	}

	public getSidebarElements(): MenuItem[] {
		return this.items.filter(item => item.position === EItemPosition.SIDEBAR);
	}

	public getProfileMenuElements(): MenuItem[] {
		return this.items.filter(item => item.position === EItemPosition.PROFILE_MENU);
	}

	private createMenuItems(): MenuItem[] {
		return [...this.buildProfileMenuElements(), ...this.buildSidebarElements()];
	}

	private buildProfileMenuElements(): MenuItem[] {
		return [
			{
				label: 'my_account',
				icon: 'person',
				url: 'account',
				position: EItemPosition.PROFILE_MENU,
				permissions: [],
				hasDivider: false
			},
			{
				label: 'my_sessions',
				icon: 'format_list_bulleted',
				url: 'sessioni',
				position: EItemPosition.PROFILE_MENU,
				permissions: [],
				hasDivider: false
			}
		];
	}

	private buildSidebarElements(): MenuItem[] {
		return [
			{
				label: 'dashboard',
				icon: 'dashboard',
				url: '',
				position: EItemPosition.SIDEBAR,
				permissions: [],
				hasDivider: false
			},
			{
				label: 'collaboratori',
				icon: 'badge',
				url: 'amministrazione/collaboratori',
				queryParams: { isBlocked: false, isDeleted: false },
				position: EItemPosition.SIDEBAR,
				permissions: [EUserPermissions.USER_LIST],
				hasDivider: false
			},
			{
				label: 'ruoli_e_permessi',
				icon: 'admin_panel_settings',
				url: 'amministrazione/ruoli-e-permessi',
				position: EItemPosition.SIDEBAR,
				permissions: [EUserPermissions.ROLE_LIST],
				hasDivider: true
			},
			{
				label: 'politicians',
				icon: 'groups',
				url: 'politici',
				position: EItemPosition.SIDEBAR,
				permissions: [],
				hasDivider: false
			},
			{
				label: 'ministry',
				icon: 'apartment',
				url: 'consiglio-dei-ministri',
				position: EItemPosition.SIDEBAR,
				permissions: [],
				hasDivider: false
			},
			{
				label: 'groups',
				icon: 'groups',
				url: 'gruppi',
				position: EItemPosition.SIDEBAR,
				permissions: [],
				hasDivider: true
			},
			{
				label: 'articoli',
				icon: 'article',
				url: 'articoli',
				position: EItemPosition.SIDEBAR,
				permissions: [EUserPermissions.ARTICLE_LIST_OWN, EUserPermissions.ARTICLE_LIST_ANY],
				hasDivider: false
			},
			{
				label: 'activity',
				icon: 'balance',
				url: 'attivita',
				position: EItemPosition.SIDEBAR,
				permissions: [EUserPermissions.ACTIVITY_LIST_ANY, EUserPermissions.ACTIVITY_LIST_OWN],
				hasDivider: false
			}
		];
	}
}
