import { ActionReducer, INIT } from '@ngrx/store';
import { logoutSuccess } from './auth/store/auth.actions';

export function clearState(reducer: ActionReducer<any>): ActionReducer<any> {
	return (state, action) => {
		if (action != null && action.type === logoutSuccess.type) {
			return reducer(undefined, { type: INIT });
		}
		return reducer(state, action);
	};
}
