import { NgModule } from '@angular/core';
import { InteractionService } from './interaction.service';
import { InteractionComponent } from './components/interaction.component';
import { CommonModule } from '@angular/common';
import { SimpleTextInputComponent } from './components/simple-text-input.component';
import { ReactiveFormsModule } from '@angular/forms';
import { HasErrorDirective } from '@uikit/directives/has-error.directive';
import { CharacterCountDirective } from '@uikit/directives/character-count.directive';
import { MatIconModule } from '@angular/material/icon';
import { MatDialogModule } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { TranslocoPipe } from '@ngneat/transloco';

@NgModule({
	imports: [
		MatDialogModule,
		MatButtonModule,
		CommonModule,

		MatFormFieldModule,
		MatInputModule,
		ReactiveFormsModule,
		HasErrorDirective,
		CharacterCountDirective,
		MatIconModule,
		TranslocoPipe
	],
	declarations: [InteractionComponent, SimpleTextInputComponent],
	providers: [InteractionService]
})
export class InteractionModule {}
