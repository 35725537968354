import { Injectable } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { noNewLine } from '@core/utils/forms/form.validators';
import { ArticleV2CreateForm } from '../models/article-V2-create.model';

@Injectable()
export class ArticlesFormMapperService {
	constructor(private readonly formBuilder: FormBuilder) {}

	public getArticleForm() {
		return this.formBuilder.group({
			title: [
				'',
				{
					validators: [Validators.required, Validators.minLength(3), Validators.maxLength(200), noNewLine()],
					nonNullable: true
				}
			],
			text: ['', { validators: Validators.minLength(3) }],
			description: ['', { validators: [Validators.minLength(3), Validators.maxLength(500)] }],
			categoryId: ['', { validators: [Validators.required] }],
			attachmentIds: [[]],
			tags: [[], { validators: [Validators.minLength(1), Validators.maxLength(50)] }]
		}) as unknown as ArticleV2CreateForm;
	}
}
