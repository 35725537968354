import { createAction, props } from '@ngrx/store';
import { Pagination } from '@core/models/miscellaneous/server-response.model';
import { EPoliticianType } from '../models/enums/politician-type.enum';
import { Politician } from '../models/politician.model';

export const initPoliticiansV2 = createAction(
	'[PoliticiansV2] Init',
	props<{
		politicianType: EPoliticianType;
		search: string | null;
	}>()
);
export const loadPoliticiansV2 = createAction(
	'[PoliticiansV2] Load',
	props<{
		politicianType: EPoliticianType;
		search: string | null;
	}>()
);

export const loadMorePoliticiansV2 = createAction(
	'[PoliticiansV2] Load more',
	props<{
		politicianType: EPoliticianType;
	}>()
);

export const loadPoliticiansV2Success = createAction(
	'[PoliticiansV2] Load success',
	props<{
		politicians: Politician[];
		pagination: Pagination;
	}>()
);

export const loadMorePoliticiansV2Success = createAction(
	'[PoliticiansV2] Load more success',
	props<{
		politicians: Politician[];
		pagination: Pagination;
	}>()
);
export const loadPoliticiansV2Failed = createAction('[PoliticiansV2] Load failed');

export const clearPoliticiansV2PaginationInfo = createAction('[PoliticiansV2] Clear pagination info');
