import { Injectable } from '@angular/core';
import { AppState } from '../core.module';
import { Store } from '@ngrx/store';
import { getUserPermissions } from '@core/profile/store/profile.selector';
import { filter, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { EUserPermissions } from '@core/models/enums/user-permissions.enum';

@Injectable()
export class UserPermissionService {
	constructor(private readonly store$: Store<AppState>) {}

	public hasPermission(permission: EUserPermissions) {
		return this.store$.select(getUserPermissions).pipe(
			filter(permissions => permissions !== undefined),
			map(permissions => permissions.includes(permission.valueOf()))
		);
	}

	public hasAllPermissions(...permissionsToHave: EUserPermissions[]) {
		if (permissionsToHave.length === 0) {
			return of(true);
		}

		return this.store$.select(getUserPermissions).pipe(
			filter(permissions => permissions !== undefined),
			map(permissions => permissionsToHave.every(permissionToHave => permissions.includes(permissionToHave)))
		);
	}

	public hasSomePermissions(...permissionsToHave: EUserPermissions[]) {
		if (permissionsToHave.length === 0) {
			return of(true);
		}

		return this.store$.select(getUserPermissions).pipe(
			filter(permissions => permissions !== undefined),
			map(permissions => permissionsToHave.some(permissionToHave => permissions.includes(permissionToHave)))
		);
	}
}
