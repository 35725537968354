import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { InteractionComponent } from './components/interaction.component';
import { map } from 'rxjs/operators';
import { InteractionData } from './components/models/interaction-data.model';
import { SimpleTextInputComponent } from './components/simple-text-input.component';
import { ServerError } from '@core/models/server-error.model';
import { ErrorValidationComponent } from '@uikit/components/error-validation/error-validation.component';
import { MatDialog } from '@angular/material/dialog';
import { ErrorValidationV2Component } from '@uikit/components/error-validation-V2/error-validation-V2.component';

@Injectable()
export class InteractionService {
	constructor(private readonly dialog: MatDialog) {}

	public confirmOrDiscard(
		title: string,
		text: string,
		confirmButtonText = 'Conferma',
		discardButtonText = 'Annulla',
		icon?: 'error' | 'warning'
	): Observable<boolean> {
		const data: InteractionData = { title, text, confirmButtonText, discardButtonText, icon };
		return this.dialog
			.open(InteractionComponent, {
				data,
				disableClose: true,
				autoFocus: false
			})
			.afterClosed()
			.pipe(map(value => value === true));
	}

	public confirm(title: string, text: string, confirmButtonText = 'Conferma', icon?: 'error' | 'warning'): Observable<boolean> {
		const data: Omit<InteractionData, 'discardButtonText'> = { title, text, confirmButtonText, icon };
		return this.dialog
			.open(InteractionComponent, {
				data,
				disableClose: true,
				autoFocus: false
			})
			.afterClosed()
			.pipe(map(value => value === true));
	}

	public simpleInputText(
		text: string,
		inputIsRequired = false,
		lengths?: {
			maxLength: number;
			minLength: number;
		}
	): Observable<string> {
		const confirmButtonText = 'PDM.GENERICS.BUTTON.CONFIRM';
		const discardButtonText = 'PDM.GENERICS.BUTTON.DISCARD';
		return this.dialog
			.open(SimpleTextInputComponent, {
				data: { text, confirmButtonText, discardButtonText, inputIsRequired, lengths },
				disableClose: true,
				autoFocus: false,
				width: '40%'
			})
			.afterClosed()
			.pipe(map(value => value));
	}

	public confirmValidation(error: ServerError, context: string): Observable<boolean> {
		const data = { error, context };
		return this.dialog
			.open(ErrorValidationComponent, {
				data,
				disableClose: true,
				autoFocus: false,
				width: '40%'
			})
			.afterClosed()
			.pipe(map(value => value === true));
	}

	public confirmValidationV2(title: string, validationMessages: string[]): Observable<boolean> {
		const data = { title, validationMessages };
		return this.dialog
			.open(ErrorValidationV2Component, {
				data,
				disableClose: true,
				autoFocus: false,
				width: '40%'
			})
			.afterClosed()
			.pipe(map(value => value === true));
	}
}
