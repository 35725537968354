import { createAction, props } from '@ngrx/store';
import { ArticleResponse } from '../../models/article.model';
import { Pagination } from '@core/models/miscellaneous/server-response.model';

export const initArticlesV2List = createAction('[Articles V2] Init List', props<{ params: Record<string, string | boolean> }>());
export const loadArticlesV2List = createAction('[Articles V2] Load List', props<{ params: Record<string, string | boolean> }>());
export const loadArticlesV2ListSuccess = createAction(
	'[Articles V2] Load success',
	props<{
		articles: ArticleResponse[];
		pagination: Pagination;
	}>()
);
export const loadArticlesV2ListFailed = createAction('[Articles V2] Load failed');
