import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType, ROOT_EFFECTS_INIT } from '@ngrx/effects';
import { AuthService } from '../services/auth.service';
import { exhaustMap, filter, mapTo, of, switchMapTo, tap } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import * as AuthActions from './auth.actions';
import * as ProfileActions from '../../profile/store/profile.actions';
import { LocalStorageService } from '@core/services/storage/local-storage.service';
import { EStorageKey } from '@core/services/storage/storage-key.enum';
import { Router } from '@angular/router';

@Injectable()
export class AuthEffect {
	init$ = createEffect(() =>
		this.actions$.pipe(
			ofType(ROOT_EFFECTS_INIT),
			mapTo(this.localStorageService.getItem(EStorageKey.LOGGED_IN)),
			filter(Boolean),
			switchMapTo([ProfileActions.loadMe()])
		)
	);

	login$ = createEffect(() =>
		this.actions$.pipe(
			ofType(AuthActions.login),
			exhaustMap(({ email, password, deleteOldest }) =>
				this.authService.login(email, password, deleteOldest).pipe(
					map(() => AuthActions.loginSuccess()),
					catchError(() => of(AuthActions.loginFailed()))
				)
			)
		)
	);

	loginSuccess$ = createEffect(() =>
		this.actions$.pipe(
			ofType(AuthActions.loginSuccess),
			tap(() => {
				this.localStorageService.setItem(EStorageKey.LOGGED_IN, true);
				this.router.navigate(['']);
			}),
			switchMapTo([ProfileActions.loadMe()])
		)
	);

	logout$ = createEffect(() =>
		this.actions$.pipe(
			ofType(AuthActions.logout),
			exhaustMap(() =>
				this.authService.logout().pipe(
					switchMapTo([AuthActions.logoutSuccess()]),
					catchError(() => of(AuthActions.logoutFailed()))
				)
			)
		)
	);

	logoutSuccess$ = createEffect(
		() =>
			this.actions$.pipe(
				ofType(AuthActions.logoutSuccess),
				tap(() => {
					this.localStorageService.setItem(EStorageKey.LOGGED_IN, false);
					this.router.navigate(['login']);
				})
			),
		{ dispatch: false }
	);

	constructor(
		private readonly router: Router,
		private readonly actions$: Actions,
		private readonly authService: AuthService,
		private readonly localStorageService: LocalStorageService
	) {}
}
