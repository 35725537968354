import { createReducer, on } from '@ngrx/store';
import { ArticleResponse } from '../models/article.model';
import * as ArticlesV2ListActions from './list/articles-v2-list.actions';
import * as ArticlesV2DetailActions from './detail/articles-v2-detail.actions';
import * as ArticlesV2DeleteActions from './delete/articles-V2-delete.actions';
import * as ArticlesV2CancelActions from './cancel/articles-V2-cancel.actions';
import * as ArticlesV2PatchActions from './patch/articles-V2-patch.actions';
import * as ArticlesV2ApproveActions from './approve/articles-V2-approve.actions';
import * as ArticlesV2RejectActions from './reject/articles-V2-reject.actions';
import * as ArticlesV2PublishActions from './publish/articles-V2-publish.actions';
import * as ArticlesV2ArchiveActions from './archive/articles-V2-archive.actions';
import * as ArticlesV2CreateActions from './create/articles-V2-create.actions';
import * as FileActions from '@core/store/actions/file.actions';
import * as InternalNoteActions from '@core/store/actions/internal-note-actions';
import { Pagination } from '@core/models/miscellaneous/server-response.model';
import { ArticleV2Detail } from '../models/article-v2-detail.model';
import { PDMFile } from '@core/models/pdm-file.model';
import { InternalNote } from '@core/models/notes/internal-note.model';

export const articlesV2FeatureKey = 'articlesV2';

export interface ArticlesV2State {
	articles: ArticleResponse[];
	article: ArticleV2Detail | undefined;
	pagination: Pagination | undefined;
	files: PDMFile[];
	notes: InternalNote[];
	patchSuccess: boolean;
	createSuccess: boolean;
}

export const initialState: ArticlesV2State = {
	articles: [],
	article: undefined,
	pagination: undefined,
	files: [],
	notes: [],
	patchSuccess: false,
	createSuccess: false
};

export const articlesV2Reducer = createReducer(
	initialState,
	on(ArticlesV2ListActions.loadArticlesV2ListSuccess, (state, action) => ({
		...state,
		articles: action.articles,
		pagination: action.pagination
	})),
	on(ArticlesV2DetailActions.loadArticleV2DetailSuccess, (state, action) => ({
		...state,
		article: action.article,
		files: action.article.attachments
	})),
	on(ArticlesV2DetailActions.clearArticleV2Detail, state => ({
		...state,
		article: undefined,
		files: []
	})),
	on(ArticlesV2DeleteActions.deleteArticleV2Success, (state, action) => ({
		...state,
		articles: [...state.articles.filter(article => article.id !== action.id)],
		files: []
	})),
	on(ArticlesV2CancelActions.cancelArticleSuccess, (state, action) => ({
		...state,
		article: action.article,
		files: []
	})),
	on(FileActions.uploadArticleFileSuccess, (state, action) => ({
		...state,
		files: [action.file, ...state.files]
	})),
	on(FileActions.cleanArticleFiles, state => ({
		...state,
		files: []
	})),
	on(FileActions.deleteArticleFileSuccess, (state, action) => ({
		...state,
		files: [...state.files.filter(file => file.id !== action.id)]
	})),
	on(InternalNoteActions.loadSuccess, (state, action) => ({
		...state,
		notes: action.notes
	})),
	on(ArticlesV2PatchActions.patchArticleSuccess, (state, action) => ({
		...state,
		article: action.article,
		files: action.article.attachments,
		patchSuccess: true
	})),
	on(ArticlesV2ApproveActions.approveArticleSuccess, (state, action) => ({
		...state,
		article: action.article,
		files: []
	})),
	on(ArticlesV2RejectActions.rejectArticleSuccess, (state, action) => ({
		...state,
		article: action.article,
		files: []
	})),
	on(ArticlesV2PublishActions.publishArticleSuccess, (state, action) => ({
		...state,
		article: action.article,
		files: []
	})),
	on(ArticlesV2ArchiveActions.archiveArticleSuccess, (state, action) => ({
		...state,
		article: action.article,
		files: []
	})),
	on(ArticlesV2CreateActions.createArticleSuccess, (state, action) => ({
		...state,
		article: action.article,
		createSuccess: true
	}))
);
