import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import * as ArticleCreateActions from './articles-V2-create.actions';
import { of, switchMap, tap } from 'rxjs';
import * as LoadingActions from '../../../../core/store/actions/loading.actions';
import { catchError, map } from 'rxjs/operators';
import { NotificationService } from '@core/services/notification.service';
import { Router } from '@angular/router';
import { InteractionService } from '../../../../shared/interaction/interaction.service';
import { TranslocoService } from '@ngneat/transloco';
import { ArticlesV2Service } from '../../services/articles-v2.service';
import { EArticleErrorCodes } from '../../models/enum/article-error-code.enum';
import { showDefaultError } from '@core/utils/miscellaneous.utils';

@Injectable()
export class ArticlesV2CreateEffects {
	init$ = createEffect(() =>
		this.actions$.pipe(
			ofType(ArticleCreateActions.initCreateArticle),
			switchMap(({ article }) => [LoadingActions.startLoading(), ArticleCreateActions.createArticle({ article })])
		)
	);

	create$ = createEffect(() =>
		this.actions$.pipe(
			ofType(ArticleCreateActions.createArticle),
			switchMap(({ article }) =>
				this.articlesService.createArticle(article).pipe(
					map(result => ArticleCreateActions.createArticleSuccess({ article: result })),
					catchError(error => of(ArticleCreateActions.createArticleFailed({ error })))
				)
			)
		)
	);

	createFailed$ = createEffect(() =>
		this.actions$.pipe(
			ofType(ArticleCreateActions.createArticleFailed),
			switchMap(({ error }) => {
				if (error.internalCode in EArticleErrorCodes) {
					this.interactionService.confirm(
						`articles.detail.errors.${error.internalCode}.title`,
						this.translateService.translate(`articles.detail.errors.${error.internalCode}.message`),
						'PDM.GENERICS.BUTTON.OK',
						'error'
					);
				} else {
					showDefaultError(error, this.notificationService, this.interactionService);
				}

				return [LoadingActions.stopLoading()];
			})
		)
	);

	createSuccess$ = createEffect(
		() =>
			this.actions$.pipe(
				ofType(ArticleCreateActions.createArticleSuccess),
				tap(({ article }) => {
					this.notificationService.success('articles.detail.success.create');
					this.router.navigate([`articoli/${article.id}/modifica`]);
				})
			),
		{ dispatch: false }
	);

	stopLoading$ = createEffect(() =>
		this.actions$.pipe(
			ofType(ArticleCreateActions.createArticleSuccess, ArticleCreateActions.createArticleFailed),
			switchMap(() => [LoadingActions.stopLoading()])
		)
	);

	constructor(
		private readonly router: Router,
		private readonly actions$: Actions,
		private readonly articlesService: ArticlesV2Service,
		private readonly translateService: TranslocoService,
		private readonly interactionService: InteractionService,
		private readonly notificationService: NotificationService
	) {}
}
