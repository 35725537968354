import { inject, Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { PaginatedServerResponse } from '@core/models/miscellaneous/server-response.model';
import { EResourcePath, resourcePathLocator } from '../../../resource-path.locator';
import { catchError, map } from 'rxjs/operators';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { ActivityV2 } from '../models/activity-V2.model';
import { DateTime } from 'luxon';
import { ActivityV2Detail, ServerActivityV2Detail } from '../models/activity-V2-detail.model';
import { EActivityV2Type } from '../models/enum/activities-V2-type.enum';
import { EActivityV2Status } from '../models/enum/activities-V2-status.enum';
import { ServerError } from '@core/models/server-error.model';
import { ActivityV2Create } from '../models/activity-V2-create.form';

@Injectable()
export class ActivitiesV2Service {
	private readonly http = inject(HttpClient);

	public listActivities(params: Record<string, string | boolean>): Observable<PaginatedServerResponse<ActivityV2[]>> {
		return this.http
			.get<PaginatedServerResponse<ActivityV2[]>>(`${resourcePathLocator[EResourcePath.ACTIVITIES]}`, { params })
			.pipe(map(response => response));
	}

	public getActivityById(id: string): Observable<ActivityV2Detail> {
		return this.http.get<ServerActivityV2Detail>(`${resourcePathLocator[EResourcePath.ACTIVITIES]}/${id}`).pipe(map(this.mapData()));
	}

	public delete(id: string): Observable<void> {
		return this.http.delete(`${resourcePathLocator[EResourcePath.ACTIVITIES]}/${id}`).pipe(
			map(() => void 0),
			catchError(err => this.catchError(err, [404, 409]))
		);
	}

	public cancel(id: string, cancellationReason: string): Observable<ActivityV2Detail> {
		return this.http
			.post<ServerActivityV2Detail>(`${resourcePathLocator[EResourcePath.ACTIVITIES]}/${id}/remove`, { cancellationReason })
			.pipe(
				map(this.mapData()),
				catchError(err => this.catchError(err, [404, 409]))
			);
	}

	public submitToReview(id: string, message?: string): Observable<ActivityV2Detail> {
		return this.http
			.post<ServerActivityV2Detail>(`${resourcePathLocator[EResourcePath.ACTIVITIES]}/${id}/review`, { note: message ?? undefined })
			.pipe(
				map(this.mapData()),
				catchError(err => this.catchError(err, [404, 409]))
			);
	}

	public patch(id: string, article: Partial<ActivityV2Create>): Observable<ActivityV2Detail> {
		return this.http.patch<ServerActivityV2Detail>(`${resourcePathLocator[EResourcePath.ACTIVITIES]}/${id}`, article).pipe(
			map(this.mapData()),
			catchError(err => this.catchError(err, [400, 404, 409]))
		);
	}

	public approve(id: string, publicationDateTime: string, message?: string): Observable<ActivityV2Detail> {
		return this.http
			.post<ServerActivityV2Detail>(`${resourcePathLocator[EResourcePath.ACTIVITIES]}/${id}/approve`, {
				note: message ?? undefined,
				publicationDate: publicationDateTime
			})
			.pipe(
				map(this.mapData()),
				catchError(err => this.catchError(err, [400, 404, 409]))
			);
	}

	public reject(id: string, message?: string): Observable<ActivityV2Detail> {
		return this.http
			.post<ServerActivityV2Detail>(`${resourcePathLocator[EResourcePath.ACTIVITIES]}/${id}/rework`, { note: message ?? undefined })
			.pipe(
				map(this.mapData()),
				catchError(err => this.catchError(err, [404, 409]))
			);
	}

	public archive(id: string, message?: string): Observable<ActivityV2Detail> {
		return this.http
			.post<ServerActivityV2Detail>(`${resourcePathLocator[EResourcePath.ACTIVITIES]}/${id}/archive`, { note: message ?? undefined })
			.pipe(
				map(this.mapData()),
				catchError(err => this.catchError(err, [404, 409]))
			);
	}

	public publish(id: string, message?: string): Observable<ActivityV2Detail> {
		return this.http
			.post<ServerActivityV2Detail>(`${resourcePathLocator[EResourcePath.ACTIVITIES]}/${id}/publish`, { note: message ?? undefined })
			.pipe(
				map(this.mapData()),
				catchError(err => this.catchError(err, [404, 409]))
			);
	}

	public create(activity: Partial<ActivityV2Create>): Observable<ActivityV2Detail> {
		const activityToSave = Object.fromEntries(
			Object.entries(activity).filter(([_, value]) => !!value && (value as Array<unknown>).length > 0)
		);

		return this.http.post<ServerActivityV2Detail>(resourcePathLocator[EResourcePath.ACTIVITIES], activityToSave).pipe(
			map(this.mapData()),
			catchError(err => this.catchError(err, [400, 404, 409]))
		);
	}

	private catchError(err: unknown, httpCodes: number[]) {
		const { error } = err as HttpErrorResponse;
		return httpCodes.includes((error as ServerError).statusCode) ? throwError(error as ServerError) : throwError(err);
	}

	private mapData() {
		return (response: ServerActivityV2Detail) => ({
			...response,
			createdAt: DateTime.fromISO(response.createdAt),
			updatedAt: DateTime.fromISO(response.updatedAt),
			dataPubblicazioneInGazzetta: response.dataPubblicazioneInGazzetta
				? DateTime.fromISO(response.dataPubblicazioneInGazzetta)
				: null,
			publishedAt: DateTime.fromISO(response.publishedAt),
			type: response.type as EActivityV2Type,
			status: response.status as EActivityV2Status
		});
	}
}
