import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class AuthService {
	private readonly BASE_URL = 'v1/auth';

	constructor(private readonly http: HttpClient) {}

	public login(email: string, password: string, deleteOldest: boolean): Observable<void> {
		return this.http
			.post(`${this.BASE_URL}/login`, {
				email,
				password,
				deleteOldest
			})
			.pipe(map(() => void 0));
	}

	public logout() {
		return this.http.post(`${this.BASE_URL}/logout`, null);
	}
}
