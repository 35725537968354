export enum EServerInternalErrorCodes {
	INVALID_CREDENTIALS = '0001',
	USER_BLOCKED = '0002',
	MAX_SESSIONS = '0003',
	COOKIE_NOT_FOUND = '0004',
	MALFORMED_COOKIE = '0005',
	SESSION_NOT_FOUND = '0006',
	SESSION_NOT_VALID = '0007',
	USER_NOT_FOUND = '0008',
	USER_DELETED = '0009',
	MISSING_PERMISSION = '0010',
	SESSION_EXPIRED = '0011',
	RESET_LINK_NOT_FOUND = '0012',
	RESET_LINK_ALREADY_USED = '0013',
	RESET_LINK_EXPIRED = '0014'
}
