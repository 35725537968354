import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { PasswordUpdate } from '@uikit/components/change-password/models/password-update.model';
import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { AuthenticatedUser } from '../models/authenticated-user.model';

@Injectable()
export class ProfileService {
	private readonly BASE_URL = 'v1/users';

	constructor(private http: HttpClient) {}

	public getMe(): Observable<AuthenticatedUser> {
		return this.http.get<AuthenticatedUser>(`${this.BASE_URL}/whoami`);
	}

	public changePassword(id: number, passwordUpdate: PasswordUpdate): Observable<void> {
		return this.http.post(`${this.BASE_URL}/${id}/change-password`, passwordUpdate).pipe(map(() => void 0));
	}
}
