import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import * as LegislaturesActions from './legislatures.actions';
import { of, switchMap } from 'rxjs';
import * as LoadingActions from '../../../core/store/actions/loading.actions';
import { catchError, map } from 'rxjs/operators';
import { LegislaturesService } from '../services/legislatures.service';
import { ServerError } from '@core/models/server-error.model';
import { environment } from '../../../../environments/environment';
import { NotificationService } from '@core/services/notification.service';
import { InteractionService } from '../../../shared/interaction/interaction.service';

@Injectable()
export class LegislaturesEffects {
	init$ = createEffect(() =>
		this.actions$.pipe(
			ofType(LegislaturesActions.initLegislatures),
			switchMap(({ params }) => [LoadingActions.startLoading(), LegislaturesActions.loadLegislatures({ params })])
		)
	);

	load$ = createEffect(() =>
		this.actions$.pipe(
			ofType(LegislaturesActions.loadLegislatures),
			switchMap(({ params }) =>
				this.ministryService.getMinistries(params).pipe(
					map(response =>
						LegislaturesActions.loadLegislaturesSuccess({
							legislatures: response.results,
							pagination: response.paginatedInfo
						})
					),
					catchError(error => of(LegislaturesActions.loadLegislaturesFailed({ error })))
				)
			)
		)
	);

	loadFailed$ = createEffect(() =>
		this.actions$.pipe(
			ofType(LegislaturesActions.loadLegislaturesFailed),
			switchMap(({ error }) => {
				this.showDefaultError(error);
				return [LoadingActions.stopLoading()];
			})
		)
	);

	stopLoading$ = createEffect(() =>
		this.actions$.pipe(
			ofType(LegislaturesActions.loadLegislaturesSuccess, LegislaturesActions.loadLegislaturesFailed),
			switchMap(() => [LoadingActions.stopLoading()])
		)
	);

	private showDefaultError(error: ServerError) {
		if (environment.production) {
			this.notificationService.error().afterDismissed();
		} else {
			this.interactionService.confirm('Generic Error', JSON.stringify(error), 'PDM.GENERICS.BUTTON.OK', 'error');
		}
	}

	constructor(
		private readonly actions$: Actions,
		private readonly ministryService: LegislaturesService,
		private readonly interactionService: InteractionService,
		private readonly notificationService: NotificationService
	) {}
}
