export enum EActivityV2Status {
	DRAFT = 'DRAFT',
	IN_REVIEW = 'IN_REVIEW',
	READY = 'READY',
	PUBLISHED = 'PUBLISHED',
	REWORK = 'REWORK',
	ARCHIVED = 'ARCHIVED',
	DELETED = 'DELETED',
	NEW = 'NEW'
}

export namespace EActivityV2Status {
	export function values(): EActivityV2Status[] {
		return [
			EActivityV2Status.DRAFT,
			EActivityV2Status.IN_REVIEW,
			EActivityV2Status.READY,
			EActivityV2Status.PUBLISHED,
			EActivityV2Status.REWORK,
			EActivityV2Status.ARCHIVED,
			EActivityV2Status.DELETED,
			EActivityV2Status.NEW
		];
	}
}
