import { createReducer, on } from '@ngrx/store';
import { Session } from '../models/session.model';
import * as SessionsActions from './sessions.actions';
import * as SessionDeleteActions from './delete/session-delete.actions';

export interface SessionsState {
	sessions: Session[];
}

export const initialState: SessionsState = {
	sessions: []
};

export const sessionsReducer = createReducer(
	initialState,
	on(SessionsActions.loadSessionsSuccess, (_, action) => ({
		error: false,
		sessions: action.sessions
	})),
	on(SessionDeleteActions.deleteSessionsSuccess, (state, action) => {
		const actualSession = state.sessions.find(session => session.isCurrent)!;
		const otherSessions = state.sessions.filter(session => !session.isCurrent);
		const actualSessions = otherSessions.filter(
			session => !action.sessionIds.some(deletedSessionId => deletedSessionId === session.id)
		);
		return {
			sessions: [actualSession, ...actualSessions]
		};
	}),
	on(SessionDeleteActions.deleteSessionsError, state => ({
		sessions: state.sessions
	}))
);
