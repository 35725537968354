import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ServerError } from '@core/models/server-error.model';

import { MatIconModule } from '@angular/material/icon';
import { MAT_DIALOG_DATA, MatDialogModule } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { TranslocoPipe } from '@ngneat/transloco';

@Component({
	selector: 'pdm-error-validation',
	standalone: true,
	imports: [CommonModule, MatDialogModule, MatButtonModule, MatIconModule, TranslocoPipe],
	template: `
		<h1 mat-dialog-title>
			<div class="flex flex-row items-center">
				<div class="flex mr-2">
					<mat-icon class="text-red-500">error</mat-icon>
				</div>
				<span>{{ title | transloco }}</span>
			</div>
		</h1>
		<mat-dialog-content>
			<ul>
				<li *ngFor="let validation of data.error.details.validation; trackBy: trackByFn" class="list-disc ml-6 p-2 text-base">
					{{ 'PDM.' + data.context + '.VALIDATION.' + data.error.internalCode + '.' + validation.internalCode | transloco }}
				</li>
			</ul>
		</mat-dialog-content>
		<mat-dialog-actions [attr.align]="'end'">
			<button mat-button mat-raised-button color="primary" [mat-dialog-close]="true">
				{{ 'PDM.GENERICS.BUTTON.OK' | transloco }}
			</button>
		</mat-dialog-actions>
	`,
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class ErrorValidationComponent {
	public readonly title: string = '';

	constructor(@Inject(MAT_DIALOG_DATA) public data: { error: ServerError; context: string }) {
		this.title = `PDM.${data.context}.VALIDATION.${data.error.internalCode}.TITLE`;
	}

	public trackByFn(index: number) {
		return index;
	}
}
