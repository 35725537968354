import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ServerResponse } from '@core/models/miscellaneous/server-response.model';
import { map } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { Category } from '@core/models/category.model';
import { EFeatureType } from '@core/models/enums/feature-type.enum';

@Injectable({
	providedIn: 'root'
})
export class CategoriesService {
	private static readonly BASE_URL = 'v2/categories';

	constructor(private readonly http: HttpClient) {}

	public getCategories(type: EFeatureType): Observable<Category[]> {
		return this.http
			.get<ServerResponse<Category[]>>(`${CategoriesService.BASE_URL}?type=${type}`)
			.pipe(map((response: ServerResponse<Category[]>) => response.results as Category[]));
	}
}
