import { Injectable } from '@angular/core';
import { EMPTY, filter, switchMap, switchMapTo, tap } from 'rxjs';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { InteractionService } from '../../../shared/interaction/interaction.service';
import { Router } from '@angular/router';
import { EStorageKey } from '@core/services/storage/storage-key.enum';
import { LocalStorageService } from '@core/services/storage/local-storage.service';
import { map } from 'rxjs/operators';
import * as AuthActions from '../../auth/store/auth.actions';
import * as LoadingActions from '../actions/loading.actions';
import { HttpErrorActions } from '../actions';
import { NotificationService } from '@core/services/notification.service';
import { EServerInternalErrorCodes } from '@core/models/enums/server-internal-error-codes.enum';
import { HttpStatusCode } from '@angular/common/http';
import { Location } from '@angular/common';

@Injectable()
export class HttpErrorEffects {
	httpError$ = createEffect(() =>
		this.actions$.pipe(
			ofType(HttpErrorActions.httpError),
			switchMap(({ code, internalCode, url }) => {
				if (code === HttpStatusCode.Unauthorized) {
					switch (internalCode) {
						case EServerInternalErrorCodes.INVALID_CREDENTIALS:
							return [AuthActions.invalidCredentials()];
						case EServerInternalErrorCodes.SESSION_NOT_FOUND:
							return this.interactionService
								.confirm('Attenzione', 'Sessione utente non trovata. Effettua nuovamente il login', 'Ok')
								.pipe(
									filter(Boolean),
									map(() => this.localStorageService.setItem(EStorageKey.LOGGED_IN, false)),
									switchMapTo([HttpErrorActions.httpErrorUnauthorized()])
								);
						case EServerInternalErrorCodes.SESSION_EXPIRED:
							return this.interactionService
								.confirm('Attenzione', 'La tua sessione utente è scaduta. Effettua nuovamente il login', 'Ok')
								.pipe(
									filter(Boolean),
									map(() => this.localStorageService.setItem(EStorageKey.LOGGED_IN, false)),
									switchMapTo([HttpErrorActions.httpErrorUnauthorized()])
								);
						default:
							this.notificationService.error(
								`Si è verificato un errore nella richiesta ${url} (err: ${internalCode})\n. Contattare l'amministratore di sistema.`,
								10000
							);
							return EMPTY;
					}
				}

				if (code === HttpStatusCode.Forbidden) {
					switch (internalCode) {
						case EServerInternalErrorCodes.USER_BLOCKED:
							return [AuthActions.blockedAccount(), HttpErrorActions.httpErrorForbidden()];
						case EServerInternalErrorCodes.MAX_SESSIONS:
							return [AuthActions.maxSessions(), HttpErrorActions.httpErrorForbidden()];
						case EServerInternalErrorCodes.RESET_LINK_NOT_FOUND:
							return this.interactionService
								.confirm(
									'PDM.RESET_LINK_ERRORS.TITLE',
									'PDM.RESET_LINK_ERRORS.RESET_LINK_NOT_FOUND',
									'PDM.RESET_LINK_ERRORS.CONFIRM'
								)
								.pipe(filter(Boolean), switchMapTo([HttpErrorActions.httpErrorForbiddenWithRedirectToLogin()]));
						case EServerInternalErrorCodes.RESET_LINK_ALREADY_USED:
							return this.interactionService
								.confirm(
									'PDM.RESET_LINK_ERRORS.TITLE',
									'PDM.RESET_LINK_ERRORS.RESET_LINK_ALREADY_USED',
									'PDM.RESET_LINK_ERRORS.CONFIRM'
								)
								.pipe(filter(Boolean), switchMapTo([HttpErrorActions.httpErrorForbiddenWithRedirectToLogin()]));
						case EServerInternalErrorCodes.RESET_LINK_EXPIRED:
							return this.interactionService
								.confirm(
									'PDM.RESET_LINK_ERRORS.TITLE',
									'PDM.RESET_LINK_ERRORS.RESET_LINK_EXPIRED',
									'PDM.RESET_LINK_ERRORS.CONFIRM'
								)
								.pipe(filter(Boolean), switchMapTo([HttpErrorActions.httpErrorForbiddenWithRedirectToLogin()]));
						default:
							return this.interactionService
								.confirm('PDM.GENERICS.ERROR.403.TITLE', 'PDM.GENERICS.ERROR.403.TEXT', 'PDM.GENERICS.BUTTON.OK')
								.pipe(
									switchMapTo([LoadingActions.stopLoading()]),
									filter(Boolean),
									tap(() => this.location.back())
								);
					}
				}

				if (code === HttpStatusCode.InternalServerError) {
					this.notificationService.error(
						`Si è verificato un errore nella richiesta ${url}\n. Contattare l'amministratore di sistema.`,
						10000
					);
				}

				return EMPTY;
			})
		)
	);

	loginRedirect$ = createEffect(
		() =>
			this.actions$.pipe(
				ofType(HttpErrorActions.httpErrorUnauthorized, HttpErrorActions.httpErrorForbiddenWithRedirectToLogin),
				tap(() => this.router.navigate(['login']))
			),
		{ dispatch: false }
	);

	constructor(
		private readonly router: Router,
		private readonly location: Location,
		private readonly actions$: Actions,
		private readonly interactionService: InteractionService,
		private readonly notificationService: NotificationService,
		private readonly localStorageService: LocalStorageService
	) {}
}
