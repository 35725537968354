<h3 mat-dialog-title [innerHTML]="data.text | transloco"></h3>
<mat-dialog-content>
	<mat-form-field class="w-full" appearance="outline">
		<textarea matTextareaAutosize matInput [placeholder]="'PDM.GENERICS.TEXT_PLACEHOLDER'| transloco"
				  [formControl]="title"></textarea>
		<mat-hint *ngIf="data.lengths" [pdmMaxAllowedCharacters]="data.lengths.maxLength" align="end"></mat-hint>
		<mat-error *pdmHasError="'required'">{{ 'PDM.GENERICS.ERROR.REQUIRED'| transloco }}</mat-error>
		<mat-error *pdmHasError="'maxlength'; let errorLength = error">
			{{ 'PDM.GENERICS.ERROR.MAX_LENGTH'| transloco: { maxLength: errorLength.requiredLength } }}
		</mat-error>
		<mat-error *pdmHasError="'minlength'; let errorLength = error">
			{{ 'PDM.GENERICS.ERROR.MIN_LENGTH'| transloco: { minLength: errorLength.requiredLength } }}
		</mat-error>
	</mat-form-field>
</mat-dialog-content>
<mat-dialog-actions [attr.align]="'end'">
	<button *ngIf="data.discardButtonText" mat-stroked-button color="accent" (click)="cancel()">
		{{ data.discardButtonText | transloco }}
	</button>
	<button mat-raised-button color="primary" (click)="save()">
		{{ data.confirmButtonText | transloco }}
	</button>
</mat-dialog-actions>
