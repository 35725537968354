import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import * as ProfileActions from './profile.actions';
import { catchError, exhaustMap, map } from 'rxjs/operators';
import { of, tap, withLatestFrom } from 'rxjs';
import { ProfileService } from '../services/profile.service';
import { EStorageKey } from '@core/services/storage/storage-key.enum';
import { LocalStorageService } from '@core/services/storage/local-storage.service';
import { getMe } from './profile.selector';
import { AppState } from '../../core.module';
import { Store } from '@ngrx/store';

@Injectable()
export class ProfileEffect {
	me$ = createEffect(() =>
		this.actions$.pipe(
			ofType(ProfileActions.loadMe),
			withLatestFrom(this.store$.select(getMe)),
			exhaustMap(([_, user]) => {
				if (user.id) {
					return [ProfileActions.loadMeSuccess({ user })];
				}

				return this.profileService.getMe().pipe(
					map(user => ProfileActions.loadMeSuccess({ user })),
					catchError(() => of(ProfileActions.loadMeFailed()))
				);
			})
		)
	);

	loadMeSuccess$ = createEffect(
		() =>
			this.actions$.pipe(
				ofType(ProfileActions.loadMeSuccess),
				tap(() => this.localStorageService.setItem(EStorageKey.LOGGED_IN, true))
			),
		{ dispatch: false }
	);

	loadMeFailed$ = createEffect(
		() =>
			this.actions$.pipe(
				ofType(ProfileActions.loadMeFailed),
				tap(() => this.localStorageService.setItem(EStorageKey.LOGGED_IN, false))
			),
		{ dispatch: false }
	);

	constructor(
		private readonly actions$: Actions,
		private readonly store$: Store<AppState>,
		private readonly profileService: ProfileService,
		private readonly localStorageService: LocalStorageService
	) {}
}
