import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { MiscellaneousService } from '@core/services/miscellaneous.service';
import { of, switchMap, withLatestFrom } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import * as LoadingActions from '../actions/loading.actions';
import * as RegionsActions from '../actions/regions.actions';
import { AppState } from '../../core.module';
import { Store } from '@ngrx/store';
import { getRegions } from '../selectors/regions.selector';

@Injectable()
export class RegionsEffects {
	initLoadRegions$ = createEffect(() =>
		this.actions$.pipe(
			ofType(RegionsActions.initLoadAllRegions),
			switchMap(() => [LoadingActions.startLoading(), RegionsActions.loadAllRegions()])
		)
	);

	loadRegions$ = createEffect(() =>
		this.actions$.pipe(
			ofType(RegionsActions.loadAllRegions),
			withLatestFrom(this.store$.select(getRegions)),
			switchMap(([_, regions]) => {
				if (regions.length > 0) {
					return [RegionsActions.loadAllRegionsSuccess({ regions })];
				}

				return this.miscellaneousService.getRegions().pipe(
					map(regions => RegionsActions.loadAllRegionsSuccess({ regions })),
					catchError(() => of(RegionsActions.loadAllRegionsFailed()))
				);
			})
		)
	);

	loadRegionsSuccess$ = createEffect(() =>
		this.actions$.pipe(
			ofType(RegionsActions.loadAllRegionsSuccess),
			switchMap(() => [LoadingActions.stopLoading()])
		)
	);

	constructor(
		private readonly actions$: Actions,
		private readonly store$: Store<AppState>,
		private readonly miscellaneousService: MiscellaneousService
	) {}
}
