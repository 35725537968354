import { createAction, props } from '@ngrx/store';
import { ServerError } from '@core/models/server-error.model';
import { ActivityV2Create } from '../../models/activity-V2-create.form';
import { ActivityV2Detail } from '../../models/activity-V2-detail.model';

export const initPatchActivityV2 = createAction('[Activities V2] Patch Init', props<{ id: string; activity: Partial<ActivityV2Create> }>());
export const initPatchActivityV2AndFireAskReview = createAction(
	'[Activities V2] Patch and fire Ask Review Init',
	props<{ id: string; activity: Partial<ActivityV2Create>; message?: string }>()
);
export const patchActivityV2 = createAction('[Activities V2] Patch', props<{ id: string; activity: Partial<ActivityV2Create> }>());
export const patchActivityV2AndFireAskReview = createAction(
	'[Activities V2] Patch and fire Ask Review',
	props<{ id: string; activity: Partial<ActivityV2Create>; message?: string }>()
);
export const patchActivityV2Success = createAction('[Activities] Patch success', props<{ activity: ActivityV2Detail }>());
export const patchActivityV2Failed = createAction('[Activities] Patch failed', props<{ error: ServerError }>());
