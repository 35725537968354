import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import * as GroupPatchActions from './groups-patch.actions';
import { of, switchMap, tap } from 'rxjs';
import * as LoadingActions from '../../../../core/store/actions/loading.actions';
import { catchError, map } from 'rxjs/operators';
import { NotificationService } from '@core/services/notification.service';
import { InteractionService } from '../../../../shared/interaction/interaction.service';
import { ServerError } from '@core/models/server-error.model';
import { environment } from '../../../../../environments/environment';
import { GroupsService } from '@groups/services/groups.service';

@Injectable()
export class GroupsPatchEffects {
	init$ = createEffect(() =>
		this.actions$.pipe(
			ofType(GroupPatchActions.initPatchGroup),
			switchMap(({ id, group }) => [
				LoadingActions.startLoading(),
				GroupPatchActions.patchGroup({
					id,
					group
				})
			])
		)
	);

	patch$ = createEffect(() =>
		this.actions$.pipe(
			ofType(GroupPatchActions.patchGroup),
			switchMap(({ id, group }) =>
				this.groupsService.patchGroup(id, group).pipe(
					map(result => GroupPatchActions.patchGroupSuccess({ group: result })),
					catchError(error => of(GroupPatchActions.patchGroupFailed({ error })))
				)
			)
		)
	);

	patchSuccess$ = createEffect(
		() =>
			this.actions$.pipe(
				ofType(GroupPatchActions.patchGroupSuccess),
				tap(() => {
					this.notificationService.success('groups.PATCH_SUCCESS');
				})
			),
		{ dispatch: false }
	);

	patchFailed$ = createEffect(() =>
		this.actions$.pipe(
			ofType(GroupPatchActions.patchGroupFailed),
			switchMap(({ error }) => {
				this.showDefaultError(error);
				return [LoadingActions.stopLoading()];
			})
		)
	);

	private showDefaultError(error: ServerError) {
		if (environment.production) {
			this.notificationService.error().afterDismissed();
		} else {
			this.interactionService.confirm('Generic Error', JSON.stringify(error), 'PDM.GENERICS.BUTTON.OK', 'error');
		}
	}

	stopLoading$ = createEffect(() =>
		this.actions$.pipe(
			ofType(GroupPatchActions.patchGroupSuccess, GroupPatchActions.patchGroupFailed),
			switchMap(() => [LoadingActions.stopLoading()])
		)
	);

	constructor(
		private readonly actions$: Actions,
		private readonly groupsService: GroupsService,
		private readonly interactionService: InteractionService,
		private readonly notificationService: NotificationService
	) {}
}
