import { PermissionsService } from '../../services/permissions.service';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import * as PermissionsActions from '../actions/permissions.actions';
import * as LoadingActions from '../actions/loading.actions';
import { of, switchMap } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { Injectable } from '@angular/core';

@Injectable()
export class PermissionsEffect {
	init$ = createEffect(() =>
		this.actions$.pipe(
			ofType(PermissionsActions.initPermissions),
			switchMap(() => [LoadingActions.startLoading(), PermissionsActions.loadPermissions()])
		)
	);

	load$ = createEffect(() =>
		this.actions$.pipe(
			ofType(PermissionsActions.loadPermissions),
			switchMap(() =>
				this.permissionService.getPermissions().pipe(
					map(result => PermissionsActions.loadPermissionsSuccess({ permissions: result })),
					catchError(() => of(PermissionsActions.loadPermissionsFailed()))
				)
			)
		)
	);

	loadSuccess$ = createEffect(() =>
		this.actions$.pipe(
			ofType(PermissionsActions.loadPermissionsSuccess),
			map(() => LoadingActions.stopLoading())
		)
	);

	loadFailed$ = createEffect(() =>
		this.actions$.pipe(
			ofType(PermissionsActions.loadPermissionsFailed),
			map(() => LoadingActions.stopLoading())
		)
	);

	constructor(private readonly permissionService: PermissionsService, private readonly actions$: Actions) {}
}
