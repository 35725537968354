import { Injectable } from '@angular/core';
import { LoadingActions } from '@core/store/actions';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import * as RolesAndPermissionsV2ListActions from './roles-and-permissions-v2-list.actions';
import { of, switchMap, tap } from 'rxjs';
import { RolesServiceV2 } from '@core/services/rolesV2.service';
import { catchError, map } from 'rxjs/operators';
import { NotificationService } from '@core/services/notification.service';

@Injectable()
export class RolesAndPermissionsV2ListEffects {
	init$ = createEffect(() =>
		this.actions$.pipe(
			ofType(RolesAndPermissionsV2ListActions.initLoadRolesAndPermissionsV2),
			switchMap(() => [LoadingActions.startLoading(), RolesAndPermissionsV2ListActions.loadRolesAndPermissionsV2()])
		)
	);

	loadAll$ = createEffect(() =>
		this.actions$.pipe(
			ofType(RolesAndPermissionsV2ListActions.loadRolesAndPermissionsV2),
			switchMap(() =>
				this.rolesServiceV2.getRoles().pipe(
					map(roles => RolesAndPermissionsV2ListActions.loadRolesAndPermissionsV2Success({ roles })),
					catchError(() => of(RolesAndPermissionsV2ListActions.loadRolesAndPermissionsV2Failed()))
				)
			)
		)
	);

	loadAllFailed$ = createEffect(
		() =>
			this.actions$.pipe(
				ofType(RolesAndPermissionsV2ListActions.loadRolesAndPermissionsV2Failed),
				tap(() => this.notificationService.error('PDM.GENERICS.ERROR.LOAD_DATA_ERROR'))
			),
		{ dispatch: false }
	);

	stopLoading$ = createEffect(() =>
		this.actions$.pipe(
			ofType(
				RolesAndPermissionsV2ListActions.loadRolesAndPermissionsV2Success,
				RolesAndPermissionsV2ListActions.loadRolesAndPermissionsV2Failed
			),
			switchMap(() => [LoadingActions.stopLoading()])
		)
	);

	constructor(
		private readonly actions$: Actions,
		private readonly rolesServiceV2: RolesServiceV2,
		private readonly notificationService: NotificationService
	) {}
}
