import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import * as CollaboratorDangerActionActions from './collaborator-danger-action.actions';
import { of, switchMap, tap } from 'rxjs';
import * as LoadingActions from '@core/store/actions/loading.actions';
import { catchError } from 'rxjs/operators';
import { CollaboratorsV2Service } from '../../../services/collaborators-v2.service';
import { EDangerZoneActionType } from '../../../models/enums/danger-zone-action-type.enum';
import { NotificationService } from '@core/services/notification.service';
import { ServerError } from '@core/models/server-error.model';
import { Router } from '@angular/router';
import { environment } from '@environment/environment';
import { InteractionService } from '../../../../../../shared/interaction/interaction.service';

@Injectable()
export class CollaboratorDangerActionEffects {
	init$ = createEffect(() =>
		this.actions$.pipe(
			ofType(CollaboratorDangerActionActions.initCollaboratorDangerAction),
			switchMap(({ actionType, userId }) => [
				LoadingActions.startLoading(),
				CollaboratorDangerActionActions.doCollaboratorDangerAction({
					actionType,
					userId
				})
			])
		)
	);

	doAction$ = createEffect(() =>
		this.actions$.pipe(
			ofType(CollaboratorDangerActionActions.doCollaboratorDangerAction),
			switchMap(({ actionType, userId }) => {
				switch (actionType) {
					case EDangerZoneActionType.DELETE:
						return this.collaboratorsService.delete(userId).pipe(
							switchMap(() => [CollaboratorDangerActionActions.collaboratorDangerActionSuccess({ actionType, userId })]),
							catchError(error => of(CollaboratorDangerActionActions.collaboratorDangerActionFailed({ error })))
						);
					case EDangerZoneActionType.BLOCK:
						return this.collaboratorsService.block(userId).pipe(
							switchMap(() => [CollaboratorDangerActionActions.collaboratorDangerActionSuccess({ actionType, userId })]),
							catchError(error => of(CollaboratorDangerActionActions.collaboratorDangerActionFailed({ error })))
						);
					case EDangerZoneActionType.UNBLOCK:
						return this.collaboratorsService.unblock(userId).pipe(
							switchMap(() => [CollaboratorDangerActionActions.collaboratorDangerActionSuccess({ actionType, userId })]),
							catchError(error => of(CollaboratorDangerActionActions.collaboratorDangerActionFailed({ error })))
						);
				}
			})
		)
	);

	actionSuccess$ = createEffect(
		() =>
			this.actions$.pipe(
				ofType(CollaboratorDangerActionActions.collaboratorDangerActionSuccess),
				tap(({ actionType }) => {
					if (actionType === EDangerZoneActionType.DELETE) {
						this.router
							.navigate(['amministrazione/collaboratori'])
							.then(() =>
								this.notificationService.success(`collaborators.DETAIL.DANGER_ZONE.${actionType}.${actionType}_SUCCESS`)
							);
					} else {
						this.notificationService.success(`collaborators.DETAIL.DANGER_ZONE.${actionType}.${actionType}_SUCCESS`);
					}
				})
			),
		{ dispatch: false }
	);

	actionFailed$ = createEffect(() =>
		this.actions$.pipe(
			ofType(CollaboratorDangerActionActions.collaboratorDangerActionFailed),
			switchMap(({ error }) => {
				this.showDefaultError(error);
				return [LoadingActions.stopLoading()];
			})
		)
	);

	stopLoading$ = createEffect(() =>
		this.actions$.pipe(
			ofType(
				CollaboratorDangerActionActions.collaboratorDangerActionSuccess,
				CollaboratorDangerActionActions.collaboratorDangerActionFailed
			),
			switchMap(() => [LoadingActions.stopLoading()])
		)
	);

	private showDefaultError(error: ServerError) {
		if (environment.production) {
			this.notificationService.error().afterDismissed();
		} else {
			this.interactionService.confirm('Generic Error', JSON.stringify(error), 'PDM.GENERICS.BUTTON.OK', 'error');
		}
	}

	constructor(
		private readonly router: Router,
		private readonly actions$: Actions,
		private readonly interactionService: InteractionService,
		private readonly notificationService: NotificationService,
		private readonly collaboratorsService: CollaboratorsV2Service
	) {}
}
