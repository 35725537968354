import { createReducer, on } from '@ngrx/store';
import * as CollaboratorDetailActions from './collaborator-detail.actions';
import * as CollaboratorDangerActionActions from './danger-actions/collaborator-danger-action.actions';
import { CollaboratorDetail } from '../../models/collaborator-detail.model';
import { EDangerZoneActionType } from '../../models/enums/danger-zone-action-type.enum';
import * as AssignRoleActions from '../../../../../core/store/actions/assign-role.actions';

export interface CollaboratorDetailState {
	collaborator: CollaboratorDetail | undefined;
}

const initialState: CollaboratorDetailState = {
	collaborator: undefined
};

export const collaboratorDetailReducers = createReducer(
	initialState,
	on(CollaboratorDetailActions.loadCollaboratorDetailSuccess, (state, action) => ({
		...state,
		collaborator: action.collaborator
	})),
	on(CollaboratorDangerActionActions.collaboratorDangerActionSuccess, (state, action) => ({
		...state,
		collaborator: {
			...state.collaborator!,
			isDeleted: action.actionType === EDangerZoneActionType.DELETE,
			isBlocked: action.actionType === EDangerZoneActionType.BLOCK
		}
	})),
	on(AssignRoleActions.assignRolesSuccessV2, (state, action) => ({
		...state,
		collaborator: {
			...state.collaborator!,
			roleId: action.roleId
		}
	}))
);
