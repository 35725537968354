import { createFeatureSelector, createSelector } from '@ngrx/store';
import { LegislatureDetailState } from './legislature-detail.reducers';

export const legislatureDetailFeatureKey = 'legislatureDetail';

const selectLegislatureDetailFeature = createFeatureSelector<LegislatureDetailState>(legislatureDetailFeatureKey);
export const getLegislatureDetail = createSelector(
	selectLegislatureDetailFeature,
	(state: LegislatureDetailState) => state?.legislatureDetail
);
export const getSimplePoliticians = createSelector(
	selectLegislatureDetailFeature,
	(state: LegislatureDetailState) => state.simplePoliticians
);
