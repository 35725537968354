import { createAction, props } from '@ngrx/store';
import { Pagination } from '@core/models/miscellaneous/server-response.model';
import { Group } from '../../models/group.model';
import { ServerError } from '@core/models/server-error.model';

export const initGroupsList = createAction('[Groups List] Init', props<{ params: Record<string, string | boolean> }>());
export const loadGroupsList = createAction('[Groups List] Load', props<{ params: Record<string, string | boolean> }>());
export const loadGroupsListSuccess = createAction(
	'[Groups List] Load success',
	props<{
		groups: Group[];
		pagination: Pagination;
	}>()
);
export const loadGroupsListFailed = createAction('[Groups List] Load failed', props<{ error: ServerError }>());
