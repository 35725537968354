import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { SessionsService } from '../../services/sessions.service';
import * as SessionsDeleteActions from './session-delete.actions';
import { concatMap, of, switchMap, tap } from 'rxjs';
import * as LoadingActions from '../../../../core/store/actions/loading.actions';
import { catchError, map } from 'rxjs/operators';
import { NotificationService } from '@core/services/notification.service';
import { ServerError } from '@core/models/server-error.model';
import { environment } from '../../../../../environments/environment';
import { InteractionService } from '../../../../shared/interaction/interaction.service';

@Injectable()
export class SessionDeleteEffects {
	$init = createEffect(() =>
		this.actions$.pipe(
			ofType(SessionsDeleteActions.initDeleteSessions),
			concatMap(({ sessionIds }) => [LoadingActions.startLoading(), SessionsDeleteActions.deleteSessions({ sessionIds })])
		)
	);

	$delete = createEffect(() =>
		this.actions$.pipe(
			ofType(SessionsDeleteActions.deleteSessions),
			switchMap(({ sessionIds }) =>
				this.sessionService.delete(sessionIds).pipe(
					map(() => SessionsDeleteActions.deleteSessionsSuccess({ sessionIds })),
					catchError(error => of(SessionsDeleteActions.deleteSessionsError(error)))
				)
			)
		)
	);

	deleteSuccess$ = createEffect(
		() =>
			this.actions$.pipe(
				ofType(SessionsDeleteActions.deleteSessionsSuccess),
				tap(() => this.notificationService.success('sessions.DELETE_SUCCESS'))
			),
		{ dispatch: false }
	);

	$deleteFailed = createEffect(
		() =>
			this.actions$.pipe(
				ofType(SessionsDeleteActions.deleteSessionsError),
				switchMap(({ error }) => {
					this.showDefaultError(error);

					return [LoadingActions.stopLoading()];
				})
			),
		{ dispatch: false }
	);

	stopLoading$ = createEffect(() =>
		this.actions$.pipe(
			ofType(SessionsDeleteActions.deleteSessionsSuccess, SessionsDeleteActions.deleteSessionsError),
			switchMap(() => [LoadingActions.stopLoading()])
		)
	);

	private showDefaultError(error: ServerError) {
		if (environment.production) {
			this.notificationService.error().afterDismissed();
		} else {
			this.interactionService.confirm('Generic Error', JSON.stringify(error), 'PDM.GENERICS.BUTTON.OK', 'error');
		}
	}

	constructor(
		private readonly actions$: Actions,
		private readonly sessionService: SessionsService,
		private readonly interactionService: InteractionService,
		private readonly notificationService: NotificationService
	) {}
}
