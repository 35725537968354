import { createAction, props } from '@ngrx/store';
import { Politician } from '../../models/politician.model';

export const initPoliticiansV2Detail = createAction(
	'[PoliticiansV2Detail] Init',
	props<{
		id: string;
	}>()
);
export const loadPoliticiansV2Detail = createAction(
	'[PoliticiansV2Detail] Load',
	props<{
		id: string;
	}>()
);

export const loadPoliticiansV2DetailSuccess = createAction(
	'[PoliticiansV2Detail] Load success',
	props<{
		politician: Politician;
	}>()
);
export const loadPoliticiansV2DetailFailed = createAction('[PoliticiansV2Detail] Load failed');
