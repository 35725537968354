import { createAction, props } from '@ngrx/store';
import { LegislatureDetail } from '../../../../models/legislature-detail.model';
import { ServerError } from '@core/models/server-error.model';

export const initDeleteMinistries = createAction(
	'[Legislature Ministry] Init Delete Ministries',
	props<{ legislatureId: string; ids: string[] }>()
);
export const deleteMinistries = createAction('[Legislature Ministry] Delete Ministries', props<{ legislatureId: string; ids: string[] }>());
export const deleteMinistriesSuccess = createAction(
	'[Legislature Ministry] Delete Ministries success',
	props<{ legislatureDetail: LegislatureDetail }>()
);
export const deleteMinistriesFailed = createAction('[Legislature Ministry] Delete Ministries failed', props<{ error: ServerError }>());
