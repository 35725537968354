import { Injectable } from '@angular/core';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { TranslocoService } from '@ngneat/transloco';

@Injectable()
export class PDMPaginationIntlService extends MatPaginatorIntl {
	constructor(private readonly translateService: TranslocoService) {
		super();
		this.translateLabels();
	}

	override getRangeLabel = (page: number, pageSize: number, length: number): string => {
		if (pageSize === 0 || length === 0) {
			return `0 di ${length}`;
		}

		length = Math.max(length, 0);
		const startIndex = page * pageSize > length ? (Math.ceil(length / pageSize) - 1) * pageSize : page * pageSize;

		const endIndex = Math.min(startIndex + pageSize, length);
		return `${startIndex + 1} - ${endIndex} di ${length}`;
	};

	private translateLabels(): void {
		this.firstPageLabel = this.translateService.translate('PDM.PAGINATION.FIRST_PAGE');
		this.itemsPerPageLabel = this.translateService.translate('PDM.PAGINATION.ITEMS_PER_PAGE');
		this.lastPageLabel = this.translateService.translate('PDM.PAGINATION.LAST_PAGE');
		this.nextPageLabel = this.translateService.translate('PDM.PAGINATION.NEXT_PAGE');
		this.previousPageLabel = this.translateService.translate('PDM.PAGINATION.PREVIOUS_PAGE');
		this.changes.next(); // Fire a change event to make sure that the labels are refreshed
	}
}
