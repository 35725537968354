export enum EUserPermissions {
	ROLE_CREATE = 'role:create',
	ROLE_DELETE = 'role:delete',
	ROLE_LIST = 'role:list',
	ROLE_ADD_PERMISSION = 'role:add-permission',
	ROLE_REMOVE_PERMISSION = 'role:remove-permission',
	ROLE_UPDATE = 'role:update',
	ROLE_READ = 'role:read',
	ROLE_USER_ASSIGN = 'role:user-assign',

	USER_BLOCK = 'user:block',
	USER_UNBLOCK = 'user:unblock',
	USER_DELETE = 'user:delete',
	USER_LIST = 'user:list',
	USER_READ = 'user:read',
	USER_UPDATE = 'user:update',

	ARTICLE_LIST_OWN = 'article:list:own',
	ARTICLE_LIST_ANY = 'article:list:any',
	ARTICLE_DELETE_OWN = 'article:delete:own',
	ARTICLE_DELETE_ANY = 'article:delete:any',
	ARTICLE_CREATE = 'article:create',
	ARTICLE_READ_ANY = 'article:read:any',
	ARTICLE_READ_OWN = 'article:read:own',
	ARTICLE_UPDATE_ANY = 'article:update:any',
	ARTICLE_UPDATE_OWN = 'article:update:own',
	ARTICLE_REVIEW_ANY = 'article:review:any',
	ARTICLE_REVIEW_OWN = 'article:review:own',
	ARTICLE_REWORK_ANY = 'article:rework:any',
	ARTICLE_REWORK_OWN = 'article:rework:own',
	ARTICLE_APPROVE_ANY = 'article:approve:any',
	ARTICLE_APPROVE_OWN = 'article:approve:own',
	ARTICLE_PUBLISH_ANY = 'article:publish:any',
	ARTICLE_PUBLISH_OWN = 'article:publish:own',
	ARTICLE_ARCHIVE_ANY = 'article:archive:any',
	ARTICLE_ARCHIVE_OWN = 'article:archive:own',
	ARTICLE_REMOVE_ANY = 'article:remove:any',
	ARTICLE_REMOVE_OWN = 'article:remove:own',

	ACTIVITY_CREATE = 'activity:create',
	ACTIVITY_LIST_OWN = 'activity:list:own',
	ACTIVITY_LIST_ANY = 'activity:list:any',
	ACTIVITY_READ_ANY = 'activity:read:any',
	ACTIVITY_READ_OWN = 'activity:read:own',
	ACTIVITY_DELETE_OWN = 'activity:delete:own',
	ACTIVITY_DELETE_ANY = 'activity:delete:any',
	ACTIVITY_UPDATE_OWN = 'activity:update:own',
	ACTIVITY_UPDATE_ANY = 'activity:update:any',
	ACTIVITY_REVIEW_ANY = 'activity:review:any',
	ACTIVITY_REVIEW_OWN = 'activity:review:own',
	ACTIVITY_REMOVE_ANY = 'activity:remove:any',
	ACTIVITY_REMOVE_OWN = 'activity:remove:own',
	ACTIVITY_REWORK_ANY = 'activity:rework:any',
	ACTIVITY_REWORK_OWN = 'activity:rework:own',
	ACTIVITY_APPROVE_ANY = 'activity:approve:any',
	ACTIVITY_APPROVE_OWN = 'activity:approve:own',
	ACTIVITY_PUBLISH_ANY = 'activity:publish:any',
	ACTIVITY_PUBLISH_OWN = 'activity:publish:own',
	ACTIVITY_ARCHIVE_ANY = 'activity:archive:any',
	ACTIVITY_ARCHIVE_OWN = 'activity:archive:own',

	POLITICIAN_LIST = 'politician:list',
	POLITICIAN_READ = 'politician:read',

	GROUP_LIST = 'group:list',
	GROUP_UPDATE = 'group:update',

	LEGISLATURE_LIST = 'legislature:list',
	LEGISLATURE_READ = 'legislature:read',
	LEGISLATURE_UPDATE = 'legislature:update',
	LEGISLATURE_CREATE = 'legislature:create',
	LEGISLATURE_ADD_MINISTRIES = 'legislature:add-ministries',
	LEGISLATURE_DELETE = 'legislature:delete',
	LEGISLATURE_DUPLICATE = 'legislature:duplicate',
	LEGISLATURE_UPDATE_MINISTRY = 'legislature:update-ministry'
}
