import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import * as ActivitiesV2ReviewActions from './activities-V2-review.actions';
import { concatMap, of, switchMap, tap } from 'rxjs';
import * as LoadingActions from '@core/store/actions/loading.actions';
import { catchError, map } from 'rxjs/operators';
import { NotificationService } from '@core/services/notification.service';
import { ServerError } from '@core/models/server-error.model';
import { InteractionService } from '../../../../shared/interaction/interaction.service';
import { Router } from '@angular/router';
import { environment } from '@environment/environment';
import { TranslocoService } from '@ngneat/transloco';
import { ActivitiesV2Service } from '../../services/activities-V2.service';
import { EActivitiesV2ErrorCodes } from '../../models/enum/activities-V2-error-code.enum';

@Injectable()
export class ActivitiesV2ReviewEffects {
	init$ = createEffect(() =>
		this.actions$.pipe(
			ofType(ActivitiesV2ReviewActions.initAskReviewActivityV2),
			switchMap(({ id, message }) => [
				LoadingActions.startLoading(),
				ActivitiesV2ReviewActions.askReviewActivityV2({
					id,
					message
				})
			])
		)
	);

	review$ = createEffect(() =>
		this.actions$.pipe(
			ofType(ActivitiesV2ReviewActions.askReviewActivityV2),
			concatMap(({ id, message }) =>
				this.activityService.submitToReview(id, message).pipe(
					map(result => ActivitiesV2ReviewActions.askReviewActivityV2Success({ activity: result })),
					catchError(e => of(ActivitiesV2ReviewActions.askReviewActivityV2Failed({ error: e as ServerError })))
				)
			)
		)
	);

	reviewSuccess$ = createEffect(
		() =>
			this.actions$.pipe(
				ofType(ActivitiesV2ReviewActions.askReviewActivityV2Success),
				tap(() => {
					this.notificationService.success('activities.detail.success.askReview').afterDismissed();
					this.router.navigate([`attivita`]);
				})
			),
		{ dispatch: false }
	);

	reviewFailed$ = createEffect(() =>
		this.actions$.pipe(
			ofType(ActivitiesV2ReviewActions.askReviewActivityV2Failed),
			switchMap(({ error }) => {
				if (error.internalCode in EActivitiesV2ErrorCodes) {
					if (
						error.internalCode === EActivitiesV2ErrorCodes.MISSING_DATA &&
						error.details.validation &&
						error.details.validation.length > 0
					) {
						const validations = error.details.validation.map(
							code => `activities.detail.errors.validation.${code.internalCode}`
						);
						this.interactionService.confirmValidationV2('activities.detail.errors.validation.title', validations);
					} else {
						this.interactionService.confirm(
							`activities.detail.errors.${error.internalCode}.title`,
							this.translateService.translate(`activities.detail.errors.${error.internalCode}.message`),
							'PDM.GENERICS.BUTTON.OK',
							'error'
						);
					}
				} else {
					this.showDefaultError(error);
				}

				return [LoadingActions.stopLoading()];
			})
		)
	);

	stopLoading$ = createEffect(() =>
		this.actions$.pipe(
			ofType(ActivitiesV2ReviewActions.askReviewActivityV2Success, ActivitiesV2ReviewActions.askReviewActivityV2Failed),
			switchMap(() => [LoadingActions.stopLoading()])
		)
	);

	private showDefaultError(error: ServerError) {
		if (environment.production) {
			this.notificationService.error().afterDismissed();
		} else {
			this.interactionService.confirm('Generic Error', JSON.stringify(error), 'PDM.GENERICS.BUTTON.OK', 'error');
		}
	}

	constructor(
		private readonly router: Router,
		private readonly actions$: Actions,
		private readonly activityService: ActivitiesV2Service,
		private readonly translateService: TranslocoService,
		private readonly interactionService: InteractionService,
		private readonly notificationService: NotificationService
	) {}
}
