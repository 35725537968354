import { createAction, props } from '@ngrx/store';
import { LegislatureDetail } from '../../../../models/legislature-detail.model';
import { ServerError } from '@core/models/server-error.model';
import { MinistryAdd } from '../../../../models/ministry-add.model';

export const initAddMinistries = createAction(
	'[Legislature Ministry] Init Add Ministries',
	props<{ legislatureId: string; ministries: MinistryAdd[] }>()
);
export const addMinistries = createAction(
	'[Legislature Ministry] Add Ministries',
	props<{ legislatureId: string; ministries: MinistryAdd[] }>()
);
export const addMinistriesSuccess = createAction(
	'[Legislature Ministry] Add Ministries success',
	props<{ legislatureDetail: LegislatureDetail }>()
);
export const addMinistriesFailed = createAction('[Legislature Ministry] Add Ministries failed', props<{ error: ServerError }>());
