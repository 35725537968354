import { Injectable } from '@angular/core';
import { filter, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { getUserPermissions } from '@core/profile/store/profile.selector';
import { EUserPermissions } from '@core/models/enums/user-permissions.enum';
import { AppState } from '@core/core.module';
import { ActivityV2Detail } from '../models/activity-V2-detail.model';
import { EActivityV2Status } from '../models/enum/activities-V2-status.enum';
import { ActivityV2AllowedActions } from '../models/activities-V2-allowed-actions.model';

@Injectable()
export class ActivitiesV2DetailPermissionsService {
	constructor(private readonly store$: Store<AppState>) {}

	public getPermission({ status, isMine }: ActivityV2Detail): Observable<ActivityV2AllowedActions> {
		return this.store$.select(getUserPermissions).pipe(
			filter(permissions => permissions?.length > 0),
			map((permissions: string[]) => {
				const allowedActions: ActivityV2AllowedActions = {
					canPhysicallyDelete:
						EActivityV2Status.DRAFT === status &&
						(permissions.includes(EUserPermissions.ACTIVITY_DELETE_ANY.valueOf()) ||
							(permissions.includes(EUserPermissions.ACTIVITY_DELETE_OWN.valueOf()) && isMine)),
					canAskReview:
						[EActivityV2Status.DRAFT, EActivityV2Status.REWORK].includes(status) &&
						(permissions.includes(EUserPermissions.ACTIVITY_REVIEW_ANY.valueOf()) ||
							(permissions.includes(EUserPermissions.ACTIVITY_REVIEW_OWN.valueOf()) && isMine)),
					canApprove:
						EActivityV2Status.IN_REVIEW === status &&
						(permissions.includes(EUserPermissions.ACTIVITY_APPROVE_ANY.valueOf()) ||
							(permissions.includes(EUserPermissions.ACTIVITY_APPROVE_OWN.valueOf()) && isMine)),
					canRework:
						EActivityV2Status.IN_REVIEW === status &&
						(permissions.includes(EUserPermissions.ACTIVITY_REWORK_ANY.valueOf()) ||
							(permissions.includes(EUserPermissions.ACTIVITY_REWORK_OWN.valueOf()) && isMine)),
					canPublish:
						EActivityV2Status.READY === status &&
						(permissions.includes(EUserPermissions.ACTIVITY_PUBLISH_ANY.valueOf()) ||
							(permissions.includes(EUserPermissions.ACTIVITY_PUBLISH_OWN.valueOf()) && isMine)),
					canArchive:
						EActivityV2Status.PUBLISHED === status &&
						(permissions.includes(EUserPermissions.ACTIVITY_ARCHIVE_ANY.valueOf()) ||
							(permissions.includes(EUserPermissions.ACTIVITY_ARCHIVE_OWN.valueOf()) && isMine)),
					canLogicallyDelete:
						![EActivityV2Status.DRAFT, EActivityV2Status.DELETED, EActivityV2Status.ARCHIVED].includes(status) &&
						(permissions.includes(EUserPermissions.ACTIVITY_REMOVE_ANY.valueOf()) ||
							(permissions.includes(EUserPermissions.ACTIVITY_REMOVE_OWN.valueOf()) && isMine)),
					canBeEdited:
						![
							EActivityV2Status.DELETED,
							EActivityV2Status.ARCHIVED,
							EActivityV2Status.READY,
							EActivityV2Status.PUBLISHED
						].includes(status) &&
						(permissions.includes(EUserPermissions.ACTIVITY_UPDATE_ANY.valueOf()) ||
							(permissions.includes(EUserPermissions.ACTIVITY_UPDATE_OWN.valueOf()) && isMine))
				};

				return allowedActions;
			})
		);
	}

	public getInitialPermission() {
		const allowedActions: ActivityV2AllowedActions = {
			canPhysicallyDelete: false,
			canAskReview: false,
			canApprove: false,
			canRework: false,
			canPublish: false,
			canArchive: false,
			canLogicallyDelete: false,
			canBeEdited: true
		};

		return allowedActions;
	}
}
