import { createAction, props } from '@ngrx/store';

export const login = createAction('[Auth] Login', props<{ email: string; password: string; deleteOldest: boolean }>());
export const loginSuccess = createAction('[Auth] Login success');
export const loginFailed = createAction('[Auth] Login failed');

export const logout = createAction('[Auth] Logout');
export const logoutSuccess = createAction('[Auth] Logout success');
export const logoutFailed = createAction('[Auth] Logout failed');

export const invalidCredentials = createAction('[Auth] Invalid credentials');
export const blockedAccount = createAction('[Auth] Blocked account');
export const maxSessions = createAction('[Auth] Max sessions');
