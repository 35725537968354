import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { MiscellaneousService } from '@core/services/miscellaneous.service';
import { exhaustMap, of, switchMap } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import * as ProvincesActions from '../actions/provinces.actions';
import * as LoadingActions from '../actions/loading.actions';

@Injectable()
export class ProvincesEffects {
	loadProvinces$ = createEffect(() =>
		this.actions$.pipe(
			ofType(ProvincesActions.loadAllProvinces),
			exhaustMap(() =>
				this.miscellaneousService.getProvinces().pipe(
					map(provinces => ProvincesActions.loadAllProvincesSuccess({ provinces })),
					catchError(() => of(ProvincesActions.loadAllProvincesFailed()))
				)
			)
		)
	);

	initLoadProvincesInRegion$ = createEffect(() =>
		this.actions$.pipe(
			ofType(ProvincesActions.initLoadAllProvincesInRegion),
			switchMap(({ regionId }) => [LoadingActions.startLoading(), ProvincesActions.loadProvinceInRegion({ regionId })])
		)
	);

	loadProvincesInRegion$ = createEffect(() =>
		this.actions$.pipe(
			ofType(ProvincesActions.loadProvinceInRegion),
			exhaustMap(({ regionId }) =>
				this.miscellaneousService.getProvinces(regionId).pipe(
					map(provinces => ProvincesActions.loadProvinceInRegionSuccess({ provinces })),
					catchError(() => of(ProvincesActions.loadProvinceInRegionFailed()))
				)
			)
		)
	);

	loadProvincesInRegionsSuccess$ = createEffect(() =>
		this.actions$.pipe(
			ofType(ProvincesActions.loadProvinceInRegionSuccess),
			switchMap(() => [LoadingActions.stopLoading()])
		)
	);

	constructor(private readonly actions$: Actions, private readonly miscellaneousService: MiscellaneousService) {}
}
