import { createAction, props } from '@ngrx/store';
import { RolesAndPermissionsCreate } from '../../models/roles-and-permissions-create.model';
import { ServerError } from '@core/models/server-error.model';
import { RoleV2 } from '@core/models/roles/roleV2.model';

export const initUpdateRolesAndPermissionsV2 = createAction(
	'[Roles and Permissions V2] Init Update',
	props<{ id: string; role: RolesAndPermissionsCreate }>()
);
export const updateRolesAndPermissionsV2 = createAction(
	'[Roles and Permissions V2] Update',
	props<{
		id: string;
		role: RolesAndPermissionsCreate;
	}>()
);
export const updateRolesAndPermissionsV2Success = createAction('[Roles and Permissions V2] Udate Success', props<{ role: RoleV2 }>());
export const updateRolesAndPermissionsV2Failed = createAction('[Roles and Permissions V2] Update Failed', props<{ error: ServerError }>());
