import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ActionButtonsComponent } from './action-buttons.component';
import { ButtonComponent } from './components/button/button.component';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatButtonModule } from '@angular/material/button';

@NgModule({
	declarations: [ActionButtonsComponent, ButtonComponent],
	exports: [ActionButtonsComponent, ButtonComponent],
	imports: [CommonModule, MatButtonModule, MatIconModule, MatProgressSpinnerModule]
})
export class ActionButtonsModule {}
