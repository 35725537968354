import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import * as ActivityV2PatchActions from './activities-V2-patch.actions';
import * as ActivityV2ReviewActions from '../review/activities-V2-review.actions';
import { concatMap, of, switchMap, tap } from 'rxjs';
import * as LoadingActions from '../../../../core/store/actions/loading.actions';
import { catchError, map } from 'rxjs/operators';
import { NotificationService } from '@core/services/notification.service';
import { InteractionService } from '../../../../shared/interaction/interaction.service';
import { ServerError } from '@core/models/server-error.model';
import { TranslocoService } from '@ngneat/transloco';
import { environment } from '@environment/environment';
import { ActivitiesV2Service } from '../../services/activities-V2.service';
import { EActivitiesV2ErrorCodes } from '../../models/enum/activities-V2-error-code.enum';

@Injectable()
export class ActivitiesV2PatchEffects {
	init$ = createEffect(() =>
		this.actions$.pipe(
			ofType(ActivityV2PatchActions.initPatchActivityV2),
			switchMap(({ id, activity }) => [
				LoadingActions.startLoading(),
				ActivityV2PatchActions.patchActivityV2({
					id,
					activity
				})
			])
		)
	);

	initAndFireReview$ = createEffect(() =>
		this.actions$.pipe(
			ofType(ActivityV2PatchActions.initPatchActivityV2AndFireAskReview),
			switchMap(({ id, activity, message }) => [
				LoadingActions.startLoading(),
				ActivityV2PatchActions.patchActivityV2AndFireAskReview({ id, activity, message })
			])
		)
	);

	patch$ = createEffect(() =>
		this.actions$.pipe(
			ofType(ActivityV2PatchActions.patchActivityV2),
			switchMap(({ id, activity }) =>
				this.activitiesV2Service.patch(id, activity).pipe(
					map(result => ActivityV2PatchActions.patchActivityV2Success({ activity: result })),
					catchError(error => of(ActivityV2PatchActions.patchActivityV2Failed({ error })))
				)
			)
		)
	);

	patchAndFireAskReview$ = createEffect(() =>
		this.actions$.pipe(
			ofType(ActivityV2PatchActions.patchActivityV2AndFireAskReview),
			switchMap(({ id, activity, message }) =>
				this.activitiesV2Service.patch(id, activity).pipe(
					concatMap(result => [
						ActivityV2PatchActions.patchActivityV2Success({ activity: result }),
						ActivityV2ReviewActions.initAskReviewActivityV2({ id: result.id, message })
					]),
					catchError(error => of(ActivityV2PatchActions.patchActivityV2Failed({ error })))
				)
			)
		)
	);

	patchSuccess$ = createEffect(
		() =>
			this.actions$.pipe(
				ofType(ActivityV2PatchActions.patchActivityV2Success),
				tap(() => {
					this.notificationService.success('activities.detail.success.patch');
				})
			),
		{ dispatch: false }
	);

	patchFailed$ = createEffect(() =>
		this.actions$.pipe(
			ofType(ActivityV2PatchActions.patchActivityV2Failed),
			switchMap(({ error }) => {
				if (error.internalCode in EActivitiesV2ErrorCodes) {
					if (
						error.internalCode === EActivitiesV2ErrorCodes.DUPLICATE_TITLE &&
						error.details &&
						error.details.duplicatedTitle &&
						error.details.duplicatedTitle.length > 0
					) {
						const validations = [
							this.translateService.translate(`activities.detail.errors.validation.DUPLICATE_TITLE`, {
								title: error.details.duplicatedTitle
							})
						];
						this.interactionService.confirmValidationV2('activities.detail.errors.validation.title', validations);
					} else {
						this.interactionService.confirm(
							`activities.detail.errors.${error.internalCode}.title`,
							this.translateService.translate(`activities.detail.errors.${error.internalCode}.message`),
							'PDM.GENERICS.BUTTON.OK',
							'error'
						);
					}
				} else {
					this.showDefaultError(error);
				}

				return [LoadingActions.stopLoading()];
			})
		)
	);

	private showDefaultError(error: ServerError) {
		if (environment.production) {
			this.notificationService.error().afterDismissed();
		} else {
			this.interactionService.confirm('Generic Error', JSON.stringify(error), 'PDM.GENERICS.BUTTON.OK', 'error');
		}
	}

	stopLoading$ = createEffect(() =>
		this.actions$.pipe(
			ofType(ActivityV2PatchActions.patchActivityV2Success, ActivityV2PatchActions.patchActivityV2Failed),
			switchMap(() => [LoadingActions.stopLoading()])
		)
	);

	constructor(
		private readonly actions$: Actions,
		private readonly activitiesV2Service: ActivitiesV2Service,
		private readonly translateService: TranslocoService,
		private readonly interactionService: InteractionService,
		private readonly notificationService: NotificationService
	) {}
}
