import { createReducer, on } from '@ngrx/store';
import * as RolesAndPermissionsV2ListActions from './list/roles-and-permissions-v2-list.actions';
import * as RolesAndPermissionsV2DeleteRoleActions from './delete/roles-and-permissions-v2-delete-role.actions';
import * as RolesAndPermissionsV2UnlinkPermissionActions from './unlink-permission/roles-and-permissions-v2-unlink-permission.action';
import * as RolesAndPermissionsV2DetailActions from './detail/roles-and-permissions-v2-detail.actions';
import * as RolesAndPermissionsV2UpdateActions from './update/roles-and-permissions-v2-update.actions';
import { RoleV2 } from '@core/models/roles/roleV2.model';

export const rolesAndPermissionsV2FeatureKey = 'rolesAndPermissionsV2';

export interface RolesAndPermissionsV2State {
	roles: RoleV2[];
	role: RoleV2 | undefined;
}

export const initialState: RolesAndPermissionsV2State = {
	roles: [],
	role: undefined
};

export const rolesAndPermissionsV2Reducer = createReducer(
	initialState,
	on(RolesAndPermissionsV2ListActions.loadRolesAndPermissionsV2Success, (state, action) => ({
		...state,
		roles: action.roles
	})),
	on(RolesAndPermissionsV2DeleteRoleActions.deleteRoleSuccess, (state, action) => ({
		...state,
		roles: state.roles.filter(role => role.id !== action.id)
	})),
	on(RolesAndPermissionsV2UnlinkPermissionActions.unlinkPermissionsSuccess, (state, action) => ({
		...state,
		roles: state.roles.map(role =>
			role.id !== action.roleId
				? role
				: {
						...role,
						permissions: role.permissions.filter(
							permission => !action.permissions.map(permission => permission.id).includes(permission.id)
						)
				  }
		)
	})),
	on(RolesAndPermissionsV2DetailActions.detailRoleSuccess, (state, action) => ({
		...state,
		role: action.role
	})),
	on(RolesAndPermissionsV2UpdateActions.updateRolesAndPermissionsV2Success, (state, action) => ({
		...state,
		roles: [action.role, ...state.roles.filter(role => role.id !== action.role.id)]
	}))
);
