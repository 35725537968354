import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { filter, Observable, throwError } from 'rxjs';
import { environment } from '../../../environments/environment';
import { catchError } from 'rxjs/operators';
import { ServerError } from '@core/models/server-error.model';
import { AppState } from '../core.module';
import { Store } from '@ngrx/store';
import { httpError } from '../store/actions/http-error.actions';

@Injectable()
export class ApiInterceptor implements HttpInterceptor {
	constructor(private readonly store$: Store<AppState>) {}

	// eslint-disable-next-line
	intercept(req: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
		let httpRequest = req;
		if (!httpRequest.url.startsWith('./assets')) {
			httpRequest = req.clone({
				url: `${environment.api}${req.url}`,
				withCredentials: true
			});
		}
		return next.handle(httpRequest).pipe(
			filter((event: HttpEvent<unknown>) => event instanceof HttpResponse),
			catchError(err => {
				if (err instanceof HttpErrorResponse) {
					const { error } = err as HttpErrorResponse;
					const serverError = error as ServerError;
					this.store$.dispatch(
						httpError({
							url: httpRequest.url,
							code: serverError.statusCode,
							internalCode: serverError.internalCode
						})
					);
				}

				return throwError(err);
			})
		);
	}
}
