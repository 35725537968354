import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import * as CategoriesActions from '../actions/categories.actions';
import { of, switchMap } from 'rxjs';
import * as LoadingActions from '../actions/loading.actions';
import { catchError, map } from 'rxjs/operators';
import { CategoriesService } from '@core/services/categories.service';

@Injectable()
export class CategoriesEffects {
	init$ = createEffect(() =>
		this.actions$.pipe(
			ofType(CategoriesActions.initCategories),
			switchMap(({ categoryType }) => [LoadingActions.startLoading(), CategoriesActions.loadCategories({ categoryType })])
		)
	);

	load$ = createEffect(() =>
		this.actions$.pipe(
			ofType(CategoriesActions.loadCategories),
			switchMap(({ categoryType }) =>
				this.categoriesService.getCategories(categoryType).pipe(
					map(categories => CategoriesActions.loadCategoriesSuccess({ categories })),
					catchError(() => of(CategoriesActions.loadCategoriesFailed()))
				)
			)
		)
	);

	loadSuccess$ = createEffect(() =>
		this.actions$.pipe(
			ofType(CategoriesActions.loadCategoriesSuccess),
			switchMap(() => [LoadingActions.stopLoading()])
		)
	);

	constructor(private readonly actions$: Actions, private readonly categoriesService: CategoriesService) {}
}
