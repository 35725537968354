import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { InteractionData } from './models/interaction-data.model';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
	selector: 'pdm-interaction',
	templateUrl: './interaction.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class InteractionComponent {
	constructor(@Inject(MAT_DIALOG_DATA) public data: InteractionData) {}
}
