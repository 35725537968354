import { createReducer, on } from '@ngrx/store';
import { Pagination } from '@core/models/miscellaneous/server-response.model';
import { Legislature } from '../models/legislature.model';
import * as LegislaturesActions from './legislatures.actions';
import * as LegislaturesDeleteActions from './delete/legislature-delete.actions';
import * as LegislaturesDuplicateActions from './duplicate/legislature-duplicate.actions';
import * as LegislaturesCreateActions from './create/legislature-create.actions';

export interface LegislatureState {
	legislatures: Legislature[];
	pagination: Pagination;
}

const initialState: LegislatureState = {
	legislatures: [],
	pagination: {} as Pagination
};

export const legislaturesReducers = createReducer(
	initialState,
	on(LegislaturesActions.loadLegislaturesSuccess, (state, action) => ({
		...state,
		legislatures: [...action.legislatures],
		pagination: action.pagination
	})),
	on(LegislaturesDeleteActions.deleteLegislatureSuccess, (state, action) => ({
		...state,
		legislatures: [...state.legislatures.filter(legislature => legislature.id !== action.id)]
	})),
	on(LegislaturesDuplicateActions.duplicateLegislatureSuccess, (state, action) => ({
		...state,
		legislatures: [action.legislature, ...state.legislatures]
	})),
	on(LegislaturesCreateActions.createLegislatureSuccess, (state, action) => ({
		...state,
		legislatures: [
			...state.legislatures,
			{
				id: action.legislatureDetail.id,
				name: action.legislatureDetail.name,
				endDate: action.legislatureDetail.endDate,
				startDate: action.legislatureDetail.startDate,
				numberOfMinistries: action.legislatureDetail.ministries.length
			}
		]
	}))
);
