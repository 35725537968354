import { createReducer, on } from '@ngrx/store';
import * as AuthActions from './auth.actions';
import * as ProfileActions from '../../profile/store/profile.actions';
import * as PasswordResetActions from './password-reset.action';

export interface AuthState {
	loggedIn: boolean;
	error: boolean;
	loading: boolean;
	invalidCredentials: boolean;
	blockedAccount: boolean;
	maxNumberOfSessions: boolean;
	resetLinkSuccess: boolean;
	passwordResented: boolean;
}

export const initialState: AuthState = {
	loggedIn: false,
	error: false,
	loading: false,
	invalidCredentials: false,
	blockedAccount: false,
	maxNumberOfSessions: false,
	resetLinkSuccess: false,
	passwordResented: false
};

export const authReducer = createReducer(
	initialState,
	on(AuthActions.login, () => ({
		loggedIn: false,
		error: false,
		loading: true,
		invalidCredentials: false,
		blockedAccount: false,
		maxNumberOfSessions: false,
		resetLinkSuccess: false,
		passwordResented: false
	})),
	on(AuthActions.loginSuccess, () => ({
		loggedIn: true,
		error: false,
		loading: false,
		invalidCredentials: false,
		blockedAccount: false,
		maxNumberOfSessions: false,
		resetLinkSuccess: false,
		passwordResented: false
	})),
	on(ProfileActions.loadMeSuccess, state => ({
		...state,
		loggedIn: true
	})),
	on(AuthActions.logoutSuccess, () => ({
		loggedIn: false,
		error: false,
		loading: false,
		invalidCredentials: false,
		blockedAccount: false,
		maxNumberOfSessions: false,
		resetLinkSuccess: false,
		passwordResented: false
	})),
	on(AuthActions.invalidCredentials, () => ({
		loggedIn: false,
		error: true,
		loading: false,
		invalidCredentials: true,
		blockedAccount: false,
		maxNumberOfSessions: false,
		resetLinkSuccess: false,
		passwordResented: false
	})),
	on(AuthActions.blockedAccount, () => ({
		loggedIn: false,
		error: true,
		loading: false,
		invalidCredentials: false,
		blockedAccount: true,
		maxNumberOfSessions: false,
		resetLinkSuccess: false,
		passwordResented: false
	})),
	on(AuthActions.maxSessions, () => ({
		loggedIn: false,
		error: true,
		loading: false,
		invalidCredentials: false,
		blockedAccount: false,
		maxNumberOfSessions: true,
		resetLinkSuccess: false,
		passwordResented: false
	})),
	on(PasswordResetActions.passwordResetLink, () => ({
		loggedIn: false,
		error: false,
		loading: true,
		invalidCredentials: false,
		blockedAccount: false,
		maxNumberOfSessions: false,
		resetLinkSuccess: false,
		passwordResented: false
	})),
	on(PasswordResetActions.passwordResetLinkFailed, () => ({
		loggedIn: false,
		error: true,
		loading: false,
		invalidCredentials: false,
		blockedAccount: false,
		maxNumberOfSessions: false,
		resetLinkSuccess: false,
		passwordResented: false
	})),
	on(PasswordResetActions.passwordResetLinkSuccess, () => ({
		loggedIn: false,
		error: false,
		loading: false,
		invalidCredentials: false,
		blockedAccount: false,
		maxNumberOfSessions: false,
		resetLinkSuccess: true,
		passwordResented: false
	})),
	on(PasswordResetActions.passwordReset, () => ({
		loggedIn: false,
		error: false,
		loading: true,
		invalidCredentials: false,
		blockedAccount: false,
		maxNumberOfSessions: false,
		resetLinkSuccess: false,
		passwordResented: false
	})),
	on(PasswordResetActions.passwordResetFailed, () => ({
		loggedIn: false,
		error: true,
		loading: false,
		invalidCredentials: false,
		blockedAccount: false,
		maxNumberOfSessions: false,
		resetLinkSuccess: false,
		passwordResented: false
	})),
	on(PasswordResetActions.passwordResetSuccess, () => ({
		loggedIn: false,
		error: false,
		loading: false,
		invalidCredentials: false,
		blockedAccount: false,
		maxNumberOfSessions: false,
		resetLinkSuccess: false,
		passwordResented: true
	}))
);
