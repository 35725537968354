import { createAction, props } from '@ngrx/store';
import { CollaboratorDetail } from '../../models/collaborator-detail.model';

export const initCollaboratorDetail = createAction(
	'[Collaborator] Init Detail',
	props<{
		id: string;
	}>()
);
export const loadCollaboratorDetail = createAction(
	'[Collaborator] Load Detail',
	props<{
		id: string;
	}>()
);

export const loadCollaboratorDetailSuccess = createAction(
	'[Collaborator] Load success Detail',
	props<{
		collaborator: CollaboratorDetail;
	}>()
);
export const loadCollaboratorDetailFailed = createAction('[Collaborator] Load Detail failed');
