import { createAction, props } from '@ngrx/store';
import { PermissionV2 } from '@core/models/roles/permissionV2.model';
import { ServerError } from '@core/models/server-error.model';

export const initAssignRolesV2 = createAction(
	'[RolesV2 Assign] Init ',
	props<{ userId: string; roleId: string; permissions: PermissionV2[] }>()
);
export const assignRolesV2 = createAction(
	'[RolesV2 Assign] Assign',
	props<{ userId: string; roleId: string; permissions: PermissionV2[] }>()
);
export const assignRolesSuccessV2 = createAction(
	'[RolesV2 Assign] Success',
	props<{ userId: string; roleId: string; permissions: PermissionV2[] }>()
);
export const assignRolesFailedV2 = createAction('[RolesV2 Assign] Failed', props<{ error: ServerError }>());
