import { Injectable } from '@angular/core';
import { EStorageKey } from './storage-key.enum';

@Injectable({ providedIn: 'root' })
export class LocalStorageService {
	public setItem<T>(key: EStorageKey, data: string | JSON | T): void {
		const jsonData = JSON.stringify(data);
		localStorage.setItem(key, jsonData);
	}

	public getItem<T>(key: EStorageKey): T | null {
		const jsonData = localStorage.getItem(key);
		if (!jsonData) {
			return null;
		}

		const data = JSON.parse(jsonData);
		return data as T;
	}

	public updateItem<T>(key: EStorageKey, data: T) {
		this.removeItem(key);
		this.setItem<T>(key, data);
	}

	public removeItem(key: EStorageKey): void {
		localStorage.removeItem(key);
	}

	public clear(): void {
		localStorage.clear();
	}
}
