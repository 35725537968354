import { createAction, props } from '@ngrx/store';
import { Category } from '@core/models/category.model';
import { EFeatureType } from '@core/models/enums/feature-type.enum';

export const initCategories = createAction('[Categories] Init', props<{ categoryType: EFeatureType }>());
export const loadCategories = createAction('[Categories] Load', props<{ categoryType: EFeatureType }>());
export const loadCategoriesSuccess = createAction(
	'[Categories] Load success',
	props<{
		categories: Category[];
	}>()
);
export const loadCategoriesFailed = createAction('[Categories] Load failed');
