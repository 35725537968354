<mat-toolbar class="toolbar" color="primary">
	<button mat-icon-button (click)="sidenavOpened = !sidenavOpened">
		<mat-icon>menu</mat-icon>
	</button>
	<span class="hidden sm:flex"> {{ 'PDM.APP_NAME'| transloco }} - v{{ version }}</span>
	<span style="flex: 1 1 auto"></span>
	<pdm-profile-button
		[attr.data-cy]="'profile_btn'"
		[userProfileColor]="userProfileColor"
		[userInitials]="(userInitials$ | async) ?? ''"
		[matMenuTriggerFor]="menu">
		<mat-menu class="test" #menu="matMenu">
			<pdm-profile-button
				class="flex flex-col flex-nowrap content-center items-center"
				[userProfileColor]="userProfileColor"
				[userInitials]="(userInitials$ | async) ?? ''">
				<div class="flex flex-col justify-center items-center flex-nowrap font-medium pt-3 pb-3">
					<span>{{ userCompleteName$ | async }}</span>
					<span>({{ userEmail$ | async }})</span>
				</div>
			</pdm-profile-button>
			<a *ngFor="let item of profileMenuItems; let i = index; trackBy: trackByFn"
			   class="text-base sidenav-link-item"
			   [routerLink]="item.url"
			   [queryParams]="item?.queryParams"
			   [routerLinkActive]="'text-[#3f51b5] is-selected'" [title]="item.url">
				<div class="flex flex-row items-center gap-3 p-4 hover:bg-black/[.05]">
					<mat-icon matPrefix>{{ item.icon }}</mat-icon>
					{{ 'PDM.MENU_ITEM.' + item.label | uppercase| transloco }}
				</div>
			</a>
			<div class="flex flex-row items-center gap-3 p-4 hover:bg-black/[.05] cursor-pointer" (click)="logout()">
				<mat-icon matPrefix>logout</mat-icon>
				{{ 'PDM.MENU_ITEM.SIGN_OUT'| transloco }}
			</div>
		</mat-menu>
	</pdm-profile-button>
</mat-toolbar>
<mat-sidenav-container>
	<mat-sidenav
		class="w-full sm:w-60" [fixedInViewport]="mobileQuery.matches"
		[style.margin-top.px]="mobileQuery.matches ? 56 : 0"
		[mode]="mobileQuery.matches ? 'over' : 'side'"
		[opened]="mobileQuery.matches ? !sidenavOpened : sidenavOpened">
		<ng-container *ngFor="let item of sidebarItems; let i = index; trackBy: trackByFn">
			<a class="text-base sidenav-link-item"
			   *pdmHasPermission="item.permissions"
			   [routerLink]="item.url"
			   [queryParams]="item?.queryParams"
			   [routerLinkActive]="'text-[#235789] font-medium is-active'"
			   (click)="mobileQuery.matches ? sidenavOpened = !sidenavOpened : ''"
			   [title]="item.url">
				<div class="flex flex-row items-center gap-3 p-4 hover:bg-black/[.05]">
					<mat-icon color="primary">
						{{ item.icon }}
					</mat-icon>
					{{ 'PDM.MENU_ITEM.' + item.label | uppercase| transloco }}
				</div>
			</a>
			<div *ngIf="item.hasDivider" class="border border-gray-300"></div>
		</ng-container>
	</mat-sidenav>
	<mat-sidenav-content>
		<mat-progress-bar *ngIf="isLoading$ | async" mode="indeterminate" color="warn"></mat-progress-bar>
		<pdm-main-container>
			<router-outlet></router-outlet>
		</pdm-main-container>
	</mat-sidenav-content>
</mat-sidenav-container>
