import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { PaginatedServerResponse } from '@core/models/miscellaneous/server-response.model';
import { Group, GroupPatch } from '../models/group.model';

@Injectable()
export class GroupsService {
	private static readonly BASE_URL = 'v1/groups';

	constructor(private readonly http: HttpClient) {}

	public getGroups(params: Record<string, string | boolean>): Observable<PaginatedServerResponse<Group[]>> {
		return this.http.get<PaginatedServerResponse<Group[]>>(GroupsService.BASE_URL, { params });
	}

	public patchGroup(id: string, group: GroupPatch): Observable<Group> {
		return this.http.patch<Group>(`${GroupsService.BASE_URL}/${id}`, group);
	}
}
