import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { PageEvent } from '@angular/material/paginator';
import { Observable } from 'rxjs';
import { PaginatedServerResponse, ServerResponse } from '@core/models/miscellaneous/server-response.model';
import { Politician, ServerPolitician } from '../models/politician.model';
import { EPoliticianType } from '../models/enums/politician-type.enum';
import { map } from 'rxjs/operators';
import { DateTime } from 'luxon';
import { Person } from '@core/models/person.model';

@Injectable()
export class PoliticiansService {
	private static readonly BASE_URL = 'v1/politicians';

	constructor(private readonly http: HttpClient) {}

	public getPoliticians(
		politicianType: EPoliticianType,
		pageEvent: PageEvent,
		search: string | null
	): Observable<PaginatedServerResponse<Politician[]>> {
		let params: HttpParams = new HttpParams().set('limit', 10).set('offset', 0);
		if (politicianType !== EPoliticianType.ALL) {
			params = params.set('type', politicianType);
		}
		params = params.set('limit', pageEvent.pageSize);
		params = params.set('offset', (pageEvent.pageIndex ?? 0) * pageEvent.pageSize);

		if (search) {
			params = params.set('search', search);
		}

		return this.http.get<PaginatedServerResponse<ServerPolitician[]>>(PoliticiansService.BASE_URL, { params }).pipe(
			map((response: PaginatedServerResponse<ServerPolitician[]>) => ({
				...response,
				results: response.results.map(politician => ({
					...politician,
					birthDate: DateTime.fromISO(politician.birthDate)
				}))
			}))
		);
	}

	public getSimplePoliticians(): Observable<ServerResponse<Person[]>> {
		return this.http.get<ServerResponse<Person[]>>(`${PoliticiansService.BASE_URL}/names`).pipe(map(response => response));
	}

	public getPolitician(id: string): Observable<Politician> {
		return this.http.get<ServerPolitician>(`${PoliticiansService.BASE_URL}/${id}`).pipe(map(this.mapData()));
	}

	private mapData() {
		return (response: ServerPolitician) => ({
			...response,
			birthDate: DateTime.fromISO(response.birthDate)
		});
	}
}
