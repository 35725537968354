import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import * as ActivitiesV2DetailActions from './activities-V2-detail.actions';
import { of, switchMap } from 'rxjs';
import * as LoadingActions from '@core/store/actions/loading.actions';
import { catchError, map, tap } from 'rxjs/operators';
import { NotificationService } from '@core/services/notification.service';
import { ActivitiesV2Service } from '../../services/activities-V2.service';

@Injectable()
export class ActivitiesV2DetailEffects {
	init$ = createEffect(() =>
		this.actions$.pipe(
			ofType(ActivitiesV2DetailActions.initActivityV2Detail),
			switchMap(({ id }) => [LoadingActions.startLoading(), ActivitiesV2DetailActions.loadActivityV2Detail({ id })])
		)
	);

	load$ = createEffect(() =>
		this.actions$.pipe(
			ofType(ActivitiesV2DetailActions.loadActivityV2Detail),
			switchMap(({ id }) =>
				this.activityService.getActivityById(id).pipe(
					map(result =>
						ActivitiesV2DetailActions.loadActivityV2DetailSuccess({
							activity: result
						})
					),
					catchError(() => of(ActivitiesV2DetailActions.loadActivityV2DetailFailed()))
				)
			)
		)
	);

	loadAllFailed$ = createEffect(
		() =>
			this.actions$.pipe(
				ofType(ActivitiesV2DetailActions.loadActivityV2DetailFailed),
				tap(() => this.notificationService.error('PDM.GENERICS.ERROR.LOAD_DATA_ERROR'))
			),
		{ dispatch: false }
	);

	stopLoading$ = createEffect(() =>
		this.actions$.pipe(
			ofType(ActivitiesV2DetailActions.loadActivityV2DetailSuccess, ActivitiesV2DetailActions.loadActivityV2DetailFailed),
			switchMap(() => [LoadingActions.stopLoading()])
		)
	);

	constructor(
		private readonly actions$: Actions,
		private readonly activityService: ActivitiesV2Service,
		private readonly notificationService: NotificationService
	) {}
}
