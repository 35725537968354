import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class PasswordResetService {
	private readonly BASE_URL = 'v1';

	constructor(private readonly http: HttpClient) {}

	askResetLink(email: string): Observable<void> {
		return this.http.post(`${this.BASE_URL}/reset-link`, { email }).pipe(map(() => void 0));
	}

	resetPassword(token: string, newPassword: string, newPasswordConfirmation: string): Observable<void> {
		return this.http
			.post(`${this.BASE_URL}/reset-password`, {
				token,
				newPassword,
				newPasswordConfirmation
			})
			.pipe(map(() => void 0));
	}
}
