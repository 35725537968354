import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import * as LegislatureMinistryUpdateActions from './legislature-ministry-update.action';
import { of, switchMap, tap } from 'rxjs';
import * as LoadingActions from '../../../../../../core/store/actions/loading.actions';
import { catchError, map } from 'rxjs/operators';
import { NotificationService } from '@core/services/notification.service';
import { LegislaturesService } from '../../../../services/legislatures.service';
import { InteractionService } from '../../../../../../shared/interaction/interaction.service';
import { ELegislaturesErrorCode } from '@legislatures/models/enum/legislatures-error-code.enum';
import { showDefaultError } from '@core/utils/miscellaneous.utils';
import { TranslocoService } from '@ngneat/transloco';

@Injectable()
export class LegislatureMinistryUpdateEffects {
	init$ = createEffect(() =>
		this.actions$.pipe(
			ofType(LegislatureMinistryUpdateActions.initUpdateMinistries),
			switchMap(({ legislatureId, ministries }) => [
				LoadingActions.startLoading(),
				LegislatureMinistryUpdateActions.updateMinistries({ legislatureId, ministries })
			])
		)
	);

	updateMinistries$ = createEffect(() =>
		this.actions$.pipe(
			ofType(LegislatureMinistryUpdateActions.updateMinistries),
			switchMap(({ legislatureId, ministries }) =>
				this.legislaturesService.updateMinistries(legislatureId, ministries).pipe(
					map(legislatureDetail => LegislatureMinistryUpdateActions.updateMinistriesSuccess({ legislatureDetail })),
					catchError(error => of(LegislatureMinistryUpdateActions.updateMinistriesFailed({ error })))
				)
			)
		)
	);

	updateMinistriesSuccess$ = createEffect(
		() =>
			this.actions$.pipe(
				ofType(LegislatureMinistryUpdateActions.updateMinistriesSuccess),
				tap(() => this.notificationService.success('legislatures.detail.success.update'))
			),
		{ dispatch: false }
	);

	updateMinistriesFailed$ = createEffect(() =>
		this.actions$.pipe(
			ofType(LegislatureMinistryUpdateActions.updateMinistriesFailed),
			switchMap(({ error }) => {
				if (error.internalCode in ELegislaturesErrorCode) {
					this.interactionService.confirm(
						`legislatures.errors.${error.internalCode}.title`,
						this.translateService.translate(`legislatures.errors.${error.internalCode}.message`),
						'PDM.GENERICS.BUTTON.OK',
						'error'
					);
				} else {
					showDefaultError(error, this.notificationService, this.interactionService);
				}

				return [LoadingActions.stopLoading()];
			})
		)
	);

	stopLoading$ = createEffect(() =>
		this.actions$.pipe(
			ofType(LegislatureMinistryUpdateActions.updateMinistriesSuccess, LegislatureMinistryUpdateActions.updateMinistriesFailed),
			switchMap(() => [LoadingActions.stopLoading()])
		)
	);

	constructor(
		private readonly actions$: Actions,
		private readonly interactionService: InteractionService,
		private readonly legislaturesService: LegislaturesService,
		private readonly translateService: TranslocoService,
		private readonly notificationService: NotificationService
	) {}
}
