import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { exhaustMap, of, switchMap } from 'rxjs';
import * as InternalNoteActions from '../actions/internal-note-actions';
import * as LoadingActions from '../actions/loading.actions';
import { catchError, map } from 'rxjs/operators';
import { InternalNoteService } from '@core/services/internal-note.service';

@Injectable()
export class InternalNoteEffects {
	initLoadNote$ = createEffect(() =>
		this.actions$.pipe(
			ofType(InternalNoteActions.initLoadNote),
			switchMap(({ entityId, feature }) => [
				LoadingActions.startLoading(),
				InternalNoteActions.load({
					entityId,
					feature
				})
			])
		)
	);

	loadNotes$ = createEffect(() =>
		this.actions$.pipe(
			ofType(InternalNoteActions.load),
			exhaustMap(({ entityId, feature }) =>
				this.internalNoteService.list(entityId, feature).pipe(
					map(notes => InternalNoteActions.loadSuccess({ notes })),
					catchError(() => of(InternalNoteActions.loadFailed))
				)
			)
		)
	);

	stopLoading$ = createEffect(() =>
		this.actions$.pipe(
			ofType(InternalNoteActions.loadSuccess, InternalNoteActions.loadFailed),
			switchMap(() => [LoadingActions.stopLoading()])
		)
	);

	constructor(private readonly actions$: Actions, private readonly internalNoteService: InternalNoteService) {}
}
