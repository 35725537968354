import { inject, Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { PaginatedServerResponse } from '@core/models/miscellaneous/server-response.model';
import { catchError, map } from 'rxjs/operators';
import { DateTime } from 'luxon';
import { ArticleResponse, ServerArticleResponse } from '../models/article.model';
import { EResourcePath, resourcePathLocator } from '../../../resource-path.locator';
import { SelectFilter, TextFilter } from '@core/models/filters/filter.model';
import { ArticlesFilters } from '../models/articles-filter.model';
import { EArticleStatus } from '../models/enum/article-status.enum';
import { ArticleV2Detail, ServerArticleV2Detail } from '../models/article-v2-detail.model';
import { ServerError } from '@core/models/server-error.model';
import { ArticleV2Create } from '../models/article-V2-create.model';

@Injectable()
export class ArticlesV2Service {
	private readonly http = inject(HttpClient);

	public getFilters(filters: ArticlesFilters) {
		return [
			new TextFilter({
				key: 'author',
				value: filters.author,
				label: 'articles.list.filters.author'
			}),
			new TextFilter({
				key: 'category',
				value: filters.category,
				label: 'articles.list.filters.category'
			}),
			new SelectFilter({
				key: 'status',
				label: 'articles.list.filters.status',
				value: filters.status,
				options: EArticleStatus.values().map(status => ({
					key: status,
					label: `articles.status.${status}`
				}))
			})
		];
	}

	public listArticles(params: Record<string, string | boolean>): Observable<PaginatedServerResponse<ArticleResponse[]>> {
		return this.http
			.get<PaginatedServerResponse<ServerArticleResponse[]>>(`${resourcePathLocator[EResourcePath.ARTICLES]}`, { params })
			.pipe(
				map(response => ({
					...response,
					results: response.results.map(article => ({
						...article,
						createdAt: article.createdAt ? DateTime.fromISO(article.createdAt) : null,
						updatedAt: article.updatedAt ? DateTime.fromISO(article.updatedAt) : null,
						publishedAt: article.publishedAt ? DateTime.fromISO(article.publishedAt) : null
					}))
				}))
			);
	}

	public getArticleById(id: string): Observable<ArticleV2Detail> {
		return this.http.get<ServerArticleV2Detail>(`${resourcePathLocator[EResourcePath.ARTICLES]}/${id}`).pipe(map(this.mapData()));
	}

	public createArticle(article: Partial<ArticleV2Create>): Observable<ArticleV2Detail> {
		const articleToSave = Object.fromEntries(
			Object.entries(article).filter(([_, value]) => !!value && (value as Array<unknown>).length > 0)
		);
		return this.http.post<ServerArticleV2Detail>(resourcePathLocator[EResourcePath.ARTICLES], articleToSave).pipe(
			map(this.mapData()),
			catchError(err => {
				const { error } = err as HttpErrorResponse;
				return [404, 409].includes((error as ServerError).statusCode) ? throwError(error as ServerError) : throwError(err);
			})
		);
	}

	public patchArticle(id: string, article: Partial<ArticleV2Create>): Observable<ArticleV2Detail> {
		return this.http.patch<ServerArticleV2Detail>(`${resourcePathLocator[EResourcePath.ARTICLES]}/${id}`, article).pipe(
			map(this.mapData()),
			catchError(err => {
				const { error } = err as HttpErrorResponse;
				return (error as ServerError).statusCode === 409 ? throwError(error as ServerError) : throwError(err);
			})
		);
	}

	public deleteArticle(id: string): Observable<void> {
		return this.http.delete(`${resourcePathLocator[EResourcePath.ARTICLES]}/${id}`).pipe(
			map(() => void 0),
			catchError(err => {
				const { error } = err as HttpErrorResponse;
				return [400, 404, 409].includes((error as ServerError).statusCode) ? throwError(error as ServerError) : throwError(err);
			})
		);
	}

	public submitToReview(id: string, message?: string): Observable<ArticleV2Detail> {
		return this.http
			.post<ServerArticleV2Detail>(`${resourcePathLocator[EResourcePath.ARTICLES]}/${id}/review`, { note: message ?? undefined })
			.pipe(
				map(this.mapData()),
				catchError(err => {
					const { error } = err as HttpErrorResponse;
					return [400, 404, 409].includes((error as ServerError).statusCode) ? throwError(error as ServerError) : throwError(err);
				})
			);
	}

	public approve(id: string, publicationDateTime: string, message?: string): Observable<ArticleV2Detail> {
		return this.http
			.post<ServerArticleV2Detail>(`${resourcePathLocator[EResourcePath.ARTICLES]}/${id}/approve`, {
				note: message ?? undefined,
				publicationDate: publicationDateTime
			})
			.pipe(
				map(this.mapData()),
				catchError(err => {
					const { error } = err as HttpErrorResponse;
					return [400, 404, 409].includes((error as ServerError).statusCode) ? throwError(error as ServerError) : throwError(err);
				})
			);
	}

	public reject(id: string, message?: string): Observable<ArticleV2Detail> {
		return this.http
			.post<ServerArticleV2Detail>(`${resourcePathLocator[EResourcePath.ARTICLES]}/${id}/rework`, { note: message ?? undefined })
			.pipe(
				map(this.mapData()),
				catchError(err => {
					const { error } = err as HttpErrorResponse;
					return [404, 409].includes((error as ServerError).statusCode) ? throwError(error as ServerError) : throwError(err);
				})
			);
	}

	public publish(id: string, message?: string): Observable<ArticleV2Detail> {
		return this.http
			.post<ServerArticleV2Detail>(`${resourcePathLocator[EResourcePath.ARTICLES]}/${id}/publish`, { note: message ?? undefined })
			.pipe(
				map(this.mapData()),
				catchError(err => {
					const { error } = err as HttpErrorResponse;
					return [404, 409].includes((error as ServerError).statusCode) ? throwError(error as ServerError) : throwError(err);
				})
			);
	}

	public cancel(id: string, cancellationReason: string): Observable<ArticleV2Detail> {
		return this.http
			.post<ServerArticleV2Detail>(`${resourcePathLocator[EResourcePath.ARTICLES]}/${id}/remove`, { cancellationReason })
			.pipe(
				map(this.mapData()),
				catchError(err => {
					const { error } = err as HttpErrorResponse;
					return [404, 409].includes((error as ServerError).statusCode) ? throwError(error as ServerError) : throwError(err);
				})
			);
	}

	public archive(id: string, message?: string): Observable<ArticleV2Detail> {
		return this.http
			.post<ServerArticleV2Detail>(`${resourcePathLocator[EResourcePath.ARTICLES]}/${id}/archive`, { note: message ?? undefined })
			.pipe(
				map(this.mapData()),
				catchError(err => {
					const { error } = err as HttpErrorResponse;
					return [404, 409].includes((error as ServerError).statusCode) ? throwError(error as ServerError) : throwError(err);
				})
			);
	}

	private mapData() {
		return (response: ServerArticleV2Detail) => ({
			...response,
			status: <EArticleStatus>EArticleStatus[response.status as keyof typeof EArticleStatus],
			createdAt: DateTime.fromISO(response.createdAt),
			updatedAt: DateTime.fromISO(response.updatedAt),
			publishedAt: response.publishedAt.length ? DateTime.fromISO(response.publishedAt) : null
		});
	}
}
