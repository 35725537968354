import { createAction, props } from '@ngrx/store';
import { ServerError } from '@core/models/server-error.model';
import { ArticleV2Detail } from '../../models/article-v2-detail.model';
import { ArticleV2Create } from '../../models/article-V2-create.model';

export const initPatchArticle = createAction('[Articles V2] Patch Init', props<{ id: string; article: Partial<ArticleV2Create> }>());
export const initPatchArticleAndFireAskReview = createAction(
	'[Articles V2] Patch and fire Ask Review Init',
	props<{ id: string; article: Partial<ArticleV2Create>; message?: string }>()
);
export const patchArticle = createAction('[Articles V2] Patch', props<{ id: string; article: Partial<ArticleV2Create> }>());
export const patchArticleAndFireAskReview = createAction(
	'[Articles V2] Patch and fire Ask Review',
	props<{ id: string; article: Partial<ArticleV2Create>; message?: string }>()
);
export const patchArticleSuccess = createAction('[Articles] Patch success', props<{ article: ArticleV2Detail }>());
export const patchArticleFailed = createAction('[Articles] Patch failed', props<{ error: ServerError }>());
