import { createReducer, on } from '@ngrx/store';
import * as ProfileActions from './profile.actions';
import * as AuthActions from '../../auth/store/auth.actions';
import * as AssignRoleActions from '../../store/actions/assign-role.actions';
import * as RolesAndPermissionsV2UpdateAction from '../../../feature/administration/roles-and-permissions-v2/store/update/roles-and-permissions-v2-update.actions';
import { AuthenticatedUser } from '../models/authenticated-user.model';
import { HttpErrorActions } from '../../store/actions';
import * as RoleUnlinkPermissionsActions from '../../../feature/administration/roles-and-permissions-v2/store/unlink-permission/roles-and-permissions-v2-unlink-permission.action';

export interface ProfileState {
	user: AuthenticatedUser;
	error: boolean;
}

export const initialState: ProfileState = {
	user: {} as AuthenticatedUser,
	error: false
};

export const profileReducer = createReducer(
	initialState,
	on(ProfileActions.loadMeSuccess, (_, action) => ({
		user: { ...action.user },
		error: false
	})),
	on(ProfileActions.loadMeFailed, () => ({
		error: true,
		user: {} as AuthenticatedUser
	})),
	on(AuthActions.logoutSuccess, () => ({
		user: {} as AuthenticatedUser,
		error: false
	})),
	on(HttpErrorActions.httpErrorUnauthorized, state => ({
		...state,
		user: {} as AuthenticatedUser,
		error: false
	})),
	on(RoleUnlinkPermissionsActions.unlinkPermissionsSuccess, (state, action) => ({
		error: false,
		user: {
			...state.user,
			permissions:
				state.user.roleId !== action.roleId
					? [...state.user.permissions]
					: state.user.permissions.filter(
							userPermission =>
								!action.permissions
									.map(
										permission =>
											`${permission.resource}:${permission.action}${
												permission.ownership ? `:${permission.ownership}` : ''
											}`
									)
									.includes(userPermission)
					  )
		}
	})),
	on(RolesAndPermissionsV2UpdateAction.updateRolesAndPermissionsV2Success, (state, action) => ({
		error: false,
		user: {
			...state.user,
			permissions:
				state.user.roleId === action.role.id
					? action.role.permissions.map(
							permission =>
								`${permission.resource}:${permission.action}${permission.ownership ? `:${permission.ownership}` : ''}`
					  )
					: state.user.permissions
		}
	})),
	on(AssignRoleActions.assignRolesSuccessV2, (state, action) => ({
		...state,
		user: {
			...state.user,
			permissions:
				state.user.id === action.userId
					? action.permissions.map(
							permission =>
								`${permission.resource}:${permission.action}${permission.ownership ? `:${permission.ownership}` : ''}`
					  )
					: state.user.permissions
		}
	}))
);
