import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { PaginatedServerResponse } from '@core/models/miscellaneous/server-response.model';
import { Legislature, ServerLegislature } from '../models/legislature.model';
import { catchError, map } from 'rxjs/operators';
import { LegislatureCreate } from '../models/legislature-create.model';
import { LegislatureDetail, ServerLegislatureDetail } from '../models/legislature-detail.model';
import { DateTime } from 'luxon';
import { ServerError } from '@core/models/server-error.model';
import { MinistryAdd } from '../models/ministry-add.model';

@Injectable()
export class LegislaturesService {
	private static readonly BASE_URL = 'v1/legislatures';

	constructor(private readonly http: HttpClient) {}

	public getMinistries(params: Record<string, string | boolean>): Observable<PaginatedServerResponse<Legislature[]>> {
		return this.http.get<PaginatedServerResponse<ServerLegislature[]>>(LegislaturesService.BASE_URL, { params }).pipe(
			map((response: PaginatedServerResponse<ServerLegislature[]>) => ({
				...response,
				results: response.results.map(legislature => ({
					...legislature,
					startDate: legislature.startDate ? DateTime.fromISO(legislature.startDate) : null,
					endDate: legislature.endDate ? DateTime.fromISO(legislature.endDate) : null
				}))
			}))
		);
	}

	public deleteLegislature(id: string): Observable<void> {
		return this.http.delete(`${LegislaturesService.BASE_URL}/${id}`).pipe(
			map(() => void 0),
			catchError(err => this.catchError(err, [404]))
		);
	}

	public duplicateLegislature(id: string, name: string): Observable<Legislature> {
		return this.http.post<Legislature>(`${LegislaturesService.BASE_URL}/${id}/duplicate`, { name }).pipe(
			map(legislature => legislature),
			catchError(err => this.catchError(err, [404]))
		);
	}

	public create(data: Partial<LegislatureCreate>): Observable<LegislatureDetail> {
		return this.http.post<ServerLegislatureDetail>(`${LegislaturesService.BASE_URL}`, data).pipe(
			map(this.mapData()),
			catchError(err => this.catchError(err, [409]))
		);
	}

	public patch(id: string, data: Partial<LegislatureCreate>): Observable<LegislatureDetail> {
		return this.http.patch<ServerLegislatureDetail>(`${LegislaturesService.BASE_URL}/${id}`, data).pipe(
			map(this.mapData()),
			catchError(err => this.catchError(err, [404, 409]))
		);
	}

	public getById(id: string): Observable<LegislatureDetail> {
		return this.http.get<ServerLegislatureDetail>(`${LegislaturesService.BASE_URL}/${id}`).pipe(
			map(this.mapData()),
			catchError(err => this.catchError(err, [404]))
		);
	}

	public deleteMinistries(legislatureId: string, ids: string[]): Observable<LegislatureDetail> {
		return this.http.post<ServerLegislatureDetail>(`${LegislaturesService.BASE_URL}/${legislatureId}/remove-ministries`, { ids }).pipe(
			map(this.mapData()),
			catchError(err => this.catchError(err, [404]))
		);
	}

	public addMinistries(legislatureId: string, ministries: MinistryAdd[]): Observable<LegislatureDetail> {
		return this.http
			.post<ServerLegislatureDetail>(`${LegislaturesService.BASE_URL}/${legislatureId}/add-ministries`, { ministries })
			.pipe(
				map(this.mapData()),
				catchError(err => this.catchError(err, [404, 409]))
			);
	}

	public updateMinistries(legislatureId: string, ministries: MinistryAdd[]): Observable<LegislatureDetail> {
		return this.http
			.patch<ServerLegislatureDetail>(`${LegislaturesService.BASE_URL}/${legislatureId}/update-ministries`, { ministries })
			.pipe(
				map(this.mapData()),
				catchError(err => {
					const { error } = err as HttpErrorResponse;
					return [404, 409].includes((error as ServerError).statusCode) ? throwError(error as ServerError) : throwError(err);
				})
			);
	}

	private catchError(err: unknown, httpCodes: number[]) {
		const { error } = err as HttpErrorResponse;
		return httpCodes.includes((error as ServerError).statusCode) ? throwError(error as ServerError) : throwError(err);
	}

	private mapData() {
		return (response: ServerLegislatureDetail) => ({
			...response,
			startDate: response.startDate ? DateTime.fromISO(response.startDate) : null,
			endDate: response.endDate ? DateTime.fromISO(response.endDate) : null
		});
	}
}
