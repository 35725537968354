import { RouterModule, Routes } from '@angular/router';
import { importProvidersFrom, NgModule } from '@angular/core';
import { MainViewComponent } from '@core/components/main-view/main-view.component';
import { PoliticiansService } from './feature/politiciansV2/services/politicians.service';
import { StoreModule } from '@ngrx/store';
import { politiciansV2FeatureKey } from './feature/politiciansV2/store/politiciansV2.selectors';
import { politiciansV2Reducers } from './feature/politiciansV2/store/politiciansV2.reducers';
import { EffectsModule } from '@ngrx/effects';
import { PoliticiansV2Effects } from './feature/politiciansV2/store/politiciansV2.effects';
import { PoliticiansV2DetailEffects } from './feature/politiciansV2/store/detail/politiciansV2-detail.effects';
import { LegislaturesService } from '@legislatures/services/legislatures.service';
import { legislaturesFeatureKey } from '@legislatures/store/legislatures.selectors';
import { legislaturesReducers } from '@legislatures/store/legislatures.reducers';
import { LegislaturesEffects } from '@legislatures/store/legislatures.effects';
import { LegislatureDuplicateEffects } from '@legislatures/store/duplicate/legislature-duplicate.effects';
import { LegislatureDeleteEffects } from '@legislatures/store/delete/legislature-delete.effects';
import { LegislatureCreateEffects } from '@legislatures/store/create/legislature-create.effects';
import { LegislatureDetailEffects } from '@legislatures/store/detail/legislature-detail.effects';
import { legislatureDetailReducers } from '@legislatures/store/detail/legislature-detail.reducers';
import { legislatureDetailFeatureKey } from '@legislatures/store/detail/legislature-detail.selectors';
import { LegislaturePatchEffects } from '@legislatures/store/detail/patch/legislature-patch.effects';
import { CollaboratorsV2Service } from '@collaborators/services/collaborators-v2.service';
import { collaboratorsListFeatureKey } from '@collaborators/store/list/collaborators-list.selectors';
import { collaboratorsListReducers } from '@collaborators/store/list/collaborators-list.reducers';
import { CollaboratorsListEffects } from '@collaborators/store/list/collaborators-list.effects';
import { collaboratorDetailReducers } from '@collaborators/store/detail/collaborator-detail.reducers';
import { CollaboratorDetailEffects } from '@collaborators/store/detail/collaborator-detail.effects';
import { collaboratorDetailFeatureKey } from '@collaborators/store/detail/collaborators-detail.selectors';
import { CollaboratorDangerActionEffects } from '@collaborators/store/detail/danger-actions/collaborator-danger-action.effects';
import { RolesV2Effect } from '@core/store/effects/rolesV2.effects';
import { GroupsService } from '@groups/services/groups.service';
import { groupsListFeatureKey } from '@groups/store/list/groups-list.selectors';
import { groupsListReducers } from '@groups/store/list/groups-list.reducers';
import { GroupsListEffects } from '@groups/store/list/groups-list.effects';
import { GroupsPatchEffects } from '@groups/store/patch/groups-patch.effects';
import { provideTranslocoScope } from '@ngneat/transloco';
import { SessionsService } from '@sessions/services/sessions.service';
import { sessionsFeatureKey } from '@sessions/store/sessions.selectors';
import { sessionsReducer } from '@sessions/store/sessions.reducers';
import { SessionsEffects } from '@sessions/store/sessions.effects';
import { SessionDeleteEffects } from '@sessions/store/delete/session-delete.effects';
import { PoliticiansAutocompleteEffects } from '@legislatures/store/detail/politicians-autocomplete/politicians-autocomplete.effects';
import { LegislaturesMapper } from '@legislatures/mapper/legislatures.mapper';
import { LegislatureMinistryDeleteEffects } from '@legislatures/store/detail/ministries/delete/legislature-ministry-delete.effects';
import { LegislatureMinistryAddEffects } from '@legislatures/store/detail/ministries/add/legislature-ministry-add.effects';
import { LegislatureMinistryUpdateEffects } from '@legislatures/store/detail/ministries/update/legislature-ministry-update.effects';
import { AuthenticationGuard } from '@core/auth/guards/authentication.guard';
import {
	rolesAndPermissionsV2FeatureKey,
	rolesAndPermissionsV2Reducer
} from './feature/administration/roles-and-permissions-v2/store/roles-and-permissions-v2.reducers';
import { RolesAndPermissionsV2ListEffects } from './feature/administration/roles-and-permissions-v2/store/list/roles-and-permissions-v2-list.effects';
import { RolesAndPermissionsV2DeleteRoleEffects } from './feature/administration/roles-and-permissions-v2/store/delete/roles-and-permissions-v2-delete-role.effects';
import { RolesAndPermissionsV2UnlinkPermissionEffects } from './feature/administration/roles-and-permissions-v2/store/unlink-permission/roles-and-permissions-v2-unlink-permission.effects';
import { RolesAndPermissionsV2DetailEffects } from './feature/administration/roles-and-permissions-v2/store/detail/roles-and-permissions-v2-detail.effects';
import { EPageStatus } from '@core/models/enums/page-status.enum';
import { RolesAndPermissionsFormMapperService } from './feature/administration/roles-and-permissions-v2/services/roles-and-permissions-form.mapper.service';
import { RolesAndPermissionsV2UpdateEffects } from './feature/administration/roles-and-permissions-v2/store/update/roles-and-permissions-v2-update.effect';
import { RolesAndPermissionsV2CreateEffects } from './feature/administration/roles-and-permissions-v2/store/create/roles-and-permissions-v2-create.effects';
import { articlesV2FeatureKey, articlesV2Reducer } from './feature/articlesV2/store/articles-v2.reducers';
import { ArticlesV2ListEffects } from './feature/articlesV2/store/list/articles-v2-list.effects';
import { ArticlesV2Service } from './feature/articlesV2/services/articles-v2.service';
import { ArticlesV2DetailEffects } from './feature/articlesV2/store/detail/articles-v2-detail.effects';
import { ArticlesFormMapperService } from './feature/articlesV2/services/articles-V2-form-mapper.service';
import { ArticleDetailPermissionsService } from './feature/articlesV2/services/articles-V2-detail-permissions.service';
import { ArticlesV2DeleteEffects } from './feature/articlesV2/store/delete/articles-V2-delete.effects';
import { ArticlesV2CancelEffects } from './feature/articlesV2/store/cancel/articles-V2-cancel.effects';
import { ArticlesV2ReviewEffects } from './feature/articlesV2/store/review/articles-V2-review.effects';
import { ArticlesV2PatchEffects } from './feature/articlesV2/store/patch/articles-V2-patch.effects';
import { ArticlesV2ArchiveEffects } from './feature/articlesV2/store/archive/article2-V2-archive.effects';
import { ArticlesV2RejectEffects } from './feature/articlesV2/store/reject/articles-V2-reject.effects';
import { ArticlesV2PublishEffects } from './feature/articlesV2/store/publish/articles-V2-publish.effects';
import { ArticlesV2ApproveEffects } from './feature/articlesV2/store/approve/articles-V2-approve.effects';
import { ArticlesV2CreateEffects } from './feature/articlesV2/store/create/articles-V2-create.effects';
import { CategoriesEffects } from '@core/store/effects/categories.effects';
import { ActivitiesV2Service } from './feature/activitiesV2/services/activities-V2.service';
import { activitiesV2FeatureKey, activitiesV2Reducer } from './feature/activitiesV2/store/activities-V2.reducers';
import { ActivitiesV2ListEffects } from './feature/activitiesV2/store/list/activities-V2-list.effects';
import { ActivitiesV2DetailEffects } from './feature/activitiesV2/store/detail/activities-V2-detail.effects';
import { ActivitiesV2FormMapperService } from './feature/activitiesV2/services/activities-V2-form.mapper.service';
import { ActivitiesV2DetailPermissionsService } from './feature/activitiesV2/services/activities-V2-detail-permissions.service';
import { ActivitiesV2DeleteEffects } from './feature/activitiesV2/store/delete/activities-V2-delete.effects';
import { ActivitiesV2CancelEffects } from './feature/activitiesV2/store/cancel/activities-V2-cancel.effects';
import { ActivitiesV2ReviewEffects } from './feature/activitiesV2/store/review/activities-V2-review.effects';
import { ActivitiesV2PatchEffects } from './feature/activitiesV2/store/patch/activities-V2-patch.effects';
import { ActivitiesV2ApproveEffects } from './feature/activitiesV2/store/approve/activities-V2-approve.effects';
import { ActivitiesV2RejectEffects } from './feature/activitiesV2/store/reject/activities-V2-reject.effects';
import { ActivitiesV2ArchiveEffects } from './feature/activitiesV2/store/archive/activities-V2-archive.effects';
import { ActivitiesV2PublishEffects } from './feature/activitiesV2/store/publish/activities-V2-publish.effects';
import { ActivitiesV2CreateEffects } from './feature/activitiesV2/store/create/activities-V2-create.effects';
import { canDeactivateGuard } from './guards/can-component-deactivate.guard';

const routes: Routes = [
	{
		path: 'login',
		loadChildren: () => import('./feature/login/login.module').then(m => m.LoginModule)
	},
	{
		path: 'reset-password',
		loadChildren: () => import('./feature/password-reset/password-reset.module').then(m => m.PasswordResetModule)
	},
	{
		path: '',
		component: MainViewComponent,
		canActivate: [AuthenticationGuard],
		title: 'Dashboard',
		children: [
			{
				path: 'account',
				loadChildren: () => import('./feature/account/account.module').then(m => m.AccountModule)
			},
			{
				path: 'sessioni',
				// loadChildren: () => import('./feature/sessions/sessions.module').then(m => m.SessionsModule)
				loadComponent: () => import('./feature/sessionV2/sessions.component').then(c => c.SessionsComponent),
				title: 'Le mie sessioni attive',
				providers: [
					provideTranslocoScope('sessions'),
					SessionsService,
					importProvidersFrom(
						StoreModule.forFeature(sessionsFeatureKey, sessionsReducer),
						EffectsModule.forFeature([SessionsEffects, SessionDeleteEffects])
					)
				]
			},
			{
				path: 'amministrazione',
				loadComponent: () =>
					import('./feature/administration/administration-page/administration-page.component').then(
						c => c.AdministrationPageComponent
					),
				title: 'Amministrazione',
				children: [
					{
						path: 'collaboratori',
						loadComponent: () =>
							import('./feature/administration/collaborators-v2/collaborators-page/collaborators-page.component').then(
								c => c.CollaboratorsPageComponent
							),
						providers: [CollaboratorsV2Service, provideTranslocoScope('collaborators')],
						children: [
							{
								path: '',
								title: 'Amministrazione collaboratori',
								loadComponent: () =>
									import(
										'./feature/administration/collaborators-v2/collaborators-list-page/collaborators-list.component'
									).then(c => c.CollaboratorsListComponent),
								providers: [
									importProvidersFrom(
										StoreModule.forFeature(collaboratorsListFeatureKey, collaboratorsListReducers),
										EffectsModule.forFeature([CollaboratorsListEffects])
									)
								]
							},
							{
								path: ':id',
								title: 'Dettaglio collaboratore',
								loadComponent: () =>
									import(
										'./feature/administration/collaborators-v2/collaborators-detail/collaborators-detail.component'
									).then(c => c.CollaboratorsDetailComponent),
								providers: [
									importProvidersFrom(
										StoreModule.forFeature(collaboratorDetailFeatureKey, collaboratorDetailReducers),
										EffectsModule.forFeature([
											CollaboratorDetailEffects,
											CollaboratorDangerActionEffects,
											RolesV2Effect
										])
									)
								]
							}
						]
					},
					{
						path: 'ruoli-e-permessi',
						title: 'Ruoli e permessi',
						loadComponent: () =>
							import('./feature/administration/roles-and-permissions-v2/page/roles-and-permissions-v2-page.component').then(
								c => c.RolesAndPermissionsV2PageComponent
							),
						providers: [
							RolesAndPermissionsFormMapperService,
							provideTranslocoScope('rolesAndPermissions'),
							importProvidersFrom(
								StoreModule.forFeature(rolesAndPermissionsV2FeatureKey, rolesAndPermissionsV2Reducer),
								EffectsModule.forFeature([
									RolesAndPermissionsV2ListEffects,
									RolesAndPermissionsV2DeleteRoleEffects,
									RolesAndPermissionsV2UnlinkPermissionEffects,
									RolesAndPermissionsV2DetailEffects
								])
							)
						],
						children: [
							{
								path: '',
								title: 'Ruoli e permessi',
								loadComponent: () =>
									import(
										'./feature/administration/roles-and-permissions-v2/list/roles-and-permissions-v2-list.component'
									).then(c => c.RolesAndPermissionsV2ListComponent)
							},
							{
								path: ':id/modifica',
								title: 'Modifica ruolo',
								loadComponent: () =>
									import(
										'./feature/administration/roles-and-permissions-v2/detail/roles-and-permissions-v2-edit.component'
									).then(c => c.RolesAndPermissionsV2EditComponent),
								data: {
									formStatus: EPageStatus.DETAIL
								},
								providers: [importProvidersFrom(EffectsModule.forFeature([RolesAndPermissionsV2UpdateEffects]))]
							},
							{
								path: 'nuovo-ruolo',
								title: 'Creazione ruolo',
								loadComponent: () =>
									import(
										'./feature/administration/roles-and-permissions-v2/detail/roles-and-permissions-v2-edit.component'
									).then(c => c.RolesAndPermissionsV2EditComponent),
								data: {
									formStatus: EPageStatus.NEW
								},
								providers: [importProvidersFrom(EffectsModule.forFeature([RolesAndPermissionsV2CreateEffects]))]
							}
						]
					}
				]
			},
			{
				path: 'articoli',
				loadComponent: () =>
					import('./feature/articlesV2/articles-v2-page/articles-v2-page.component').then(c => c.ArticlesV2PageComponent),
				providers: [
					provideTranslocoScope('articles'),
					ArticlesV2Service,
					ArticlesFormMapperService,
					ArticleDetailPermissionsService,
					importProvidersFrom(StoreModule.forFeature(articlesV2FeatureKey, articlesV2Reducer))
				],
				children: [
					{
						path: '',
						title: 'Articoli',
						loadComponent: () =>
							import('./feature/articlesV2/articles-v2-list/articles-v2-list.component').then(c => c.ArticlesV2ListComponent),
						providers: [importProvidersFrom(EffectsModule.forFeature([ArticlesV2ListEffects]))]
					},
					{
						path: ':id/modifica',
						title: 'Dettaglio articolo',
						loadComponent: () =>
							import('./feature/articlesV2/articles-v2-detail/articles-v2-detail.component').then(
								c => c.ArticlesV2DetailComponent
							),
						data: {
							formStatus: EPageStatus.DETAIL
						},
						providers: [
							importProvidersFrom(
								EffectsModule.forFeature([
									ArticlesV2DetailEffects,
									ArticlesV2DeleteEffects,
									ArticlesV2CancelEffects,
									ArticlesV2ReviewEffects,
									ArticlesV2PatchEffects,
									ArticlesV2ArchiveEffects,
									ArticlesV2RejectEffects,
									ArticlesV2PublishEffects,
									ArticlesV2ApproveEffects,
									ArticlesV2CreateEffects,
									CategoriesEffects
								])
							)
						],
						canDeactivate: [canDeactivateGuard]
					},
					{
						path: 'nuovo-articolo',
						title: 'Crea articolo',
						loadComponent: () =>
							import('./feature/articlesV2/articles-v2-detail/articles-v2-detail.component').then(
								c => c.ArticlesV2DetailComponent
							),
						data: {
							formStatus: EPageStatus.NEW
						},
						providers: [importProvidersFrom(EffectsModule.forFeature([CategoriesEffects, ArticlesV2CreateEffects]))]
					}
				]
			},
			{
				path: 'attivita',
				loadComponent: () =>
					import('./feature/activitiesV2/activities-V2-page/activities-v2-page.component').then(c => c.ActivitiesV2PageComponent),
				providers: [
					provideTranslocoScope('activities'),
					importProvidersFrom(StoreModule.forFeature(activitiesV2FeatureKey, activitiesV2Reducer)),
					ActivitiesV2Service,
					ActivitiesV2FormMapperService,
					ActivitiesV2DetailPermissionsService
				],
				children: [
					{
						path: '',
						title: 'Attività',
						loadComponent: () =>
							import('./feature/activitiesV2/activities-V2-list/activities-v2-list.component').then(
								c => c.ActivitiesV2ListComponent
							),
						providers: [importProvidersFrom(EffectsModule.forFeature([ActivitiesV2ListEffects]))]
					},
					{
						path: ':id/modifica',
						title: 'Modifica attività',
						loadComponent: () =>
							import('./feature/activitiesV2/activities-V2-detail/activities-v2-detail.component').then(
								c => c.ActivitiesV2DetailComponent
							),
						providers: [
							importProvidersFrom(
								EffectsModule.forFeature([
									ActivitiesV2DetailEffects,
									CategoriesEffects,
									ActivitiesV2DeleteEffects,
									ActivitiesV2CancelEffects,
									ActivitiesV2ReviewEffects,
									ActivitiesV2PatchEffects,
									ActivitiesV2ApproveEffects,
									ActivitiesV2RejectEffects,
									ActivitiesV2ArchiveEffects,
									ActivitiesV2PublishEffects,
									ActivitiesV2CreateEffects
								])
							)
						],
						data: {
							formStatus: EPageStatus.DETAIL
						},
						canDeactivate: [canDeactivateGuard]
					},
					{
						path: 'nuova-attivita',
						title: 'Crea nuova attività',
						loadComponent: () =>
							import('./feature/activitiesV2/activities-V2-detail/activities-v2-detail.component').then(
								c => c.ActivitiesV2DetailComponent
							),
						providers: [importProvidersFrom(EffectsModule.forFeature([CategoriesEffects, ActivitiesV2CreateEffects]))],
						data: {
							formStatus: EPageStatus.NEW
						}
					}
				]
			},
			{
				path: 'consiglio-dei-ministri',
				title: 'Consiglio dei ministri',
				providers: [LegislaturesService, LegislaturesMapper, provideTranslocoScope('legislatures')],
				loadComponent: () =>
					import('./feature/legislatures/legislatures-page/legislatures-page.component').then(c => c.LegislaturesPageComponent),
				children: [
					{
						path: 'legislature',
						title: 'Legislature',
						loadComponent: () =>
							import('./feature/legislatures/legislatures-list/legislatures-list.component').then(
								c => c.LegislaturesListComponent
							),
						providers: [
							importProvidersFrom(
								StoreModule.forFeature(legislaturesFeatureKey, legislaturesReducers),
								EffectsModule.forFeature([
									LegislaturesEffects,
									LegislatureDeleteEffects,
									LegislatureDuplicateEffects,
									LegislatureCreateEffects
								])
							)
						]
					},
					{
						path: ':id',
						title: 'Dettaglio legislatura',
						loadComponent: () =>
							import('./feature/legislatures/legislature-detail/legislature-detail.component').then(
								c => c.LegislatureDetailComponent
							),
						providers: [
							PoliticiansService,
							importProvidersFrom(
								StoreModule.forFeature(legislatureDetailFeatureKey, legislatureDetailReducers),
								EffectsModule.forFeature([
									LegislatureDetailEffects,
									LegislaturePatchEffects,
									PoliticiansAutocompleteEffects,
									LegislatureMinistryDeleteEffects,
									LegislatureMinistryAddEffects,
									LegislatureMinistryUpdateEffects
								])
							)
						]
					},
					{
						path: '',
						pathMatch: 'full',
						redirectTo: 'legislature'
					}
				]
			},
			{
				path: 'gruppi',
				loadComponent: () => import('./feature/groupsV2/groups-page/groups-page.component').then(c => c.GroupsPageComponent),
				providers: [GroupsService, provideTranslocoScope('groups')],
				children: [
					{
						path: '',
						title: 'Gruppi parlamentari',
						loadComponent: () =>
							import('./feature/groupsV2/groups-table-page/groups-table-page.component').then(
								c => c.GroupsTablePageComponent
							),
						providers: [
							importProvidersFrom(
								StoreModule.forFeature(groupsListFeatureKey, groupsListReducers),
								EffectsModule.forFeature([GroupsListEffects, GroupsPatchEffects])
							)
						]
					},
					{
						path: '',
						pathMatch: 'full',
						redirectTo: ''
					}
				]
			},
			{
				path: 'politici',
				loadComponent: () =>
					import('./feature/politiciansV2/politicians-v2/politicians-v2.component').then(c => c.PoliticiansV2Component),
				providers: [
					PoliticiansService,
					provideTranslocoScope('politicians'),
					importProvidersFrom(
						StoreModule.forFeature(politiciansV2FeatureKey, politiciansV2Reducers),
						EffectsModule.forFeature([PoliticiansV2Effects])
					)
				],
				children: [
					{
						path: 'senatori',
						loadComponent: () =>
							import('./feature/politiciansV2/senators-v2/senators-v2.component').then(c => c.SenatorsV2Component)
					},
					{
						path: 'deputati',
						loadComponent: () =>
							import('./feature/politiciansV2/deputies-v2/deputies-v2.component').then(c => c.DeputiesV2Component)
					},
					{
						path: '',
						pathMatch: 'full',
						redirectTo: 'senatori'
					}
				]
			},
			{
				path: 'politici/:id',
				loadComponent: () =>
					import('./feature/politiciansV2/politician-detail/politician-detail.component').then(c => c.PoliticianDetailComponent),
				providers: [
					PoliticiansService,
					provideTranslocoScope('politicians'),
					importProvidersFrom(
						StoreModule.forFeature(politiciansV2FeatureKey, politiciansV2Reducers),
						EffectsModule.forFeature([PoliticiansV2DetailEffects])
					)
				]
			},
			{ path: '', redirectTo: 'articoli', pathMatch: 'full' }
		]
	}
];

@NgModule({
	imports: [RouterModule.forRoot(routes)],
	exports: [RouterModule]
})
export class AppRoutingModule {}
