<div mat-dialog-title class="items-center" [style.display]="'flex'" [style.margin-top.rem]="1">
	<mat-icon *ngIf="data.icon" class="mr-2" [ngClass]="data.icon === 'error' ? 'text-red-500' : 'text-yellow-500'">
		{{ data.icon }}
	</mat-icon>
	{{ data.title| transloco }}
</div>
<mat-dialog-content>
	<h3 [innerHTML]="data.text| transloco"></h3>
</mat-dialog-content>
<mat-dialog-actions [attr.align]="'end'">
	<button *ngIf="data.discardButtonText" mat-stroked-button [mat-dialog-close]="false" color="accent">
		{{ data.discardButtonText| transloco }}
	</button>
	<button mat-raised-button color="primary" [mat-dialog-close]="true">
		{{ data.confirmButtonText| transloco }}
	</button>
</mat-dialog-actions>
