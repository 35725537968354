import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { SessionsService } from '../services/sessions.service';
import * as SessionsActions from './sessions.actions';
import { concatMap, of, switchMap } from 'rxjs';
import * as LoadingActions from '../../../core/store/actions/loading.actions';
import { catchError, map } from 'rxjs/operators';

@Injectable()
export class SessionsEffects {
	$init = createEffect(() =>
		this.actions$.pipe(
			ofType(SessionsActions.initLoadSessions),
			concatMap(({ userId }) => [LoadingActions.startLoading(), SessionsActions.loadSessions({ userId })])
		)
	);

	$load = createEffect(() =>
		this.actions$.pipe(
			ofType(SessionsActions.loadSessions),
			switchMap(({ userId }) =>
				this.sessionService.getSessions(userId).pipe(
					map(result =>
						SessionsActions.loadSessionsSuccess({
							sessions: result
						})
					),
					catchError(() => of(SessionsActions.loadSessionsError()))
				)
			)
		)
	);

	stopLoading$ = createEffect(() =>
		this.actions$.pipe(
			ofType(SessionsActions.loadSessionsSuccess, SessionsActions.loadSessionsError),
			switchMap(() => [LoadingActions.stopLoading()])
		)
	);

	constructor(private readonly actions$: Actions, private readonly sessionService: SessionsService) {}
}
