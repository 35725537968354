import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { ServerResponse } from '@core/models/miscellaneous/server-response.model';
import { catchError, map } from 'rxjs/operators';
import { RoleV2 } from '@core/models/roles/roleV2.model';
import { ServerError } from '@core/models/server-error.model';
import { RolesAndPermissionsCreate } from '../../feature/administration/roles-and-permissions-v2/models/roles-and-permissions-create.model';

@Injectable()
export class RolesServiceV2 {
	private static BASE_URL = 'v1/roles';

	constructor(private readonly http: HttpClient) {}

	public getRoles(): Observable<RoleV2[]> {
		return this.http
			.get<ServerResponse<RoleV2[]>>(`${RolesServiceV2.BASE_URL}`)
			.pipe(map((response: ServerResponse<RoleV2[]>) => response.results));
	}

	public save(role: RolesAndPermissionsCreate): Observable<RoleV2> {
		return this.http.post<RoleV2>(`${RolesServiceV2.BASE_URL}`, role).pipe(
			map(result => result),
			catchError(err => {
				const { error } = err as HttpErrorResponse;
				return (error as ServerError).statusCode === 400 ? throwError(error as ServerError) : throwError(err);
			})
		);
	}

	public assign(userId: string, roleId: string): Observable<void> {
		return this.http.post(`${RolesServiceV2.BASE_URL}/assign`, { userId, roleId }).pipe(map(() => void 0));
	}

	public delete(roleId: string): Observable<void> {
		return this.http.delete(`${RolesServiceV2.BASE_URL}/${roleId}`).pipe(
			map(() => void 0),
			catchError(err => {
				const { error } = err as HttpErrorResponse;
				return (error as ServerError).statusCode === 409 ? throwError(error as ServerError) : throwError(err);
			})
		);
	}

	public getRole(id: string): Observable<RoleV2> {
		return this.http.get<RoleV2>(`${RolesServiceV2.BASE_URL}/${id}`);
	}

	public update(id: string, role: RolesAndPermissionsCreate): Observable<RoleV2> {
		return this.http.put<RoleV2>(`${RolesServiceV2.BASE_URL}/${id}`, role).pipe(
			map(result => result),
			catchError(err => {
				const { error } = err as HttpErrorResponse;
				return (error as ServerError).statusCode === 400 ? throwError(error as ServerError) : throwError(err);
			})
		);
	}

	public unlinkPermissions(roleId: string, permissionIds: string[]): Observable<void> {
		const body = { permissionsIds: [...permissionIds] };
		return this.http.post(`${RolesServiceV2.BASE_URL}/${roleId}/permissions/remove`, body).pipe(map(() => void 0));
	}
}
