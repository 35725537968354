import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import * as ArticlesV2CancelActions from './articles-V2-cancel.actions';
import { of, switchMap } from 'rxjs';
import * as LoadingActions from '../../../../core/store/actions/loading.actions';
import { catchError, map } from 'rxjs/operators';
import { NotificationService } from '@core/services/notification.service';
import * as InternalNoteActions from '../../../../core/store/actions/internal-note-actions';
import { EFeatureType } from '@core/models/enums/feature-type.enum';
import * as RoleDeleteActions from '../../../administration/roles-and-permissions-v2/store/delete/roles-and-permissions-v2-delete-role.actions';
import { EArticleErrorCodes } from '../../models/enum/article-error-code.enum';
import { ServerError } from '@core/models/server-error.model';
import { environment } from '@environment/environment';
import { ArticlesV2Service } from '../../services/articles-v2.service';
import { TranslocoService } from '@ngneat/transloco';
import { InteractionService } from '../../../../shared/interaction/interaction.service';

@Injectable()
export class ArticlesV2CancelEffects {
	init$ = createEffect(() =>
		this.actions$.pipe(
			ofType(ArticlesV2CancelActions.initCancelArticle),
			switchMap(({ id, message }) => [
				LoadingActions.startLoading(),
				ArticlesV2CancelActions.cancelArticle({
					id,
					message
				})
			])
		)
	);

	cancel$ = createEffect(() =>
		this.actions$.pipe(
			ofType(ArticlesV2CancelActions.cancelArticle),
			switchMap(({ id, message }) =>
				this.articlesService.cancel(id, message).pipe(
					map(result => ArticlesV2CancelActions.cancelArticleSuccess({ article: result })),
					catchError(() => of(ArticlesV2CancelActions.cancelArticleFailed()))
				)
			)
		)
	);

	cancelSuccess$ = createEffect(() =>
		this.actions$.pipe(
			ofType(ArticlesV2CancelActions.cancelArticleSuccess),
			switchMap(({ article }) => {
				this.notificationService.success('articles.detail.success.cancel');
				return [InternalNoteActions.initLoadNote({ entityId: article.id, feature: EFeatureType.ARTICLE })];
			})
		)
	);

	cancelFailed$ = createEffect(() =>
		this.actions$.pipe(
			ofType(RoleDeleteActions.deleteRoleFailed),
			switchMap(({ error }) => {
				if (error.internalCode in EArticleErrorCodes) {
					this.interactionService.confirm(
						`articles.detail.errors.${error.internalCode}.title`,
						this.translateService.translate(`articles.detail.errors.${error.internalCode}.message`),
						'PDM.GENERICS.BUTTON.OK',
						'error'
					);
				} else {
					this.showDefaultError(error);
				}

				return [LoadingActions.stopLoading()];
			})
		)
	);

	stopLoading$ = createEffect(() =>
		this.actions$.pipe(
			ofType(ArticlesV2CancelActions.cancelArticleSuccess, ArticlesV2CancelActions.cancelArticleFailed),
			switchMap(() => [LoadingActions.stopLoading()])
		)
	);

	private showDefaultError(error: ServerError) {
		if (environment.production) {
			this.notificationService.error().afterDismissed();
		} else {
			this.interactionService.confirm('Generic Error', JSON.stringify(error), 'PDM.GENERICS.BUTTON.OK', 'error');
		}
	}

	constructor(
		private readonly actions$: Actions,
		private readonly articlesService: ArticlesV2Service,
		private readonly translateService: TranslocoService,
		private readonly interactionService: InteractionService,
		private readonly notificationService: NotificationService
	) {}
}
