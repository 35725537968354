import { createReducer, on } from '@ngrx/store';
import * as RolesV2Actions from '../actions/rolesV2.actions';
import { RoleV2 } from '@core/models/roles/roleV2.model';

export interface RolesV2State {
	roles: RoleV2[];
}

const initialState: RolesV2State = {
	roles: []
};

export const rolesV2Reducer = createReducer(
	initialState,
	on(RolesV2Actions.loadRolesSuccessV2, (state, action) => ({
		...state,
		roles: action.roles
	}))
);
