import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import * as LegislatureMinistryAddActions from './legislature-ministry-add.action';
import { of, switchMap, tap } from 'rxjs';
import * as LoadingActions from '../../../../../../core/store/actions/loading.actions';
import { catchError, map } from 'rxjs/operators';
import { NotificationService } from '@core/services/notification.service';
import { LegislaturesService } from '../../../../services/legislatures.service';
import { InteractionService } from '../../../../../../shared/interaction/interaction.service';
import { ELegislaturesErrorCode } from '@legislatures/models/enum/legislatures-error-code.enum';
import { showDefaultError } from '@core/utils/miscellaneous.utils';
import { TranslocoService } from '@ngneat/transloco';

@Injectable()
export class LegislatureMinistryAddEffects {
	init$ = createEffect(() =>
		this.actions$.pipe(
			ofType(LegislatureMinistryAddActions.initAddMinistries),
			switchMap(({ legislatureId, ministries }) => [
				LoadingActions.startLoading(),
				LegislatureMinistryAddActions.addMinistries({ legislatureId, ministries })
			])
		)
	);

	add$ = createEffect(() =>
		this.actions$.pipe(
			ofType(LegislatureMinistryAddActions.addMinistries),
			switchMap(({ legislatureId, ministries }) =>
				this.legislaturesService.addMinistries(legislatureId, ministries).pipe(
					map(legislatureDetail => LegislatureMinistryAddActions.addMinistriesSuccess({ legislatureDetail })),
					catchError(error => of(LegislatureMinistryAddActions.addMinistriesFailed({ error })))
				)
			)
		)
	);

	addSuccess$ = createEffect(
		() =>
			this.actions$.pipe(
				ofType(LegislatureMinistryAddActions.addMinistriesSuccess),
				tap(() => this.notificationService.success('legislatures.detail.success.add'))
			),
		{ dispatch: false }
	);

	addFailed$ = createEffect(() =>
		this.actions$.pipe(
			ofType(LegislatureMinistryAddActions.addMinistriesFailed),
			switchMap(({ error }) => {
				if (error.internalCode in ELegislaturesErrorCode) {
					this.interactionService.confirm(
						`legislatures.errors.${error.internalCode}.title`,
						this.translateService.translate(`legislatures.errors.${error.internalCode}.message`),
						'PDM.GENERICS.BUTTON.OK',
						'error'
					);
				} else {
					showDefaultError(error, this.notificationService, this.interactionService);
				}

				return [LoadingActions.stopLoading()];
			})
		)
	);

	stopLoading$ = createEffect(() =>
		this.actions$.pipe(
			ofType(LegislatureMinistryAddActions.addMinistriesSuccess, LegislatureMinistryAddActions.addMinistriesFailed),
			switchMap(() => [LoadingActions.stopLoading()])
		)
	);

	constructor(
		private readonly actions$: Actions,
		private readonly interactionService: InteractionService,
		private readonly legislaturesService: LegislaturesService,
		private readonly translateService: TranslocoService,
		private readonly notificationService: NotificationService
	) {}
}
