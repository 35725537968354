import { Observable } from 'rxjs';
import { CanDeactivateFn, UrlTree } from '@angular/router';
import { inject } from '@angular/core';
import { InteractionService } from '../shared/interaction/interaction.service';

export type CanDeactivateType = Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree;

export interface CanComponentDeactivate {
	canDeactivate: () => CanDeactivateType;
}

export const canDeactivateGuard: CanDeactivateFn<CanComponentDeactivate> = (component: CanComponentDeactivate) => {
	const interactionService = inject(InteractionService);
	if (!component.canDeactivate || component.canDeactivate()) {
		return true;
	}

	return interactionService.confirmOrDiscard('PDM.EXIT_GUARD.TITLE', 'PDM.EXIT_GUARD.MESSAGE');
};
