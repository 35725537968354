import { ValidatorFn } from '@angular/forms';

export class Filter<T> {
	value: T | undefined;
	options: { key: string; label: string }[] | undefined;
	key: string;
	label: string;
	controlType: string;
	type: string;
	validators: ValidatorFn[];

	constructor(
		options: {
			value?: T;
			options?: { key: string; label: string }[];
			key?: string;
			label?: string;
			controlType?: string;
			type?: string;
			validators?: ValidatorFn[];
		} = {}
	) {
		this.value = options.value;
		this.options = options.options;
		this.key = options.key || '';
		this.label = options.label || '';
		this.controlType = options.controlType || '';
		this.type = options.type || '';
		this.validators = options.validators || [];
	}
}

export class TextFilter extends Filter<string> {
	override controlType = 'textbox';
}

export class SelectFilter extends Filter<string> {
	override controlType = 'dropdown';
}

export class CheckboxFilter extends Filter<string> {
	override controlType = 'checkbox';
}
