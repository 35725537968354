import { AbstractControl, FormArray, ValidationErrors, ValidatorFn } from '@angular/forms';
import { isNullOrUndefined } from '@core/utils/miscellaneous.utils';
import { MinistryForm } from '@legislatures/legislature-detail/legislature-ministries/legislature-ministries.component';

export function noNewLine(): ValidatorFn {
	return (control: AbstractControl): ValidationErrors | null => {
		const forbidden = /[\r\n]+/gm.test(control.value);
		return forbidden ? { noNewLine: true } : null;
	};
}

export function isValidUrl(): ValidatorFn {
	return (control: AbstractControl): ValidationErrors | null => {
		if (!control.value) {
			return null;
		}

		try {
			new URL(control.value);
		} catch (e) {
			return { invalidUrl: true };
		}

		return null;
	};
}

export function isValueSelectedFromList(): ValidatorFn {
	return (control: AbstractControl): ValidationErrors | null => {
		const value = control.value;
		if (value && !isNullOrUndefined(value.id)) {
			return null;
		} else {
			return { noId: true };
		}
	};
}

export function maxArrayLength(max: number): ValidatorFn {
	return (control: AbstractControl): ValidationErrors | null => {
		if (control instanceof FormArray && control.length > max) {
			return { maxArrayLength: true };
		}

		return null;
	};
}

export function hasUniqueValues(): ValidatorFn {
	return (control: AbstractControl): ValidationErrors | null => {
		if (control instanceof FormArray) {
			const values = control.getRawValue();
			const uniqueMinistryNames = new Set(values.map((value: MinistryForm) => value.name));
			if (values.length !== uniqueMinistryNames.size) {
				return { uniqueValues: true };
			}

			const uniqueMinisters = new Set(values.map((value: MinistryForm) => value.minister?.id));
			if (values.length !== uniqueMinisters.size) {
				return { uniqueValues: true };
			}
			return null;
		}

		return null;
	};
}
