import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import * as ArticleReviewActions from './articles-V2-review.actions';
import { concatMap, of, switchMap, tap } from 'rxjs';
import * as LoadingActions from '@core/store/actions/loading.actions';
import { catchError, map } from 'rxjs/operators';
import { NotificationService } from '@core/services/notification.service';
import { ServerError } from '@core/models/server-error.model';
import { InteractionService } from '../../../../shared/interaction/interaction.service';
import { Router } from '@angular/router';
import { ArticlesV2Service } from '../../services/articles-v2.service';
import { EArticleErrorCodes } from '../../models/enum/article-error-code.enum';
import { environment } from '@environment/environment';
import { TranslocoService } from '@ngneat/transloco';

@Injectable()
export class ArticlesV2ReviewEffects {
	init$ = createEffect(() =>
		this.actions$.pipe(
			ofType(ArticleReviewActions.initAskReviewArticle),
			switchMap(({ id, message }) => [
				LoadingActions.startLoading(),
				ArticleReviewActions.askReviewArticle({
					id,
					message
				})
			])
		)
	);

	review$ = createEffect(() =>
		this.actions$.pipe(
			ofType(ArticleReviewActions.askReviewArticle),
			concatMap(({ id, message }) =>
				this.articlesService.submitToReview(id, message).pipe(
					map(() => ArticleReviewActions.askReviewArticleSuccess()),
					catchError(e => of(ArticleReviewActions.askReviewArticleFailed({ error: e as ServerError })))
				)
			)
		)
	);

	reviewSuccess$ = createEffect(
		() =>
			this.actions$.pipe(
				ofType(ArticleReviewActions.askReviewArticleSuccess),
				tap(() => {
					this.notificationService.success('articles.detail.success.askReview').afterDismissed();
					this.router.navigate([`articoli`]);
				})
			),
		{ dispatch: false }
	);

	reviewFailed$ = createEffect(() =>
		this.actions$.pipe(
			ofType(ArticleReviewActions.askReviewArticleFailed),
			switchMap(({ error }) => {
				if (error.internalCode in EArticleErrorCodes) {
					if (
						error.internalCode === EArticleErrorCodes.MISSING_DATA &&
						error.details.validation &&
						error.details.validation.length > 0
					) {
						const validations = error.details.validation.map(code => `articles.detail.errors.validation.${code.internalCode}`);
						this.interactionService.confirmValidationV2('articles.detail.errors.validation.title', validations);
					} else {
						this.interactionService.confirm(
							`articles.detail.errors.${error.internalCode}.title`,
							this.translateService.translate(`articles.detail.errors.${error.internalCode}.message`),
							'PDM.GENERICS.BUTTON.OK',
							'error'
						);
					}
				} else {
					this.showDefaultError(error);
				}

				return [LoadingActions.stopLoading()];
			})
		)
	);

	stopLoading$ = createEffect(() =>
		this.actions$.pipe(
			ofType(ArticleReviewActions.askReviewArticleSuccess, ArticleReviewActions.askReviewArticleFailed),
			switchMap(() => [LoadingActions.stopLoading()])
		)
	);

	private showDefaultError(error: ServerError) {
		if (environment.production) {
			this.notificationService.error().afterDismissed();
		} else {
			this.interactionService.confirm('Generic Error', JSON.stringify(error), 'PDM.GENERICS.BUTTON.OK', 'error');
		}
	}

	constructor(
		private readonly router: Router,
		private readonly actions$: Actions,
		private readonly articlesService: ArticlesV2Service,
		private readonly translateService: TranslocoService,
		private readonly interactionService: InteractionService,
		private readonly notificationService: NotificationService
	) {}
}
