import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import * as PoliticianDetailActions from './politiciansV2-detail.actions';
import { of, switchMap } from 'rxjs';
import * as LoadingActions from '../../../../core/store/actions/loading.actions';
import { catchError, map } from 'rxjs/operators';
import { Router } from '@angular/router';
import { PoliticiansService } from '../../services/politicians.service';

@Injectable()
export class PoliticiansV2DetailEffects {
	init$ = createEffect(() =>
		this.actions$.pipe(
			ofType(PoliticianDetailActions.initPoliticiansV2Detail),
			switchMap(({ id }) => [
				LoadingActions.startLoading(),
				PoliticianDetailActions.loadPoliticiansV2Detail({
					id
				})
			])
		)
	);

	load$ = createEffect(() =>
		this.actions$.pipe(
			ofType(PoliticianDetailActions.loadPoliticiansV2Detail),
			switchMap(({ id }) =>
				this.politiciansService.getPolitician(id).pipe(
					map(response =>
						PoliticianDetailActions.loadPoliticiansV2DetailSuccess({
							politician: response
						})
					),
					catchError(() => of(PoliticianDetailActions.loadPoliticiansV2DetailFailed()))
				)
			)
		)
	);

	loadSuccess$ = createEffect(() =>
		this.actions$.pipe(
			ofType(PoliticianDetailActions.loadPoliticiansV2DetailSuccess),
			switchMap(() => {
				this.router.navigate([], { queryParams: {} });

				return [LoadingActions.stopLoading()];
			})
		)
	);

	constructor(
		private readonly router: Router,
		private readonly actions$: Actions,
		private readonly politiciansService: PoliticiansService
	) {}
}
