import { AfterViewInit, DestroyRef, Directive, inject, Input, TemplateRef, ViewContainerRef } from '@angular/core';
import { startWith, tap } from 'rxjs/operators';
import { MatFormField } from '@angular/material/form-field';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Directive({
	selector: '[pdmHasError]',
	standalone: true
})
export class HasErrorDirective implements AfterViewInit {
	private readonly destroyRef = inject(DestroyRef);
	private _error = '';

	@Input()
	public set pdmHasError(value: string) {
		this._error = value;
	}

	public constructor(
		private readonly matFormField: MatFormField,
		private readonly templateRef: TemplateRef<unknown>,
		private readonly viewContainerRef: ViewContainerRef
	) {}

	public ngAfterViewInit(): void {
		const control = this.matFormField._control;
		if (!control) {
			return;
		}

		const ngControl = control.ngControl;
		if (!ngControl) {
			return;
		}

		ngControl.valueChanges
			?.pipe(
				startWith(control.value),
				tap(() => {
					this.viewContainerRef.clear();
					if (ngControl.hasError(this._error)) {
						const error = ngControl.errors?.[this._error];
						this.viewContainerRef.createEmbeddedView(this.templateRef, { error });
					}
				}),
				takeUntilDestroyed(this.destroyRef)
			)
			.subscribe();
	}
}
