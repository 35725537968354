import { Injectable } from '@angular/core';
import { MinistryForm } from '../legislature-detail/legislature-ministries/legislature-ministries.component';
import { MinistryAdd } from '../models/ministry-add.model';
import { MinistryUpdate } from '../models/ministry-update.model';

@Injectable()
export class LegislaturesMapper {
	public ministryFormToMinistryAdd(ministryForm: MinistryForm[]): MinistryAdd[] {
		return ministryForm.map(value => ({
			name: value.name,
			politicianId: value.minister!.id
		}));
	}

	public ministryFormToMinistryUpdate(ministryForm: MinistryForm[]): MinistryUpdate[] {
		return ministryForm.map(value => ({
			id: value.id!,
			name: value.name,
			politicianId: value.minister!.id
		}));
	}
}
