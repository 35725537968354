import { createSelector } from '@ngrx/store';
import { AppState } from '../../core.module';
import { ProvincesState } from '../reducers/provinces.reducer';
import { getCollaborator } from '@collaborators/store/detail/collaborators-detail.selectors';

const selectProvinces = (state: AppState) => state.provinces;
export const getProvinces = (regionId: string) =>
	createSelector(selectProvinces, (state: ProvincesState) => (state.provinces ? state.provinces[regionId] : null));

export const getProvincesByCollaboratorRegion = createSelector(selectProvinces, getCollaborator, (state, collaboratorDetail) => {
	if (collaboratorDetail && state.provinces) {
		return state.provinces[collaboratorDetail.regionId];
	}

	return [];
});
