import { NgModule } from '@angular/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { LuxonDateAdapter, LuxonDateModule, MAT_LUXON_DATE_ADAPTER_OPTIONS } from '@angular/material-luxon-adapter';

export const MY_FORMATS = {
	parse: {
		dateInput: 'y-MM-d'
	},
	display: {
		dateInput: 'd MMMM y',
		monthYearLabel: 'y',
		dateA11yLabel: 'LL',
		monthYearA11yLabel: 'YYYY'
	}
};

export class CustomDateAdapter extends LuxonDateAdapter {
	override getFirstDayOfWeek(): number {
		return 1;
	}
}

@NgModule({
	imports: [MatDatepickerModule, LuxonDateModule],
	exports: [MatDatepickerModule],
	providers: [
		{
			provide: DateAdapter,
			useClass: CustomDateAdapter,
			deps: [MAT_DATE_LOCALE, MAT_LUXON_DATE_ADAPTER_OPTIONS]
		},
		{ provide: MAT_DATE_FORMATS, useValue: MY_FORMATS }
	]
})
export class PdmCustomDatesModule {}
