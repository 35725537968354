import { CanActivate, Router, UrlTree } from '@angular/router';
import { Injectable } from '@angular/core';
import { LocalStorageService } from '@core/services/storage/local-storage.service';
import { EStorageKey } from '@core/services/storage/storage-key.enum';

@Injectable({
	providedIn: 'root'
})
export class AuthenticationGuard implements CanActivate {
	constructor(private readonly localStorageService: LocalStorageService, private readonly router: Router) {}

	canActivate(): boolean | UrlTree {
		const loggedIn = this.localStorageService.getItem(EStorageKey.LOGGED_IN) === true;
		if (!loggedIn) {
			return this.router.createUrlTree(['login']);
		}

		return true;
	}
}
