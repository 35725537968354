import { createAction, props } from '@ngrx/store';
import { Pagination } from '@core/models/miscellaneous/server-response.model';
import { ActivityV2 } from '../../models/activity-V2.model';

export const initActivitiesV2List = createAction('[Activities V2] Init List', props<{ params: Record<string, string | boolean> }>());
export const loadActivitiesV2List = createAction('[Activities V2] Load List', props<{ params: Record<string, string | boolean> }>());
export const loadActivitiesV2ListSuccess = createAction(
	'[Activities V2] Load success',
	props<{
		activities: ActivityV2[];
		pagination: Pagination;
	}>()
);
export const loadActivitiesV2ListFailed = createAction('[Activities V2] Load failed');
