import { ServerError } from '@core/models/server-error.model';
import { environment } from '@environment/environment';
import { NotificationService } from '@core/services/notification.service';
import { InteractionService } from '../../shared/interaction/interaction.service';
import { COMMA, ENTER } from '@angular/cdk/keycodes';

export const randomColor = (): string => `#${Math.floor(Math.random() * 0xffffff).toString(16)}`;
export const isNullOrUndefined = (value: unknown): boolean => value === null || value === undefined;
export const showDefaultError = (error: ServerError, notificationService: NotificationService, interactionService: InteractionService) => {
	if (environment.production) {
		notificationService.error().afterDismissed();
	} else {
		interactionService.confirm('Generic Error', JSON.stringify(error), 'PDM.GENERICS.BUTTON.OK', 'error');
	}
};

export const separatorKeysCodes = [ENTER, COMMA];
