import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import * as LegislatureCreateActions from './legislature-create.actions';
import { of, switchMap, tap } from 'rxjs';
import * as LoadingActions from '../../../../core/store/actions/loading.actions';
import { catchError, map } from 'rxjs/operators';
import { NotificationService } from '@core/services/notification.service';
import { LegislaturesService } from '../../services/legislatures.service';
import { Router } from '@angular/router';
import { InteractionService } from '../../../../shared/interaction/interaction.service';
import { TranslocoService } from '@ngneat/transloco';
import { ELegislaturesErrorCode } from '@legislatures/models/enum/legislatures-error-code.enum';
import { showDefaultError } from '@core/utils/miscellaneous.utils';

@Injectable()
export class LegislatureCreateEffects {
	init$ = createEffect(() =>
		this.actions$.pipe(
			ofType(LegislatureCreateActions.initCreateLegislature),
			switchMap(({ legislatureCreate }) => [
				LoadingActions.startLoading(),
				LegislatureCreateActions.createLegislature({ legislatureCreate })
			])
		)
	);

	create$ = createEffect(() =>
		this.actions$.pipe(
			ofType(LegislatureCreateActions.createLegislature),
			switchMap(({ legislatureCreate }) =>
				this.legislaturesService.create(legislatureCreate).pipe(
					map(legislatureDetail => LegislatureCreateActions.createLegislatureSuccess({ legislatureDetail })),
					catchError(error => of(LegislatureCreateActions.createLegislatureFailed({ error })))
				)
			)
		)
	);

	createSuccess$ = createEffect(
		() =>
			this.actions$.pipe(
				ofType(LegislatureCreateActions.createLegislatureSuccess),
				tap(({ legislatureDetail }) => {
					this.notificationService.success('legislatures.create.success').afterDismissed();
					this.router.navigate([`consiglio-dei-ministri/${legislatureDetail.id}`]);
				})
			),
		{ dispatch: false }
	);

	createFailed$ = createEffect(() =>
		this.actions$.pipe(
			ofType(LegislatureCreateActions.createLegislatureFailed),
			switchMap(({ error }) => {
				if (error.internalCode in ELegislaturesErrorCode) {
					if (error.internalCode === ELegislaturesErrorCode.DUPLICATE_NAME && error.details) {
						const validations = this.translateService.translate(`legislatures.errors.validation.${error.internalCode}`, {
							title: error.details.duplicatedName
						});
						this.interactionService.confirmValidationV2('legislatures.errors.validation.title', [validations]);
					} else {
						this.interactionService.confirm(
							`legislatures.errors.${error.internalCode}.title`,
							this.translateService.translate(`legislatures.errors.${error.internalCode}.message`),
							'PDM.GENERICS.BUTTON.OK',
							'error'
						);
					}
				} else {
					showDefaultError(error, this.notificationService, this.interactionService);
				}

				return [LoadingActions.stopLoading()];
			})
		)
	);

	stopLoading$ = createEffect(() =>
		this.actions$.pipe(
			ofType(LegislatureCreateActions.createLegislatureSuccess, LegislatureCreateActions.createLegislatureFailed),
			switchMap(() => [LoadingActions.stopLoading()])
		)
	);

	constructor(
		private readonly router: Router,
		private readonly actions$: Actions,
		private readonly translateService: TranslocoService,
		private readonly interactionService: InteractionService,
		private readonly legislaturesService: LegislaturesService,
		private readonly notificationService: NotificationService
	) {}
}
