import { Injectable } from '@angular/core';
import { ArticlesV2Service } from '../../services/articles-v2.service';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import * as ArticlesV2DetailActions from './articles-v2-detail.actions';
import { of, switchMap } from 'rxjs';
import * as LoadingActions from '@core/store/actions/loading.actions';
import { catchError, map, tap } from 'rxjs/operators';
import { NotificationService } from '@core/services/notification.service';

@Injectable()
export class ArticlesV2DetailEffects {
	init$ = createEffect(() =>
		this.actions$.pipe(
			ofType(ArticlesV2DetailActions.initArticleV2Detail),
			switchMap(({ id }) => [LoadingActions.startLoading(), ArticlesV2DetailActions.loadArticleV2Detail({ id })])
		)
	);

	load$ = createEffect(() =>
		this.actions$.pipe(
			ofType(ArticlesV2DetailActions.loadArticleV2Detail),
			switchMap(({ id }) =>
				this.articlesV2Service.getArticleById(id).pipe(
					map(result =>
						ArticlesV2DetailActions.loadArticleV2DetailSuccess({
							article: result
						})
					),
					catchError(() => of(ArticlesV2DetailActions.loadArticleV2DetailFailed()))
				)
			)
		)
	);

	loadAllFailed$ = createEffect(
		() =>
			this.actions$.pipe(
				ofType(ArticlesV2DetailActions.loadArticleV2DetailFailed),
				tap(() => this.notificationService.error('PDM.GENERICS.ERROR.LOAD_DATA_ERROR'))
			),
		{ dispatch: false }
	);

	stopLoading$ = createEffect(() =>
		this.actions$.pipe(
			ofType(ArticlesV2DetailActions.loadArticleV2Detail, ArticlesV2DetailActions.loadArticleV2DetailFailed),
			switchMap(() => [LoadingActions.stopLoading()])
		)
	);

	constructor(
		private readonly actions$: Actions,
		private readonly articlesV2Service: ArticlesV2Service,
		private readonly notificationService: NotificationService
	) {}
}
