import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { MiscellaneousService } from '@core/services/miscellaneous.service';
import { mergeMap, of, switchMap, withLatestFrom } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import * as ProvincesV2Actions from '../actions/provincesV2.actions';
import * as LoadingActions from '../actions/loading.actions';
import { Store } from '@ngrx/store';
import { AppState } from '../../core.module';
import { getProvinces } from '../selectors/provinces.selector';

@Injectable()
export class ProvincesV2Effects {
	initLoadProvinces$ = createEffect(() =>
		this.actions$.pipe(
			ofType(ProvincesV2Actions.initLoadProvinces),
			switchMap(({ regionId }) => [LoadingActions.startLoading(), ProvincesV2Actions.loadProvinces({ regionId })])
		)
	);

	loadProvinces$ = createEffect(() =>
		this.actions$.pipe(
			ofType(ProvincesV2Actions.loadProvinces),
			mergeMap(({ regionId }) => of(regionId).pipe(withLatestFrom(this.store$.select(getProvinces(regionId))))),
			switchMap(([regionId, provinces]) => {
				if (provinces) {
					return [ProvincesV2Actions.loadProvincesSuccess({ regionId, provinces })];
				}

				return this.miscellaneousService.getProvinces(regionId).pipe(
					map(provinces => ProvincesV2Actions.loadProvincesSuccess({ regionId, provinces })),
					catchError(() => of(ProvincesV2Actions.loadProvincesFailed()))
				);
			})
		)
	);

	stopLoading$ = createEffect(() =>
		this.actions$.pipe(
			ofType(ProvincesV2Actions.loadProvincesSuccess, ProvincesV2Actions.loadProvincesFailed),
			switchMap(() => [LoadingActions.stopLoading()])
		)
	);

	constructor(
		private readonly actions$: Actions,
		private readonly store$: Store<AppState>,
		private readonly miscellaneousService: MiscellaneousService
	) {}
}
