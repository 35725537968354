import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { ServerError } from '@core/models/server-error.model';
import { Session } from '../models/session.model';

@Injectable()
export class SessionsService {
	private readonly baseURL = 'v1/sessions';

	constructor(private readonly http: HttpClient) {}

	public getSessions(userId: string): Observable<Session[]> {
		const params = new HttpParams().set('userId', userId).set('active', true);
		return this.http.get<Session[]>(`${this.baseURL}`, { params });
	}

	public delete(sessionIds: string[]): Observable<void> {
		return this.http.delete(`${this.baseURL}`, { body: { ids: sessionIds } }).pipe(
			map(() => void 0),
			catchError(err => {
				const { error } = err as HttpErrorResponse;
				return (error as ServerError).statusCode === 409 ? throwError(error as ServerError) : throwError(err);
			})
		);
	}
}
