import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import * as ArticlesV2DeleteActions from './articles-V2-delete.actions';
import { of, switchMap, tap } from 'rxjs';
import * as LoadingActions from '../../../../core/store/actions/loading.actions';
import { catchError, map } from 'rxjs/operators';
import { NotificationService } from '@core/services/notification.service';
import { Router } from '@angular/router';
import { ArticlesV2Service } from '../../services/articles-v2.service';
import * as RoleDeleteActions from '../../../administration/roles-and-permissions-v2/store/delete/roles-and-permissions-v2-delete-role.actions';
import { ServerError } from '@core/models/server-error.model';
import { environment } from '@environment/environment';
import { InteractionService } from '../../../../shared/interaction/interaction.service';
import { TranslocoService } from '@ngneat/transloco';
import { EArticleErrorCodes } from '../../models/enum/article-error-code.enum';

@Injectable()
export class ArticlesV2DeleteEffects {
	init$ = createEffect(() =>
		this.actions$.pipe(
			ofType(ArticlesV2DeleteActions.initDeleteArticleV2),
			switchMap(({ id }) => [LoadingActions.startLoading(), ArticlesV2DeleteActions.deleteArticleV2({ id })])
		)
	);

	delete$ = createEffect(() =>
		this.actions$.pipe(
			ofType(ArticlesV2DeleteActions.deleteArticleV2),
			switchMap(({ id }) =>
				this.articlesService.deleteArticle(id).pipe(
					map(() => ArticlesV2DeleteActions.deleteArticleV2Success({ id })),
					catchError(() => of(ArticlesV2DeleteActions.deleteArticleV2Failed()))
				)
			)
		)
	);

	deleteSuccess$ = createEffect(
		() =>
			this.actions$.pipe(
				ofType(ArticlesV2DeleteActions.deleteArticleV2Success),
				tap(() =>
					this.router.navigateByUrl('articoli').then(() => this.notificationService.success('articles.detail.success.delete'))
				)
			),
		{ dispatch: false }
	);

	deleteFailed$ = createEffect(() =>
		this.actions$.pipe(
			ofType(RoleDeleteActions.deleteRoleFailed),
			switchMap(({ error }) => {
				if (error.internalCode in EArticleErrorCodes) {
					this.interactionService.confirm(
						`articles.detail.errors.${error.internalCode}.title`,
						this.translateService.translate(`articles.detail.errors.${error.internalCode}.message`),
						'PDM.GENERICS.BUTTON.OK',
						'error'
					);
				} else {
					this.showDefaultError(error);
				}

				return [LoadingActions.stopLoading()];
			})
		)
	);

	stopLoading$ = createEffect(() =>
		this.actions$.pipe(
			ofType(ArticlesV2DeleteActions.deleteArticleV2Success, ArticlesV2DeleteActions.deleteArticleV2Failed),
			switchMap(() => [LoadingActions.stopLoading()])
		)
	);

	private showDefaultError(error: ServerError) {
		if (environment.production) {
			this.notificationService.error().afterDismissed();
		} else {
			this.interactionService.confirm('Generic Error', JSON.stringify(error), 'PDM.GENERICS.BUTTON.OK', 'error');
		}
	}

	constructor(
		private readonly router: Router,
		private readonly actions$: Actions,
		private readonly translateService: TranslocoService,
		private readonly articlesService: ArticlesV2Service,
		private readonly interactionService: InteractionService,
		private readonly notificationService: NotificationService
	) {}
}
