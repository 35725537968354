import { createAction, props } from '@ngrx/store';
import { Province } from '@core/models/miscellaneous/province.model';

export const loadAllProvinces = createAction('[Provinces] Load all');
export const loadAllProvincesSuccess = createAction('[Provinces] Load all success', props<{ provinces: Province[] }>());
export const loadAllProvincesFailed = createAction('[Provinces] Load all failed');

export const initLoadAllProvincesInRegion = createAction('[Provinces in region] Init', props<{ regionId: string }>());
export const loadProvinceInRegion = createAction('[Provinces in region] Load', props<{ regionId: string }>());
export const loadProvinceInRegionSuccess = createAction('[Provinces in region] Load success', props<{ provinces: Province[] }>());
export const loadProvinceInRegionFailed = createAction('[Provinces in region] Load failed');
