import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import * as CollaboratorsListActions from './collaborators-list.actions';
import { of, switchMap } from 'rxjs';
import * as LoadingActions from '@core/store/actions/loading.actions';
import { catchError, map } from 'rxjs/operators';
import { CollaboratorsV2Service } from '../../services/collaborators-v2.service';

@Injectable()
export class CollaboratorsListEffects {
	init$ = createEffect(() =>
		this.actions$.pipe(
			ofType(CollaboratorsListActions.initCollaborators),
			switchMap(({ params }) => [LoadingActions.startLoading(), CollaboratorsListActions.loadCollaborators({ params })])
		)
	);

	load$ = createEffect(() =>
		this.actions$.pipe(
			ofType(CollaboratorsListActions.loadCollaborators),
			switchMap(({ params }) =>
				this.collaboratorsService.listUsers(params).pipe(
					map(result =>
						CollaboratorsListActions.loadCollaboratorsSuccess({
							collaborators: result.results,
							pagination: result.paginatedInfo
						})
					),
					catchError(() => of(CollaboratorsListActions.loadCollaboratorsFailed()))
				)
			)
		)
	);

	stopLoading$ = createEffect(() =>
		this.actions$.pipe(
			ofType(CollaboratorsListActions.loadCollaboratorsSuccess, CollaboratorsListActions.loadCollaboratorsFailed),
			switchMap(() => [LoadingActions.stopLoading()])
		)
	);

	constructor(private readonly actions$: Actions, private readonly collaboratorsService: CollaboratorsV2Service) {}
}
