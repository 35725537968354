import { createAction, props } from '@ngrx/store';
import { Pagination } from '@core/models/miscellaneous/server-response.model';
import { Legislature } from '../models/legislature.model';
import { ServerError } from '@core/models/server-error.model';

export const initLegislatures = createAction('[Legislatures] Init', props<{ params: Record<string, string | boolean> }>());
export const loadLegislatures = createAction('[Legislatures] Load', props<{ params: Record<string, string | boolean> }>());
export const loadLegislaturesSuccess = createAction(
	'[Legislatures] Load success',
	props<{
		legislatures: Legislature[];
		pagination: Pagination;
	}>()
);
export const loadLegislaturesFailed = createAction('[Legislatures] Load failed', props<{ error: ServerError }>());
