import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { ApiInterceptor } from '@core/interceptors/api.interceptor';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BrowserModule } from '@angular/platform-browser';
import { InjectionToken, LOCALE_ID, NgModule } from '@angular/core';
import localeIt from '@angular/common/locales/it';
import { registerLocaleData } from '@angular/common';
import { CoreModule } from '@core/core.module';
import { LayoutModule } from '@angular/cdk/layout';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatIconModule } from '@angular/material/icon';
import packageObject from './../../package.json';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatButtonModule } from '@angular/material/button';
import { MatListModule } from '@angular/material/list';
import { TranslocoRootModule } from './transloco-root.module';
import { PdmCustomDatesModule } from './shared/pdm-dates/pdm-custom-dates.module';

export const APP_VERSION = new InjectionToken('app.version');
registerLocaleData(localeIt);

@NgModule({
	declarations: [AppComponent],
	imports: [
		CoreModule,
		TranslocoRootModule,
		BrowserModule,
		BrowserAnimationsModule,
		AppRoutingModule,
		HttpClientModule,
		MatSnackBarModule,
		PdmCustomDatesModule,
		LayoutModule,
		MatToolbarModule,
		MatButtonModule,
		MatSidenavModule,
		MatIconModule,
		MatListModule
	],
	providers: [
		{ provide: HTTP_INTERCEPTORS, useClass: ApiInterceptor, multi: true },
		{ provide: LOCALE_ID, useValue: 'it-IT' },
		{ provide: APP_VERSION, useValue: packageObject.version }
	],
	bootstrap: [AppComponent]
})
export class AppModule {}
