import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
	selector: 'pdm-profile-button',
	templateUrl: './profile-button.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProfileButtonComponent {
	@Input() public userProfileColor = '';
	@Input() public userInitials = '';
}
