import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import * as CollaboratorDetailActions from './collaborator-detail.actions';
import * as RolesV2Actions from '../../../../../core/store/actions/rolesV2.actions';
import * as RegionsActions from '../../../../../core/store/actions/regions.actions';
import * as ProvincesActions from '../../../../../core/store/actions/provincesV2.actions';
import { concatMap, mergeMap, of, switchMap } from 'rxjs';
import * as LoadingActions from '../../../../../core/store/actions/loading.actions';
import { catchError } from 'rxjs/operators';
import { CollaboratorsV2Service } from '../../services/collaborators-v2.service';

@Injectable()
export class CollaboratorDetailEffects {
	init$ = createEffect(() =>
		this.actions$.pipe(
			ofType(CollaboratorDetailActions.initCollaboratorDetail),
			mergeMap(({ id }) => [
				LoadingActions.startLoading(),
				CollaboratorDetailActions.loadCollaboratorDetail({
					id
				}),
				RolesV2Actions.initRolesV2(),
				RegionsActions.initLoadAllRegions()
			])
		)
	);

	load$ = createEffect(() =>
		this.actions$.pipe(
			ofType(CollaboratorDetailActions.loadCollaboratorDetail),
			concatMap(({ id }) =>
				this.collaboratorsService.getDetail(id).pipe(
					switchMap(response => [
						CollaboratorDetailActions.loadCollaboratorDetailSuccess({
							collaborator: response
						}),
						ProvincesActions.initLoadProvinces({ regionId: response.regionId })
					]),
					catchError(() => of(CollaboratorDetailActions.loadCollaboratorDetailFailed()))
				)
			)
		)
	);

	stopLoading$ = createEffect(() =>
		this.actions$.pipe(
			ofType(CollaboratorDetailActions.loadCollaboratorDetailSuccess, CollaboratorDetailActions.loadCollaboratorDetailFailed),
			switchMap(() => [LoadingActions.stopLoading()])
		)
	);

	constructor(private readonly actions$: Actions, private readonly collaboratorsService: CollaboratorsV2Service) {}
}
