import { Category } from '@core/models/category.model';
import { createReducer, on } from '@ngrx/store';
import * as CategoriesActions from '../actions/categories.actions';

export interface CategoriesState {
	categories: Category[];
}

const initialState: CategoriesState = {
	categories: []
};

export const categoriesReducer = createReducer(
	initialState,
	on(CategoriesActions.loadCategoriesSuccess, (state, action) => ({
		...state,
		categories: [...action.categories]
	}))
);
