import { createAction, props } from '@ngrx/store';
import { PDMFile } from '@core/models/pdm-file.model';
import { EFeatureType } from '@core/models/enums/feature-type.enum';
import { EFileTarget } from '@core/models/enums/file-target.enum';

export const uploadFileFailed = createAction('[File] Upload failed');
export const initDeleteFile = createAction(
	'[File] Delete Init',
	props<{ id: string; featureType: EFeatureType; targetFile?: EFileTarget }>()
);
export const deleteFile = createAction('[File] Delete', props<{ id: string; featureType: EFeatureType; targetFile?: EFileTarget }>());

// Article files
export const uploadArticleFileSuccess = createAction('[Article File] Upload success', props<{ file: PDMFile }>());
export const deleteArticleFileSuccess = createAction('[Article File] Delete success', props<{ id: string }>());
export const deleteArticleFileFailed = createAction('[Article File] Delete failed');
export const cleanArticleFiles = createAction('[Article Files] Clean');

// Activity files
export const uploadActivityTextFileSuccess = createAction('[Activity Text File] Upload success', props<{ file: PDMFile }>());
export const uploadActivityDescriptionFileSuccess = createAction('[Activity Description File] Upload success', props<{ file: PDMFile }>());
export const deleteActivityTextFileSuccess = createAction('[Activity Text File] Delete success', props<{ id: string }>());
export const deleteActivityDescriptionFileSuccess = createAction('[Activity Description File] Delete success', props<{ id: string }>());
export const deleteActivityFileFailed = createAction('[Activity File] Delete failed');
export const cleanActivityFiles = createAction('[Activity Files] Clean');
