<ng-container *ngIf="type === 'primary'">
	<button mat-raised-button [color]="color" [disabled]="disabled">
		<ng-container [ngTemplateOutlet]="buttonContent"></ng-container>
	</button>
</ng-container>

<ng-container *ngIf="type === 'secondary'">
	<button mat-stroked-button [color]="color" [disabled]="disabled">
		<ng-container [ngTemplateOutlet]="buttonContent"></ng-container>
	</button>
</ng-container>

<ng-container *ngIf="type === 'text'">
	<button mat-button [color]="color" [disabled]="disabled">
		<ng-container [ngTemplateOutlet]="buttonContent"></ng-container>
	</button>
</ng-container>

<ng-template #buttonContent>
	<mat-icon *ngIf="updating">
		<mat-spinner diameter="20" color="accent"></mat-spinner>
	</mat-icon>
	<ng-content></ng-content>
</ng-template>
