import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import * as PoliticiansAutocompleteActions from './politicians-autocomplete.actions';
import { of, switchMap } from 'rxjs';
import * as LoadingActions from '../../../../../core/store/actions/loading.actions';
import { catchError, map } from 'rxjs/operators';
import { PoliticiansService } from '../../../../politiciansV2/services/politicians.service';

@Injectable()
export class PoliticiansAutocompleteEffects {
	init$ = createEffect(() =>
		this.actions$.pipe(
			ofType(PoliticiansAutocompleteActions.initPoliticiansAutocomplete),
			switchMap(({ search }) => [
				LoadingActions.startLoading(),
				PoliticiansAutocompleteActions.loadPoliticiansAutocomplete({
					search
				})
			])
		)
	);

	load$ = createEffect(() =>
		this.actions$.pipe(
			ofType(PoliticiansAutocompleteActions.loadPoliticiansAutocomplete),
			switchMap(() =>
				this.politiciansService.getSimplePoliticians().pipe(
					map(({ results }) => PoliticiansAutocompleteActions.loadPoliticiansAutocompleteSuccess({ politicians: results })),
					catchError(() => of(PoliticiansAutocompleteActions.loadPoliticiansAutocompleteFailed()))
				)
			)
		)
	);

	loadSuccess$ = createEffect(() =>
		this.actions$.pipe(
			ofType(
				PoliticiansAutocompleteActions.loadPoliticiansAutocompleteSuccess,
				PoliticiansAutocompleteActions.loadPoliticiansAutocompleteFailed
			),
			switchMap(() => [LoadingActions.stopLoading()])
		)
	);

	constructor(private readonly actions$: Actions, private readonly politiciansService: PoliticiansService) {}
}
