import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { InternalNote, ServerInternalNote } from '@core/models/notes/internal-note.model';
import { map } from 'rxjs/operators';
import { DateTime } from 'luxon';
import { ServerResponse } from '@core/models/miscellaneous/server-response.model';
import { EFeatureType } from '@core/models/enums/feature-type.enum';

@Injectable()
export class InternalNoteService {
	private static BASE_URL = 'v1/notes';

	constructor(private readonly http: HttpClient) {}

	public list(entityId: string, feature: EFeatureType): Observable<InternalNote[]> {
		const params = new HttpParams().set('entityId', entityId).set('category', feature);

		return this.http.get<ServerResponse<ServerInternalNote[]>>(`${InternalNoteService.BASE_URL}`, { params }).pipe(
			map((response: ServerResponse<ServerInternalNote[]>) => {
				return response.results.map(note => ({
					...note,
					createdAt: DateTime.fromISO(note.createdAt),
					category: note.category as EFeatureType
				}));
			})
		);
	}
}
