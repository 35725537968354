import { createReducer, on } from '@ngrx/store';
import * as RegionsActions from '../actions/regions.actions';
import { Region } from '@core/models/miscellaneous/region.model';

export interface RegionsState {
	regions: Region[];
}

const initialState: RegionsState = {
	regions: []
};

export const regionsReducer = createReducer(
	initialState,
	on(RegionsActions.loadAllRegionsSuccess, (state, action) => ({
		...state,
		regions: action.regions
	}))
);
