import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import * as PoliticiansActions from './politiciansV2.actions';
import { of, switchMap, withLatestFrom } from 'rxjs';
import * as LoadingActions from '../../../core/store/actions/loading.actions';
import { catchError, map } from 'rxjs/operators';
import { Router } from '@angular/router';
import { PoliticiansService } from '../services/politicians.service';
import { PageEvent } from '@angular/material/paginator';
import { Store } from '@ngrx/store';
import { AppState } from '../../../core/core.module';
import { getPoliticiansV2PaginationInfo } from './politiciansV2.selectors';

@Injectable()
export class PoliticiansV2Effects {
	init$ = createEffect(() =>
		this.actions$.pipe(
			ofType(PoliticiansActions.initPoliticiansV2),
			switchMap(({ politicianType, search }) => [
				LoadingActions.startLoading(),
				PoliticiansActions.loadPoliticiansV2({
					politicianType,
					search
				})
			])
		)
	);

	load$ = createEffect(() =>
		this.actions$.pipe(
			ofType(PoliticiansActions.loadPoliticiansV2),
			withLatestFrom(this.store$.select(getPoliticiansV2PaginationInfo)),
			switchMap(([{ politicianType, search }, actualPageInfo]) => {
				const pagination: PageEvent = {
					length: 0,
					pageSize: actualPageInfo?.itemsPerPage ?? 20,
					pageIndex: actualPageInfo.page ? actualPageInfo.page + 1 : 0
				};
				return this.politiciansService.getPoliticians(politicianType, pagination, search).pipe(
					map(response =>
						PoliticiansActions.loadPoliticiansV2Success({
							politicians: response.results,
							pagination: response.paginatedInfo
						})
					),
					catchError(() => of(PoliticiansActions.loadPoliticiansV2Failed()))
				);
			})
		)
	);

	loadMore$ = createEffect(() =>
		this.actions$.pipe(
			ofType(PoliticiansActions.loadMorePoliticiansV2),
			withLatestFrom(this.store$.select(getPoliticiansV2PaginationInfo)),
			switchMap(([{ politicianType }, pagination]) => {
				const newPagination: PageEvent = {
					length: 0,
					pageSize: pagination.itemsPerPage,
					pageIndex: pagination.page
				};
				return this.politiciansService.getPoliticians(politicianType, newPagination, null).pipe(
					map(response =>
						PoliticiansActions.loadMorePoliticiansV2Success({
							politicians: response.results,
							pagination: response.paginatedInfo
						})
					),
					catchError(() => of(PoliticiansActions.loadPoliticiansV2Failed()))
				);
			})
		)
	);

	loadSuccess$ = createEffect(() =>
		this.actions$.pipe(
			ofType(PoliticiansActions.loadPoliticiansV2Success),
			switchMap(() => {
				this.router.navigate([], { queryParams: {} });

				return [LoadingActions.stopLoading()];
			})
		)
	);

	constructor(
		private readonly router: Router,
		private readonly actions$: Actions,
		private readonly store$: Store<AppState>,
		private readonly politiciansService: PoliticiansService
	) {}
}
