import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import * as ActivitiesV2CancelActions from './activities-V2-cancel.actions';
import { of, switchMap } from 'rxjs';
import * as LoadingActions from '../../../../core/store/actions/loading.actions';
import { catchError, map } from 'rxjs/operators';
import { NotificationService } from '@core/services/notification.service';
import * as InternalNoteActions from '../../../../core/store/actions/internal-note-actions';
import { EFeatureType } from '@core/models/enums/feature-type.enum';
import * as RoleDeleteActions from '../../../administration/roles-and-permissions-v2/store/delete/roles-and-permissions-v2-delete-role.actions';
import { ServerError } from '@core/models/server-error.model';
import { environment } from '@environment/environment';
import { TranslocoService } from '@ngneat/transloco';
import { InteractionService } from '../../../../shared/interaction/interaction.service';
import { ActivitiesV2Service } from '../../services/activities-V2.service';
import { EActivitiesV2ErrorCodes } from '../../models/enum/activities-V2-error-code.enum';

@Injectable()
export class ActivitiesV2CancelEffects {
	init$ = createEffect(() =>
		this.actions$.pipe(
			ofType(ActivitiesV2CancelActions.initCancelActivityV2),
			switchMap(({ id, message }) => [
				LoadingActions.startLoading(),
				ActivitiesV2CancelActions.cancelActivityV2({
					id,
					message
				})
			])
		)
	);

	cancel$ = createEffect(() =>
		this.actions$.pipe(
			ofType(ActivitiesV2CancelActions.cancelActivityV2),
			switchMap(({ id, message }) =>
				this.activityService.cancel(id, message).pipe(
					map(result => ActivitiesV2CancelActions.cancelActivityV2Success({ activity: result })),
					catchError(() => of(ActivitiesV2CancelActions.cancelActivityV2Failed()))
				)
			)
		)
	);

	cancelSuccess$ = createEffect(() =>
		this.actions$.pipe(
			ofType(ActivitiesV2CancelActions.cancelActivityV2Success),
			switchMap(({ activity }) => {
				this.notificationService.success('activities.detail.success.cancel');
				return [InternalNoteActions.initLoadNote({ entityId: activity.id, feature: EFeatureType.ACTIVITY })];
			})
		)
	);

	cancelFailed$ = createEffect(() =>
		this.actions$.pipe(
			ofType(RoleDeleteActions.deleteRoleFailed),
			switchMap(({ error }) => {
				if (error.internalCode in EActivitiesV2ErrorCodes) {
					this.interactionService.confirm(
						`activities.detail.errors.${error.internalCode}.title`,
						this.translateService.translate(`activities.detail.errors.${error.internalCode}.message`),
						'PDM.GENERICS.BUTTON.OK',
						'error'
					);
				} else {
					this.showDefaultError(error);
				}

				return [LoadingActions.stopLoading()];
			})
		)
	);

	stopLoading$ = createEffect(() =>
		this.actions$.pipe(
			ofType(ActivitiesV2CancelActions.cancelActivityV2Success, ActivitiesV2CancelActions.cancelActivityV2Failed),
			switchMap(() => [LoadingActions.stopLoading()])
		)
	);

	private showDefaultError(error: ServerError) {
		if (environment.production) {
			this.notificationService.error().afterDismissed();
		} else {
			this.interactionService.confirm('Generic Error', JSON.stringify(error), 'PDM.GENERICS.BUTTON.OK', 'error');
		}
	}

	constructor(
		private readonly actions$: Actions,
		private readonly activityService: ActivitiesV2Service,
		private readonly translateService: TranslocoService,
		private readonly interactionService: InteractionService,
		private readonly notificationService: NotificationService
	) {}
}
