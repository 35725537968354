import { createAction, props } from '@ngrx/store';

export const passwordResetLink = createAction('[Password reset link] Ask link', props<{ email: string }>());
export const passwordResetLinkSuccess = createAction('[Password reset link] Ask link success');
export const passwordResetLinkFailed = createAction('[Password reset link] Ask link failed');

export const passwordReset = createAction(
	'[Password reset] Reset',
	props<{ token: string; newPassword: string; newPasswordConfirmation: string }>()
);
export const passwordResetSuccess = createAction('[Password reset] Reset success');
export const passwordResetFailed = createAction('[Password reset] Reset failed');
