import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import * as LegislatureDuplicateActions from './legislature-duplicate.actions';
import { of, switchMap, tap } from 'rxjs';
import * as LoadingActions from '../../../../core/store/actions/loading.actions';
import { catchError, map } from 'rxjs/operators';
import { NotificationService } from '@core/services/notification.service';
import { LegislaturesService } from '../../services/legislatures.service';
import { ELegislaturesErrorCode } from '@legislatures/models/enum/legislatures-error-code.enum';
import { showDefaultError } from '@core/utils/miscellaneous.utils';
import { InteractionService } from '../../../../shared/interaction/interaction.service';
import { TranslocoService } from '@ngneat/transloco';

@Injectable()
export class LegislatureDuplicateEffects {
	init$ = createEffect(() =>
		this.actions$.pipe(
			ofType(LegislatureDuplicateActions.initDuplicateLegislature),
			switchMap(({ id, name }) => [LoadingActions.startLoading(), LegislatureDuplicateActions.duplicateLegislature({ id, name })])
		)
	);

	duplicate$ = createEffect(() =>
		this.actions$.pipe(
			ofType(LegislatureDuplicateActions.duplicateLegislature),
			switchMap(({ id, name }) =>
				this.legislaturesService.duplicateLegislature(id, name).pipe(
					map(result => LegislatureDuplicateActions.duplicateLegislatureSuccess({ legislature: result })),
					catchError(error => of(LegislatureDuplicateActions.duplicateLegislatureFailed({ error })))
				)
			)
		)
	);

	duplicateSuccess$ = createEffect(
		() =>
			this.actions$.pipe(
				ofType(LegislatureDuplicateActions.duplicateLegislatureSuccess),
				tap(() => this.notificationService.success('legislatures.list.success.duplicate'))
			),
		{ dispatch: false }
	);

	duplicateFailed$ = createEffect(() =>
		this.actions$.pipe(
			ofType(LegislatureDuplicateActions.duplicateLegislatureFailed),
			switchMap(({ error }) => {
				if (error.internalCode in ELegislaturesErrorCode) {
					if (error.internalCode === ELegislaturesErrorCode.DUPLICATE_NAME && error.details) {
						const validations = this.translateService.translate(`legislatures.errors.validation.${error.internalCode}`, {
							title: error.details.duplicatedName
						});
						this.interactionService.confirmValidationV2('legislatures.errors.validation.title', [validations]);
					} else {
						this.interactionService.confirm(
							`legislatures.errors.${error.internalCode}.title`,
							this.translateService.translate(`legislatures.errors.${error.internalCode}.message`),
							'PDM.GENERICS.BUTTON.OK',
							'error'
						);
					}
				} else {
					showDefaultError(error, this.notificationService, this.interactionService);
				}

				return [LoadingActions.stopLoading()];
			})
		)
	);

	stopLoading$ = createEffect(() =>
		this.actions$.pipe(
			ofType(LegislatureDuplicateActions.duplicateLegislatureSuccess, LegislatureDuplicateActions.duplicateLegislatureFailed),
			switchMap(() => [LoadingActions.stopLoading()])
		)
	);

	constructor(
		private readonly actions$: Actions,
		private readonly legislaturesService: LegislaturesService,
		private readonly translateService: TranslocoService,
		private readonly interactionService: InteractionService,
		private readonly notificationService: NotificationService
	) {}
}
