import { createAction, props } from '@ngrx/store';
import { Person } from '@core/models/person.model';

export const initPoliticiansAutocomplete = createAction(
	'[Politicians Autocomplete] Init',
	props<{
		search: string | null;
	}>()
);
export const loadPoliticiansAutocomplete = createAction(
	'[Politicians Autocomplete] Load',
	props<{
		search: string | null;
	}>()
);

export const loadPoliticiansAutocompleteSuccess = createAction(
	'[Politicians Autocomplete] Load success',
	props<{
		politicians: Person[];
	}>()
);
export const loadPoliticiansAutocompleteFailed = createAction('[Politicians Autocomplete] Load failed');
