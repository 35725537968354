import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import * as RolesV2Actions from '../actions/rolesV2.actions';
import { of, switchMap } from 'rxjs';
import * as LoadingActions from '../actions/loading.actions';
import { catchError, map } from 'rxjs/operators';
import { RolesServiceV2 } from '@core/services/rolesV2.service';

@Injectable()
export class RolesV2Effect {
	init$ = createEffect(() =>
		this.actions$.pipe(
			ofType(RolesV2Actions.initRolesV2),
			switchMap(() => [LoadingActions.startLoading(), RolesV2Actions.loadRolesV2()])
		)
	);

	load$ = createEffect(() =>
		this.actions$.pipe(
			ofType(RolesV2Actions.loadRolesV2),
			switchMap(() =>
				this.rolesServiceV2.getRoles().pipe(
					map(roles => RolesV2Actions.loadRolesSuccessV2({ roles })),
					catchError(() => of(RolesV2Actions.loadRolesFailedV2()))
				)
			)
		)
	);

	stopLoading$ = createEffect(() =>
		this.actions$.pipe(
			ofType(RolesV2Actions.loadRolesSuccessV2, RolesV2Actions.loadRolesFailedV2),
			map(() => LoadingActions.stopLoading())
		)
	);

	constructor(private readonly rolesServiceV2: RolesServiceV2, private readonly actions$: Actions) {}
}
