import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import * as ActivityV2PublishActions from './activities-V2-publish.actions';
import { of, switchMap } from 'rxjs';
import * as LoadingActions from '../../../../core/store/actions/loading.actions';
import * as InternalNoteActions from '../../../../core/store/actions/internal-note-actions';
import { catchError, map } from 'rxjs/operators';
import { NotificationService } from '@core/services/notification.service';
import { EFeatureType } from '@core/models/enums/feature-type.enum';
import { TranslocoService } from '@ngneat/transloco';
import { InteractionService } from '../../../../shared/interaction/interaction.service';
import { showDefaultError } from '@core/utils/miscellaneous.utils';
import { ActivitiesV2Service } from '../../services/activities-V2.service';
import { EActivitiesV2ErrorCodes } from '../../models/enum/activities-V2-error-code.enum';

@Injectable()
export class ActivitiesV2PublishEffects {
	init$ = createEffect(() =>
		this.actions$.pipe(
			ofType(ActivityV2PublishActions.initPublishActivityV2),
			switchMap(({ id, message }) => [
				LoadingActions.startLoading(),
				ActivityV2PublishActions.publishActivityV2({
					id,
					message
				})
			])
		)
	);

	publish$ = createEffect(() =>
		this.actions$.pipe(
			ofType(ActivityV2PublishActions.publishActivityV2),
			switchMap(({ id, message }) =>
				this.activityService.publish(id, message).pipe(
					map(result => ActivityV2PublishActions.publishActivityV2Success({ activity: result })),
					catchError(error => of(ActivityV2PublishActions.publishActivityV2Failed({ error })))
				)
			)
		)
	);

	publishSuccess$ = createEffect(() =>
		this.actions$.pipe(
			ofType(ActivityV2PublishActions.publishActivityV2Success),
			switchMap(({ activity }) => {
				this.notificationService.success('articles.detail.success.publish');
				return [InternalNoteActions.initLoadNote({ entityId: activity.id, feature: EFeatureType.ACTIVITY })];
			})
		)
	);

	publishFailed$ = createEffect(() =>
		this.actions$.pipe(
			ofType(ActivityV2PublishActions.publishActivityV2Failed),
			switchMap(({ error }) => {
				if (error.internalCode in EActivitiesV2ErrorCodes) {
					this.interactionService.confirm(
						`articles.detail.errors.${error.internalCode}.title`,
						this.translateService.translate(`articles.detail.errors.${error.internalCode}.message`),
						'PDM.GENERICS.BUTTON.OK',
						'error'
					);
				} else {
					showDefaultError(error, this.notificationService, this.interactionService);
				}

				return [LoadingActions.stopLoading()];
			})
		)
	);

	stopLoading$ = createEffect(() =>
		this.actions$.pipe(
			ofType(ActivityV2PublishActions.publishActivityV2Success, ActivityV2PublishActions.publishActivityV2Failed),
			switchMap(() => [LoadingActions.stopLoading()])
		)
	);

	constructor(
		private readonly actions$: Actions,
		private readonly activityService: ActivitiesV2Service,
		private readonly translateService: TranslocoService,
		private readonly interactionService: InteractionService,
		private readonly notificationService: NotificationService
	) {}
}
