import { Injectable } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { isValidUrl, noNewLine } from '@core/utils/forms/form.validators';
import { ActivityV2CreateForm } from '../models/activity-V2-create.form';
import { ActivityV2Detail } from '../models/activity-V2-detail.model';

@Injectable()
export class ActivitiesV2FormMapperService {
	constructor(private readonly formBuilder: FormBuilder) {}

	public buildEmptyForm(): ActivityV2CreateForm {
		return this.formBuilder.group({
			title: [
				'',
				{
					validators: [Validators.required, Validators.minLength(3), Validators.maxLength(200), noNewLine()],
					nonNullable: true
				}
			],
			categoryId: ['', { validators: [Validators.required] }],
			type: ['', { validators: [Validators.required] }],
			linkGazzettaUfficiale: ['', { validators: [Validators.minLength(10), Validators.maxLength(500), isValidUrl()] }],
			dataPubblicazioneInGazzetta: [null],
			tags: [null, { validators: [Validators.minLength(1), Validators.maxLength(50)] }],
			attachmentIds: [null, { validators: [Validators.minLength(1), Validators.maxLength(50)] }]
		}) as unknown as ActivityV2CreateForm;
	}

	public buildFromExisting(activityDetail: ActivityV2Detail): ActivityV2CreateForm {
		return this.formBuilder.group({
			title: [
				activityDetail.title,
				{
					validators: [Validators.required, Validators.minLength(3), Validators.maxLength(200), noNewLine()],
					nonNullable: true
				}
			],
			categoryId: [activityDetail.categoryId, { validators: [Validators.required] }],
			type: [activityDetail.type, { validators: [Validators.required] }],
			linkGazzettaUfficiale: [
				activityDetail.linkGazzettaUfficiale,
				{ validators: [Validators.minLength(10), Validators.maxLength(500), isValidUrl()] }
			],
			dataPubblicazioneInGazzetta: [
				activityDetail.dataPubblicazioneInGazzetta ? activityDetail.dataPubblicazioneInGazzetta.toISODate() : null
			],
			tags: [activityDetail.tags, { validators: [Validators.minLength(1), Validators.maxLength(50)] }],
			attachmentIds: [
				activityDetail.attachments?.map(attachment => attachment.id),
				{ validators: [Validators.minLength(1), Validators.maxLength(50)] }
			]
		}) as unknown as ActivityV2CreateForm;
	}
}
