import { createAction, props } from '@ngrx/store';
import { ArticleV2Detail } from '../../models/article-v2-detail.model';
import { ServerError } from '@core/models/server-error.model';

export const initApproveArticle = createAction(
	'[Articles] Approve Init',
	props<{ id: string; publicationDateTime: string; message?: string }>()
);
export const approveArticle = createAction('[Articles] Approve', props<{ id: string; publicationDateTime: string; message?: string }>());
export const approveArticleSuccess = createAction('[Articles] Approve success', props<{ article: ArticleV2Detail }>());
export const approveArticleFailed = createAction('[Articles] Approve failed', props<{ error: ServerError }>());
